/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EventsCampaignsService } from 'shared/src/services/website/events-campaigns/events-campaigns.service';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { environment, SharedCommonService } from '@panjab-digi-lib/shared';
import { Router, RouterModule } from '@angular/router';
import { GlobalDividerComponent } from '../../divider/global-divider.component';
import { EventsCampaignsItem, HomePageEventCampaign } from 'shared/src/interfaces/events-campaigns.interface';

@Component({
  selector: 'panjab-digi-lib-events-campaigns',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, GlobalDividerComponent],
  templateUrl: './events-campaigns.component.html',
  styleUrl: './events-campaigns.component.scss',
})
export class EventsCampaignsComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  subscription = new Subscription();
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  eventsCampaigns:any;
  constructor(  
    private readonly translate: TranslateService,
    private readonly service: EventsCampaignsService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly commonService: SharedCommonService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  // fetch event and campaign
  loadData() {
    this.subscription.add(this.service.getHomePageEventsCampaigns().subscribe({
      next: (response:HomePageEventCampaign) => {
        this.eventsCampaigns = response;
      },
      error: (error) => {
        //
      },
    }))
  }

  // navigate to detail page
  navigateToDetail(item: EventsCampaignsItem) {
    this.router.navigateByUrl(`/events-campaigns/${item.ID}`);
  }

  // sanitizes an HTML string to prevent XSS attacks.
  sanitizeHTML = (htmlStr: string) => this.commonService.sanitizeHTML(htmlStr);

  ngOnDestroy(): void {
  this.subscription.unsubscribe();
  }
}
