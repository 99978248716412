<div class="container mt-3">
  <!-- load document reader -->
  @if (metadata) {
    @if (loadingImageAndPdf) {
        <panjab-digi-lib-grow-spinner ></panjab-digi-lib-grow-spinner>
    } @else if (!loadingImageAndPdf && pdfFiles.length) {
        <panjab-digi-lib-reader  [fileUrl]="pdfFiles[0]?.url || ''"></panjab-digi-lib-reader>
    }
  }
  <section class="innerpage-banner">
    <div class="row mb-3">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            Home
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" [routerLink]="'/news-and-articles'">
            {{'pdl-website.newsandarticles.heading' | translate}}
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="javascript:void(0)" [routerLink]="'/news-and-articles/'+subjectId+'/'+document?.ID">
            {{document?.Title}}
          </a>
        </div>
      </div>
    </div>
  </section>
  <div class="content align-items-start mb-5">
    <span class="date">Posted on: {{document?.DateAdded | date : 'dd MMMM YYYY'}}</span>
    <span class="pill">Published on: {{document?.PublishedDate | date : 'dd MMMM YYYY'}}</span>
    <h1 class="heading">{{document?.Title}}</h1>
    <p class="text">{{document?.Description}}</p>
  </div>
  <div class="same-topic mt-5">
    <h5 class="heading mb-4">{{'pdl-website.newsandarticles.sameTopic' | translate}}</h5>
    <div class="list_section mb-5 d-flex no-scrollbar">
      @for(item of relatedArticles; track item.ID;) {
        <div class="item">
          <a href="javascript:void(0);" [routerLink]="'/news-and-articles/'+subjectId+'/'+item.ID">
            <img [src]="getThumbnailImg(item)" (error)="handleImageError($event)"  class="banner_img" alt="" />
          </a>
          <div class="item_content">
            <span class="pill">Published on: {{item.PublishedDate | date : 'dd MMMM YYYY'}}</span>
            <span class="heading"><a href="javascript:void(0);" [routerLink]="'/news-and-articles/'+item.ID">{{item.Title | titlecase}}</a></span>
          </div>
        </div>
      } @empty {
        <div>No new article found</div>
      }
    </div>
  </div>
</div>
