export const environment = {
    production: false,
    staging: false,
    development: true,
    rootApiUrl: 'https://devapi.panjabdigilib.org/api/',
    adminApiPrefix: 'admin/',
    staffApiPrefix: 'staff/',
    sharedApiPrefix: 'shared/',
    appApiPrefix: 'app/',
    rootApiPublicUrl: 'https://devapi.panjabdigilib.org/',
    homepageUrl: 'https://dev.panjabdigilib.org/',
    recaptcha: {
        siteKey: '6LdeR9wpAAAAAJdXLaGC7TT53CyF5K71GB2B5W0A'
    },
    social: {
        GOOGLE_CLIENT_ID: '346028492508-tm41lk5f4e12n8qbm7khqffc1eccuf8n.apps.googleusercontent.com',
        FB_APP_ID: '1161989674840271'
    },
    donorBoxConfig: {
        donorBoxJS: 'https://donorbox.org/widget.js',
        donationLink: 'https://donorbox.org/embed/test-donate-12'
    },
    razorPayConfig: {
        razorPayJS: 'https://checkout.razorpay.com/v1/payment-button.js',
        razorPayBtnID: 'pl_OFV2ljkPaFLLTV',
        razorPayCheckoutJS: 'https://checkout.razorpay.com/v1/checkout.js',
        razorPayKeyID: 'rzp_test_fFhQsMCaPu04cb'
    },
    paypalConfig: {
        PAYPAL_JS: 'https://www.paypal.com/sdk/js?client-id=ARsfffoxsLO0eqJw4i6vdUcOCuKZ2L_QFHYJNGOnPmmZYHfU8IlyoCmc4DKAU2TDHOVX-B_gIj0Z1o8s',
        PAYPAL_CLIENT_ID:'ARsfffoxsLO0eqJw4i6vdUcOCuKZ2L_QFHYJNGOnPmmZYHfU8IlyoCmc4DKAU2TDHOVX-B_gIj0Z1o8s'
    },
    availableCountriesForDonation: [
        1, 2, 3, 4, 5
    ],
    azureCloudStrorage: {
        publicUrl: 'https://pdldevfilestorage.blob.core.windows.net/dev-container-public/'
    },
    quillEditorConfiguration: {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean']
        ],
    },
    reader: {
        server: 'devapi.panjabdigilib.org',
        iiifSearchPath: '/api/app/iiifSearch'
    },
    googleMapsApiKey: "AIzaSyC_tvM8jJVHmbsVC3wXVSbv2CUKpHP9EI8",
    googleAnalytics: {
        web: "GTM-PK4G99VW",
        staff: "GTM-PF37PH82",
        admin: "GTM-P83Z936H"
    },
    getIPAddressEndpoint: "https://api.ipify.org?format=json",
    geoAPIEndpoint: "http://ip-api.com/json/{IPAddress}?fields=status,message,country,countryCode,currency",
    countryinfoapi: "https://countryinfoapi.com/api/countries/name/{countryName}?fields=name,currencies,cca2"
};
