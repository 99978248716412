<section class="contact-request-form">
    <div class="container reg-container">
        <div class="row d-flex">
            <!-- Column 1 - Static Content -->
            <div class="col-md-5 column-1">
                <div class="content fw-normal text-justify">
                    <!-- Hide breadcrumbs in mobile view -->
                    <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                        <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"
                            [route]="'/register-user'"></panjab-digi-lib-breadcrumbs>
                    </div>
                    <h5 class="fw-bold">{{'pdl-website.forms.labels.contactUsForm.heading' | translate }}</h5>
                    <div class="static-txt"
                        [innerHtml]="'pdl-website.forms.labels.contactUsForm.contactLeftColText' | translate"></div>
                </div>
            </div>
            <!-- Column 2 - contact Form -->
            <div class="col-md-7 column-2">
                <div class="right-section mb-5">
                    <div class="top-content">
                        <div class="header-title" #moveToTop tabindex="-1">
                            <!-- Show breadcrumbs in mobile view -->
                            <div
                                class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                                <panjab-digi-lib-breadcrumbs [pageName]="pageTitle" [route]="'/register-user'"></panjab-digi-lib-breadcrumbs>
                            </div>
                            <div class="title-txt mt-4">
                                <h5 class="fw-bolder">{{'pdl-website.forms.labels.contactUsForm.requestFormTitle' |
                                    translate }}</h5>
                            </div>
                        </div>
                        <div class="header-body">
                            <p class="fw-light"
                                [innerHtml]="'pdl-website.forms.labels.contactUsForm.contactRightColText' | translate">
                            </p>
                        </div>
                    </div>
                    <form [formGroup]="contactReqForm" (ngSubmit)="onSubmit()" autocomplete="off">
                        <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                        <div class="form-section2">
                            <p class="fw-bold">{{'pdl-website.forms.labels.contactUsForm.informationText' | translate }}
                            </p>
                            <!-- name,email -->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="name" class="form-label reqd"
                                            [ngClass]="{'text-danger': contactReqForm.controls['name'].invalid && contactReqForm.controls['name'].touched}">{{'pdl-website.forms.labels.contactUsForm.name'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(contactReqForm.controls['name'].invalid &&
                                        contactReqForm.controls['name'].touched) {
                                        <ng-container
                                            *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.fullname' | translate }"></ng-container>
                                        }
                                        @if(contactReqForm.controls['name'].valid &&
                                        contactReqForm.controls['name'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }


                                    </div>
                                    <input type="text" autocomplete="off" id="name" class="form-control"
                                        formControlName="name"
                                        [ngClass]="{'border-danger': contactReqForm.controls['name'].invalid && contactReqForm.controls['name'].touched}"
                                        panjabDigiLibTrimmedText>
                                </div>
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="email" class="form-label reqd"
                                            [ngClass]="{'text-danger': contactReqForm.controls['email'].invalid && contactReqForm.controls['email'].touched}">{{'pdl-website.forms.labels.contactUsForm.email'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(contactReqForm.controls['email'].touched &&
                                        contactReqForm.controls['email'].errors &&
                                        (contactReqForm.controls['email']['errors']['required'] ||
                                        contactReqForm.controls['email']['errors']['invalidField'])) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @else if(contactReqForm.controls['email'].touched &&
                                        contactReqForm.controls['email'].errors &&
                                        contactReqForm.controls['email']['errors']['pattern']) {
                                        <ng-container
                                            *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidEmail' | translate }"></ng-container>
                                        }
                                        @if(contactReqForm.controls['email'].valid &&
                                        contactReqForm.controls['email'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="email" autocomplete="off" #emailInput class="form-control"
                                        formControlName="email" id="email"
                                        [ngClass]="{'border-danger': contactReqForm.controls['email'].invalid && contactReqForm.controls['email'].touched}"
                                        panjabDigiLibTrimmedText>

                                </div>
                            </div>
                            <!-- phone, reason -->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="phone" class="form-label reqd"
                                            [ngClass]="{'text-danger': contactReqForm.controls['phone'].invalid && contactReqForm.controls['phone'].touched}">{{'pdl-website.forms.labels.contactUsForm.phone'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(contactReqForm.controls['phone'].touched &&
                                        contactReqForm.controls['phone'].errors &&
                                        (contactReqForm.controls['phone']['errors']['required'] ||
                                        contactReqForm.controls['phone']['errors']['invalidField'])) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @else if(contactReqForm.controls['phone'].touched &&
                                        contactReqForm.controls['phone'].errors &&
                                        contactReqForm.controls['phone']['errors']) {
                                        <ng-container
                                            *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidPhonenum' | translate }"></ng-container>
                                        }
                                        @if(contactReqForm.controls['phone'].valid &&
                                        contactReqForm.controls['phone'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="text" autocomplete="off" [maxLength]="12" [minLength]="10" #phoneInput
                                        class="form-control" formControlName="phone" id="phone"
                                        [ngClass]="{'border-danger': contactReqForm.controls['phone'].invalid && contactReqForm.controls['phone'].touched}"
                                        panjabDigiLibNumberonly>
                                </div>
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="reason" class="form-label reqd"
                                            [ngClass]="{'text-danger': contactReqForm.controls['reason'].invalid && contactReqForm.controls['reason'].touched}">{{'pdl-website.forms.labels.contactUsForm.reason'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(contactReqForm.controls['reason'].invalid &&
                                        contactReqForm.controls['reason'].touched) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @if(contactReqForm.controls['reason'].valid &&
                                        contactReqForm.controls['reason'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <select class="form-select" formControlName="reason" id="reason"
                                        [ngClass]="{'border-danger': contactReqForm.controls['reason'].invalid && contactReqForm.controls['reason'].touched, 'select_option': contactReqForm.controls['reason'] === null || contactReqForm.controls['reason'].invalid}">
                                        <option class="select_option" [ngValue]="null" disabled>Select</option>
                                        @for(reason of reasonList; track reason.ID) {
                                        <option [ngValue]="reason?.ID">{{reason?.Reason}}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <!------------- message ----->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <label for="message"
                                            class="form-label reqd">{{'pdl-website.forms.labels.contactUsForm.message' |
                                            translate}}</label>
                                        <span class="flex-grow-1 ms-1 select_option"> ({{'pdl-website.forms.labels.contactUsForm.characterAllowed' |
                                            translate}})</span>
                                        @if(contactReqForm.controls['message'].touched &&
                                        contactReqForm.controls['message'].errors &&
                                        contactReqForm.controls['message']['errors']['required']) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                    </div>
                                    <textarea class="form-control" formControlName="message" rows="3" cols="50" [maxLength]="1000"
                                        id="message"
                                        [ngClass]="{'border-danger': contactReqForm.controls['message'].invalid && contactReqForm.controls['message'].touched}"
                                        panjabDigiLibTrimmedText> </textarea>
                                </div>
                            </div>
                            <!-- recaptch -->
                            <div class="row mt-4">
                                <div class="col-12">
                                    <re-captcha #captchaRef (resolved)="resolveCaptcha($event)" formControlNme="cpt"
                                        siteKey="{{sitekey}}" [size]="isMobile ? 'compact' : 'normal'"></re-captcha>
                                </div>
                            </div>
                            <!-- /recaptch -->
                            <div class="row">
                                <div class="col-6 text-end">
                                    <button type="reset" [disabled]="isSubmitted"
                                        class="btn btn-secondary btn__secondary">{{'pdl-website.forms.labels.resetBtn' |
                                        translate }}</button>
                                </div>
                                <div class="col-6 text-start">
                                    @if(isSubmitted) {
                                    <panjab-digi-lib-spinner-button
                                        [name]="'pdl-shared.loaderState.pleaseWait' | translate"></panjab-digi-lib-spinner-button>
                                    } @else {
                                    <button [disabled]="contactReqForm.invalid" type="submit"
                                        class="btn btn-primary btn__primary">
                                        {{'pdl-website.forms.labels.contactUsForm.submit' | translate }}
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Template - required field error -->
    <ng-template #requiredError>
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
        </span>
    </ng-template>
    <!-- Template - invalid field error -->
    <ng-template #invalidError let-msg="msg">
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
            <ng-template #tipContent>{{msg}}</ng-template>
        </span>
    </ng-template>
    <!-- Template - valid field -->
    <ng-template #validFld>
        <span class="text-success">
            <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
        </span>
    </ng-template>
</section>