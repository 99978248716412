@if(isLoading) {
<panjab-digi-lib-spinner></panjab-digi-lib-spinner>
}
@if(!isLoading) {
<div class="overflow-x-hidden">
   <section class="top_section">
      <img src="{{imageBaseUrl}}{{exhibitionDetail.ItemPath}}" class="banner_image" alt="exhibition-image" />
      <div class="innerpage-banner">
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                     <a class="breadcrumb-link font-regular" href="#">
                        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                        Home
                     </a>
                     <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg" alt="icon">
                     </span>
                     <a class="breadcrumb-link font-regular" href="#">
                        All Exhibitions
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="top_section_bottom">
         <div class="content">
            <p class="heading">
               {{exhibitionDetail.Title}}
            </p>
            <div class="subheading ">
               <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                     <div>{{exhibitionDetail.Date.startDate | date : 'dd MMMM YYYY'}} - {{exhibitionDetail.Date.endDate
                        |
                        date :
                        'dd MMMM YYYY'}} | {{ '1970-01-01T' + exhibitionDetail.Time.startTime + ':00' | date: 'h:mm a'
                        }} -
                        {{
                        '1970-01-01T' + exhibitionDetail.Time.endTime + ':00' | date: 'h:mm a' }}
                     </div>
                     <div class="mb-3">
                        {{exhibitionDetail.Location}}
                     </div>
                     @if(exhibitionDetail.OtherLocations.length> 0) {
                     <button class="check_more_locations cursor-pointer">Check
                        {{exhibitionDetail.OtherLocations.length}}
                        More
                        {{exhibitionDetail.OtherLocations.length > 1 ? 'Locations' : 'Location'}} </button>
                     }
                  </div>
                  <div class="btn_wrapper">
                     <button class="btn btn__secondary">
                        Get Directions
                     </button>
                     <button class="btn btn__primary">
                        Plan Your Visit
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-------------------------- google map section  ----------------------->
   <section class="container exhibition_detail mt-5">
      <!-- <p class="heading">Our Multiple location address</p> -->
      <!-- <p class="subheading">
         Organized by the Annenberg Space for Photography in Los Angeles and drawn from the photography collections
         of the Library of Congress, presenting a taste of this institution’s spectacular holdings of more than 14
         million photographs. Selected images reproduced for this on.
      </p> -->
      <div class="mapouter mb-5 mt-5 d-flex justify-content-center">
         <div class="gmap_canvas">
            <div #mapContainer style="width: 100%; height: 400px;"></div>
         </div>
      </div>
   </section>
   <div class="d-flex tabs no-scrollbar mb-5">
      <ul class="nav nav-pills no-scrollbar" role="tablist">
         <li class="nav-item" role="presentation">
            <button class="nav-link active" aria-current="page" data-bs-toggle="pill"
               data-bs-target="#exhibition_detail" type="button" role="tab" aria-selected="true">Explore</button>
         </li>
         <li class="nav-item" role="presentation">
            <button class="nav-link" href="#" data-bs-toggle="pill" data-bs-target="#exhibition_items">Exhibition
               Items</button>
         </li>
         <li class="nav-item" role="presentation">
            <button class="nav-link" href="#" data-bs-target="#event_resources" data-bs-toggle="pill">Events &
               Resources</button>
         </li>
         <li class="nav-item" role="presentation">
            <button class="nav-link" href="#" data-bs-target="#event_credits" data-bs-toggle="pill">Credits</button>
         </li>
      </ul>
   </div>
   <div class="tab-content">
      <!-- Exhibition Details Tab -->
      <div id="exhibition_detail" role="tabpanel" class="tab-pane fade show active">
         <section class="container exhibition_detail mt-5 desc_content">
            <p class="heading">{{ exhibitionDetail.Title }}</p>
            <div [innerHTML]="sanitizeHTML(exhibitionDetail.Description)"></div>
            <!-- @if(exhibitionDetail.ExhibitionCredits.Sponsors) {
            <div [innerHTML]="sanitizeHTML(exhibitionDetail.ExhibitionCredits.Sponsors)"></div>
            } -->

            <div class="mb-5 mt-5">
               <panjab-digi-lib-global-divider />
            </div>
         </section>
      </div>
      <!-- Exhibition Items Tab -->
      <div class="tab-pane fade show exhibition_items" role="tabpanel" id="exhibition_items">
         <section class="explore_exhibit">
            <h5 class="semibold text-center">Explore the Exhibit</h5>
            <div class="d-flex flex-wrap item_wrapper mt-4">
               @for(item of exhibitionDetail.ExhibitionItems;track item.ExhibitionItemID) {
               <div class="item d-flex flex-column img_wrapper" (click)="navigateToItemsWithState()">
                  @if(item.ItemPath) {
                  <img src="{{imageBaseUrl}}{{item.ItemPath}}" alt="img" />
                  } @else {
                  <img src="{{fallbackImage}}" alt="img" />
                  }
                  <span class="text">{{item.Title}}</span>
               </div>
               }
            </div>
         </section>
         <div class="mb-5 mt-5">
            <panjab-digi-lib-global-divider />
         </div>
      </div>
      <!-- Event Resources Tab -->
      <div class="tab-pane fade show event_resources" role="tabpanel" id="event_resources">
         <section class="container">
            <p class="bold">Events & Resources</p>
            <div class="item_wrapper">
               <div class="item d-flex">
                  <img src="{{imageBaseUrl}}{{exhibitionDetail.ExhibitionResource.ItemPath}}" class="item_image"
                     alt="image" />
                  <div class="item_content">
                     <p class="heading">{{exhibitionDetail.ExhibitionResource.Title}}</p>
                     <div class="text text-cut dynamic-text"

                        [innerHTML]="sanitizeHTML(exhibitionDetail.ExhibitionResource.Description)">

                     </div>
                  </div>
               </div>
            </div>
         </section>
         <div class="mb-5 mt-5">
            <panjab-digi-lib-global-divider />
         </div>
      </div>
      <!-- Event credits Tab -->
      <div class="tab-pane fade show event_resources" role="tabpanel" id="event_credits">
         <section class="container credits_section">
            <p class="bold">Credits</p>
            <div [innerHTML]="sanitizeHTML(exhibitionDetail.ExhibitionCredits.Description)"></div>
            @if(exhibitionDetail.ExhibitionCredits.Sponsors) {
            <h6>Sponsors</h6>
            <div [innerHTML]="sanitizeHTML(exhibitionDetail.ExhibitionCredits.Sponsors)"></div>

            }
         </section>
         <div class="mb-5 mt-5">
            <panjab-digi-lib-global-divider />
         </div>
      </div>
   </div>
   <!----------------------------gallery section --------------------->
   <section>
      <h5 class="semibold text-center mb-4">Exhibition Gallery</h5>
      <ngx-slick-carousel class="carousel mb-0" #slickCarousel="slick-carousel" [config]="slideConfig"
         (afterChange)="onCarouselChange($event)">
         @for(media of exhibitionDetail.ExhibitionMediaAssets;track media) {
         <div ngxSlickItem #gallery class="slide downloads-slide pe-4">
            <div class="downloads-slide-card">
               <div class="block-img">
                  @if(media.AssetType === 1) {
                  <img src="{{imageBaseUrl}}{{media.ItemPath}}" alt="img">
                  }@else{
                  <video controls>
                     <source src="{{imageBaseUrl}}{{media.ItemPath}}" type="video/mp4">
                  </video>
                  }
               </div>
            </div>
         </div>
         }
      </ngx-slick-carousel>
      <div class="d-flex justify-content-center mb-5 mt-3" style="gap: 20px">
         <button class="btn btn__primary" (click)="slickCarousel.slickPrev()">
            <img src="assets/images/icons/arrow-white-left.svg" alt="">
         </button>
         <button class="btn btn__primary" (click)="slickCarousel.slickNext()">
            <img src="assets/images/icons/arrow-white-right.svg" alt="">
         </button>
      </div>
      <div class="d-flex infinite-hr-scroll no-scrollbar mb-5">
         @for(media of exhibitionDetail.ExhibitionMediaAssets;track media) {
         <div (click)="setActiveSlide(media)">
            @if(media.AssetType === 1) {
            <img src="{{imageBaseUrl}}{{media.ItemPath}}" alt="img">
            }@else{
            <div class="position-relative">
               <video class="">
                  <source src="{{imageBaseUrl}}{{media.ItemPath}}" type="video/mp4">
               </video>
               <img class="position-absolute play_img" src="assets/BookReader/icons/play-no-bg.svg" />
            </div>
            }
         </div>
         }
      </div>
   </section>
</div>
}
