import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../reducers/website/web-document-bookmark.reducer';

export const selectBookmarksState = createFeatureSelector<State>('webDocumentBookmarks');

export const selectBookmarks = createSelector(
  selectBookmarksState,
  (state) => state.bookmarks
);
export const selectBookmarksLoading = createSelector(
  selectBookmarksState,
  (state) => state.loading
);
export const selectBookmarksError = createSelector(
  selectBookmarksState,
  (state) => state.error
);
