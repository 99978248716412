import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminEventsCampaigns from '../reducers/admin-events-campaigns.reducer';

export const selectState =
  createFeatureSelector<fromAdminEventsCampaigns.State>('adminEventsCampaigns');

export const loadEventsCampaignsList = createSelector(selectState, (state) => ({
  recordsList: fromAdminEventsCampaigns.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber,
  type: state.type
}));

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage, state.isLoading, state.isUpdateStatus]
);