import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment, SharedCommonService, SpinnerComponent } from '@panjab-digi-lib/shared';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import { APP_CONSTANTS as SHARED_APP_CONSTANTS } from 'shared/src/common/app.constants';
import { TranslateModule } from '@ngx-translate/core';
import { AdoptionService } from 'shared/src/services/website/adoption/adoption.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AdoptedDocument, AdopterDetails } from 'shared/src/interfaces/website/adoption.interface';
import { HttpFormatPipe } from 'shared/src/pipes/httpFormat.pipe';

@Component({
  selector: 'panjab-digi-lib-org-adopted-docs',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule, 
    RouterLink, 
    SpinnerComponent, 
    HttpFormatPipe,
  ],
  templateUrl: './org-adopted-docs.component.html',
  styleUrl: './org-adopted-docs.component.scss',
})
export class OrgAdoptedDocsComponent implements OnInit {
  imageBaseURL = environment.azureCloudStrorage.publicUrl;
  fallbackImage = APP_CONSTANTS.categoryFallbackImage;
  adopterTypes = SHARED_APP_CONSTANTS.AdopterType;
  
  adopterID: string = ''
  documentList: AdoptedDocument[] = [];
  adopterDetails!: AdopterDetails;
  isLoading: boolean = true;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private adoptionService: AdoptionService,
    private activatedRoute: ActivatedRoute,
    private sharedCommonService: SharedCommonService, 
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if(params.id) {
        this.adopterID = params.id;
        this.getAdoptionDetails();
      } else {
        this.isLoading = false;
      }
    });
  }

  getAdoptionDetails(): void {
    this.adoptionService.getAdoptionDetails(this.adopterID).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        console.log('response', response);
        this.adopterDetails = response.adopter;
        this.adopterDetails.Website = this.sharedCommonService.decodeHtmlEntities(this.adopterDetails.Website)
        this.documentList = response.documents;
        this.isLoading = false;
      }, error: (error) => {
        console.log('error', error);
        this.isLoading = false;
      }
    })
  }

  getRating(rating: string): number[] {
    const fullStars = Math.floor(Number(rating));
    const halfStar = Number(rating) % 1 >= 0.5;
    const noStars = 5 - fullStars - (halfStar ? 1 : 0);
    return [
      ...Array(fullStars).fill(1),
      ...(halfStar ? [0.5] : []),
      ...Array(noStars).fill(0),
    ];
  }

  
  // Method to get the appropriate star image URL
  getStarImage(star: number): string {
    switch (star) {
      case 1:
        return 'assets/images/icons/full-star.svg';
      case 0.5:
        return 'assets/images/icons/half-star.svg';
      case 0:
        return 'assets/images/icons/no-star.svg';
      default:
        return 'assets/images/icons/no-star.svg';
    }
  }

  
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
