/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { ExhibitionService } from 'shared/src/services/website/exhibition/exhibition.service';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { ExhibitionItem } from 'shared/src/interfaces/exhibition.interface';
import { environment, SharedCommonService, SpinnerComponent } from '@panjab-digi-lib/shared';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Router, RouterModule } from '@angular/router';
import { GlobalDividerComponent } from '../../divider/global-divider.component';

@Component({
  selector: 'panjab-digi-lib-home-exhibitions',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule,SpinnerComponent,SlickCarouselModule,GlobalDividerComponent],
  templateUrl: './exhibitions.component.html',
  styleUrl: './exhibitions.component.scss',
})
export class ExhibitionsComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  subscription = new Subscription();
  currentExhibitions: ExhibitionItem[] = [];
  upcomingExhibitions: ExhibitionItem[] = [];
  pastExhibitions: ExhibitionItem[] = [];
  allExhibitions : ExhibitionItem[] = [];
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  constructor(  
    private translate: TranslateService,
    private service: ExhibitionService,
    readonly toastService: ToastService,
    private router: Router,
    private commonService: SharedCommonService
  ) {

  }

  ngOnInit(): void {
    this.fetchAllExhibitions();
  }

  fetchAllExhibitions() {
    const payload = { pageNumber: 1, pageSize: 1 };
    const payloads = ['Current', 'Upcoming', 'Past'].map((filter) => ({
      ...payload,
      filters: filter
    }));

    const [currentPayload, upcomingPayload, pastPayload] = payloads;
    this.subscription.add(forkJoin([
      this.service.get(currentPayload),
      this.service.get(upcomingPayload),
      this.service.get(pastPayload)
    ]).subscribe({
      next: ([currentExhibitionResponse, upcomingExhibitionResponse, pastExhibitionResponse]) => {
        this.isLoading = false;
        this.currentExhibitions = currentExhibitionResponse?.body?.exhibitions;
        this.upcomingExhibitions = upcomingExhibitionResponse?.body?.exhibitions;
        this.pastExhibitions = pastExhibitionResponse?.body?.exhibitions;
        this.allExhibitions = [...this.currentExhibitions, ...this.upcomingExhibitions,...this.pastExhibitions].slice(0, 2);
      },
      error: (error) => {
        this.isLoading = false;
        this.toastService.showError(
          error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
        );
      }
    }));
  }

  ////////// navigate /////////
  navigateToDetail(exhibition: ExhibitionItem) {
    this.router.navigateByUrl(`/exhibition/${exhibition.ExhibitionID}`);
  }

  ngOnDestroy(): void {
  this.subscription.unsubscribe();
  }
  sanitizeHTML = (htmlStr: string) => this.commonService.sanitizeHTML(htmlStr);

}
