import { Injectable } from '@angular/core';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentNotesService {
  private rootURL: string;

  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }


  /////////////// Create a new note ///////////////
  createNote(payload: any): Observable<any> {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.metadata.notes}`;
    return this.http.post(url, payload).pipe(map((response: any) => response));
  }

  /////////////// Get all notes by DocID ///////////////
  getNotesByDocId(docId: number): Observable<any> {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.metadata.notes}?docIds=${docId}`;
    return this.http.get(url).pipe(map((response: any) => response));
  }

  /////////////// Update a note by NoteID ///////////////
  updateNoteById(noteId: number, payload: any): Observable<any> {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.metadata.notes}/${noteId}`;
    return this.http.put(url, payload).pipe(map((response: any) => response));
  }

  /////////////// Delete a note by NoteID ///////////////
  deleteNoteById(noteId: number): Observable<any> {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.metadata.notes}/${noteId}`;
    return this.http.delete(url).pipe(map((response: any) => response));
  }
}
