import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCart from '../../reducers/website/cart.reducer';

export const selectCartState = createFeatureSelector<fromCart.State>('cart');
const { selectAll } = fromCart.adapter.getSelectors(selectCartState);

export const cartList = createSelector(
  selectCartState,
  (state) => ({
    cartList: fromCart.selectAll(state),
    totalAmount: state.totalAmount,
  })
);

export const onCartChange = createSelector(
  selectCartState,
  (state) => ({
    cartList: !state.isUpdating ? Object.values(state.entities) : [],
    isUpdating: state.isUpdating,
  })
);

export const totalAmount = createSelector(
  selectCartState,
  (state) => state.totalAmount
);

export const isLoading = createSelector(
  selectCartState,
  (state) => state.isLoading
);

export const isUpdating = createSelector(
  selectCartState,
  (state) => state.isUpdating
);
