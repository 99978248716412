<div class="search-container py-2" [class.show_search]="showNotesContainer">
  <div class="text-end mb-2">
    <button (click)="closeNotes()" type="button" class="btn btn-link p-0 me-2">
      <img src="assets/images/icons/close-rounded-icon.svg" alt="Close" class="img-fluid" />
    </button>
  </div>

  <div class="reader__search px-4">
    <div
      (click)="showAddNotes()"
      class="position-relative inner__search d-flex align-items-center border-dotted cursor-pointer text-center"
    >
      <label for="" class=""><img src="/assets/images/icons/plus-icon-white.svg"></label>
      <div name="addNotes" type="text" class="form-control notes_input" placeholder="Search...">
        {{ 'pdl-website.metadataPage.notes.addNote' | translate}}
      </div>
    </div>
  </div>

  <!-- Notes Section -->
  @if (!enableAddNotes && !enableEditNotes) {
    <div class="notes px-3 h-100">
      <!-- Loading Indicator -->
      @if ((isLoading$ | async) || showLoader) {
        <div class="text-center loader-container my-2">
          <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">{{ 'pdl-shared.loaderState.loading' | translate}}</span>
          </div>
        </div>
      }

      <!-- Error Message -->
      @if (errorMessage$ | async) {
        <div class="text-danger">
          {{ (errorMessage$ | async)?.message }}
        </div>
      }

      <!-- Notes List -->
      @if (!(isLoading$ | async) && !showLoader) {
        @if (hasNotes$ | async) {
          @for (note of notes$ | async; track note; let index = $index;) {
            <div class="note_wrapper">
              <div class="heading_note">
                <span>{{ 'pdl-website.metadataPage.notes.note' | translate}} {{ index + 1 }}</span>
              </div>
              <div class="note_content pe-4">
                {{ note.Note }}
              </div>
              <button
                class="btn btn-link p-0 me-2 close-btn"
                [ngbPopover]="popContent"
                placement="start"
              >
                <img
                  src="assets/images/icons/close-rounded-icon.svg"
                  alt="Close"
                  class="img-fluid"
                />
              </button>
              <button
                class="btn btn-link p-0 me-2 edit-btn"
                (click)="showEditNotes(note)"
              >
                <img
                src="assets/images/icons/edit.svg"
                alt="Close"
                class="img-fluid"
              />
              </button>

              <!-- Confirmation Popover -->
              <ng-template #popContent>
                <div
                  class="popover bs-popover-top show position-absolute"
                  style="z-index: 1050; width: 200px;"
                >
                  <div class="popover-body">
                    <p>{{ 'pdl-website.metadataPage.notes.confirmDelete' | translate}}</p>
                    <div class="d-flex justify-content-between">
                      <button class="btn btn-secondary btn-sm">{{ 'pdl-shared.buttonText.cancel' | translate}}</button>
                      <button
                        class="btn btn-danger btn-sm"
                        (click)="deleteNote(note.ID)"
                      >
                        {{ 'pdl-shared.buttonText.delete' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          }
        } @else {
          <div class="text-center my-2">
            <p class="text-light">{{ 'pdl-website.metadataPage.notes.noNotesMsg' | translate}}</p>
          </div>
        }
      }
    </div>
  }

  <!-- Add/Edit Notes Form -->
  @if (enableAddNotes || enableEditNotes) {
    <div class="add_notes_textarea px-4">
      <span class="heading">
        {{ enableAddNotes ? 'New Note' : 'Edit Note' }}
      </span>
      @if (!enableEditNotes) {
        <div class="page-no-container text-light pt-2">
          {{ 'pdl-website.metadataPage.bookmarks.selectPageNumber' | translate}}
          @for (pageNo of pages; track pageNo; let index = $index;) {
            <div class="form-check form-check-inline ms-1">
              <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio{{ pageNo }}"
              (click)="updatePageNo(pageNo)"
              [value]="pageNo"
              [checked]="currentPageNumber == pageNo"
              />
              <label class="form-check-label" for="inlineRadio{{ pageNo }}">
                {{ pageNo }}
              </label>
            </div>
          }
        </div>
      } @else {
        <div class="text-light py-3"> {{ 'pdl-website.metadataPage.notes.pageNumber' | translate}}: <strong>{{editingNote.PageNumber}}</strong></div>
      }
      <textarea
        #noteTextArea
        rows="4"
        class="form-control"
        placeholder="Write your note here..."
        [value]="enableEditNotes ? editingNote.Note : ''"
      ></textarea>
    </div>
    <div class="text-center px-3 pt-2">
      <button
        class="btn btn__primary"
        *ngIf="enableAddNotes"
        (click)="addNote(noteTextArea.value)"
      >
      {{ 'pdl-shared.buttonText.add' | translate}}
      </button>
      <button
        class="btn btn__primary"
        *ngIf="enableEditNotes"
        (click)="updateNote(noteTextArea.value)"
      >
        {{ 'pdl-shared.content.save' | translate}}
      </button>
      <button
        class="btn btn-secondary ms-2"
        (click)="enableAddNotes ? closeAddNotes() : closeEditNotes()"
      >
        {{ 'pdl-shared.buttonText.cancel' | translate}}
      </button>
    </div>
  }
</div>
