<div class="bookmark-container py-2" [class.show_search]="showBookMarksContainer">
    <!-- Header Section -->
    <div class="d-flex justify-content-between mt-2 mb-4 ps-4 pe-2">
      <div class="bookmarkHeading">{{ 'pdl-website.metadataPage.bookmarks.myAllBookmarks' | translate}}</div>
      <div class="text-end mb-2">
        <button (click)="closeBookmarks()" type="button" class="btn btn-link p-0 me-2">
          <img src="assets/images/icons/close-rounded-icon.svg" alt="Close" class="img-fluid" />
        </button>
      </div>
    </div>
  
        <!-- Error Message -->
        @if (errorMessage$ | async) {
          <div class="text-danger px-4 py-1">
            {{ (errorMessage$ | async)?.message }}
          </div>
        }
  
    <!-- Loader Section -->
     @if ((isLoading$ | async)  || showLoader) {
       <div  class="text-center loader-container my-4">
         <div class="spinner-border text-light" role="status">
           <span class="visually-hidden">{{ 'pdl-shared.loaderState.loading' | translate}}</span>
         </div>
       </div>
     }
  
    <!-- Content Section -->
    @if(!(isLoading$ | async) && !showLoader) {
      <!-- Add Bookmark -->
      @if (!enableAddBookmark) {
        <div class="add__bookmark px-4" >
          <div
            class="position-relative add__button d-flex align-items-center border-dotted cursor-pointer text-center"
            (click)="showAddBookmark()"
          >
            <label for=""><img src="/assets/images/icons/plus-icon-white.svg"></label>
            <div class="bookmarks-label ms-2 ">{{ 'pdl-website.metadataPage.bookmarks.addBookmark' | translate}}</div>
          </div>
        </div>
      }
      <!-- Add Bookmark Form -->
       @if (enableAddBookmark) {
         <div class="px-4" >
           <span class="bookmarkHeading">{{ 'pdl-website.metadataPage.bookmarks.addBookmark' | translate}}</span>
           <div class="page-no-container text-light pt-2">
            {{ 'pdl-website.metadataPage.bookmarks.selectPageNumber' | translate}}:
             @for (pageNo of pages; track pageNo; let index = $index;) {
              <div class="form-check form-check-inline ms-1">
                <input
                  class="form-check-input"
                  type="radio"
                  name="pageSelection"
                  [value]="pageNo"
                  [checked]="currentPageNumber === pageNo"
                  (click)="updatePageNo(pageNo)"
                />
                <label class="form-check-label white-text">{{ pageNo }}</label>
              </div>
              }
           </div>
           <div class="text-center mt-3">
             <button class="btn btn__primary" (click)="addBookmark()">{{ 'pdl-shared.buttonText.add' | translate}}</button>
             <button class="btn btn-secondary ms-2" (click)="closeAddBookmark()">{{ 'pdl-shared.buttonText.cancel' | translate}}</button>
           </div>
         </div>
       }
  
  
      <!-- Bookmarks List -->
       @if (!enableAddBookmark) {
         <div class="bookmarksContainer px-4 pt-4" >
           @if(hasBookmarks$ | async) {
             <div class="bookmarkItem py-2" *ngFor="let bookmark of bookmarks$ | async">
               <div class="d-flex justify-content-between">
                 <span class="text-light" (click)="goToPageNo(bookmark.PageNumber)">Page {{ bookmark.PageNumber }}</span>
                 <div>
                   <button class="btn btn-link p-0 me-2" 
                     [ngbPopover]="popContent"
                     placement="start">
                     <img src="assets/images/icons/close-rounded-icon.svg" alt="Delete" class="img-fluid" />
                   </button>
                 </div>
                 <ng-template #popContent>
                     <div class="popover-body">
                       <p>{{ 'pdl-website.metadataPage.bookmarks.confirmDelete' | translate}}</p>
                       <div class="d-flex justify-content-between">
                         <button class="btn btn-secondary btn-sm">{{ 'pdl-shared.buttonText.cancel' | translate}}</button>
                         <button
                           class="btn btn-danger btn-sm"
                           (click)="deleteBookmark(bookmark.ID)"
                         >
                          {{ 'pdl-shared.buttonText.delete' | translate}}
                         </button>
                       </div>
                     </div>
                 </ng-template>
               </div>
             </div>
            } @else {
             <p class="text-center text-light mt-4">{{ 'pdl-website.metadataPage.bookmarks.noBookmarkMsg' | translate}}</p>
            }
         </div>
       }
    }
  </div>
  