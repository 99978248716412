// selectors/notes.selectors.ts
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../reducers/website/web-document-notes.reducer';

export const selectNotesState = createFeatureSelector<State>('webDocumentNotes');

export const selectAllNotes = createSelector(
  selectNotesState,
  (state) => state.notes
);

export const selectIsLoading = createSelector(
  selectNotesState,
  (state) => state.isLoading
);

export const selectErrorMessage = createSelector(
  selectNotesState,
  (state) => state.errorMessage
);
