/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { environment } from 'shared/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DigitizationRequestPayload } from 'shared/src/interfaces/digitization-request.interface';
import { GenericReponse } from 'shared/src/interfaces/common.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigitizationRequestService {

  appURL = '';
  endPointUrl = WEB_API_ENDPOINTS.digitizationRequest;
  constructor(private http: HttpClient) {
    this.appURL = environment.rootApiUrl + environment.appApiPrefix;
  }


  ////////////////////// add digitization request /////////////////////
  request(payload: DigitizationRequestPayload) {
    const url = `${this.appURL}${this.endPointUrl.add}`
    return this.http.post<GenericReponse>(url, payload)
      .pipe(map((e: GenericReponse) => e))
  }
}


