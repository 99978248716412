@if(featuredCollectionList && featuredCollectionList.length > 0) {

@if(isForHomePage) {
<div class="global-divider">
  <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
}
<section class="collections section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="heading-style semibold text-center mb-lg-40 f-31">
          {{heading}}
        </h2>
      </div>
      <div class="col-12 hidden-after-767">
        <div class="collections-in-grid">
          <div class="row justify-content-center">
            @for(collection of featuredCollectionList; track collection.CollectionID) {
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="collection-card">
                @if(collection.IsFeatured) {
                <span class="featured__tag">{{'pdl-website.curatedCollections.featured' | translate}}</span>
                }
                <div class="block-img"
                  [ngClass]="{'threeImgCollection': collection.Documents.length === 3,'twoImgCollection': collection.Documents.length === 2 }">
                  @for(document of collection.Documents; track document.DocID) {
                  <img alt="Thumbimage" class="img-fluid"
                    src="{{imageBaseURL}}{{document.CategoryName}}/{{document.AccessionNo}}/Thumbnails/{{document.AccessionNo}}_90.jpg"
                    onerror="this.src='assets/images/icons/no-image-placeholder.svg'">
                  }
                </div>
                <div class="block-text">
                  <h5 class="medium-font">
                    {{collection.Title}}
                  </h5>
                  <span class="number-of-docs">
                    <img src="assets/images/icons/folder-icon.svg" alt="folder-icon">
                    {{collection.DocumentCount}} {{'pdl-website.numberofDocs.docsText' | translate }}
                  </span>
                  <p class="description-text">
                    <span class="text__ellipse text-wrap text-break">{{collection.Description}}</span>
                  </p>
                  <div class="line-btn-block">
                    <a (click)="changeCollection(collection.CollectionID)" class="btn pdl-btn line-btn medium-font">
                      {{'pdl-website.collectionsButton.buttonText' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>

      <div class="col-12 visible-below-767">
        <div class="collections-in-slider">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
            (init)="slickInit($event)">
            @for(collection of featuredCollectionList; track collection.CollectionID) {
            <div ngxSlickItem class="slide collection-slide pe-4">
              <div class="collection-card">
                @if(collection.IsFeatured) {
                <span class="featured__tag">{{'pdl-website.curatedCollections.featured' | translate}}</span>
                }
                <div class="block-img"
                  [ngClass]="{'threeImgCollection': collection.Documents.length === 3,'twoImgCollection': collection.Documents.length === 2 }">
                  @for(document of collection.Documents; track document.DocID) {
                  <img alt="Thumbimage" class="img-fluid"
                    src="{{imageBaseURL}}{{document.CategoryName}}/{{document.AccessionNo}}/Thumbnails/{{document.AccessionNo}}_90.jpg"
                    onerror="this.src='assets/images/icons/no-image-placeholder.svg'">
                  }
                </div>
                <div class="block-text">
                  <h5 class="medium-font"> {{collection.Title}}</h5>
                  <span class="number-of-docs">
                    <img src="assets/images/icons/folder-icon.svg" alt="">
                    {{collection.DocumentCount}} {{'pdl-website.numberofDocs.docsText' | translate }}
                  </span>
                  <p class="description-text">
                    {{collection.Description}}
                  </p>
                  <div class="line-btn-block">
                    <a (click)="changeCollection(collection.CollectionID)" class="btn pdl-btn line-btn medium-font">
                      {{'pdl-website.collectionsButton.buttonText' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            }
          </ngx-slick-carousel>
        </div>
      </div>

      @if(isForHomePage) {
      <div class="col-12">
        <div class="centered-btn d-flex align-items-center justify-content-center">
          <a href="#" [routerLink]="['/all-collections']"
            class="btn pdl-btn">{{'pdl-website.collectionsmainButton.buttonText' | translate }}</a>
        </div>
      </div>
      }

    </div>
  </div>
</section>

}
