/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { EventsCampaignsDetail, EventsCampaignsDetailResponse, EventsCampaignsDetailResponseFE, EventsCampaignsResponse, GetEventsCampaignsPayload, HomePageEventCampaignResponse } from 'shared/src/interfaces/events-campaigns.interface';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';

@Injectable({
  providedIn: 'root'
})
export class EventsCampaignsService {

  rootURL = environment.rootApiUrl;
  endPointUrl = WEB_API_ENDPOINTS.eventsCampaigns;
  constructor(
    private readonly http: HttpClient
  ) { }

  ///////////////// fetch event and campaign on home page ///////////////
  getHomePageEventsCampaigns() {
    const url = `${this.rootURL}${this.endPointUrl.homePage}`;
    return this.http
      .get<HomePageEventCampaignResponse>(url)
      .pipe(map((response: HomePageEventCampaignResponse) => response?.body));
  }

  ///////////// list all records ///////////////////
  getRecords(data: GetEventsCampaignsPayload) {
    let url = `${this.rootURL}${this.endPointUrl.default}`
      .replace('{pageSize}', data?.pageSize?.toString() ?? APP_CONSTANTS.pageSize.toString())
      .replace('{pageNumber}', data?.pageNumber?.toString() ?? '1')
      .replace('{type}', data?.type?.toString() ?? 'all');
    if (data.filters) {
      url += `&filters=${data?.filters?.toString()}`
    }
    if (data.startDate && data.endDate) {
      url += `&startDate=${data?.startDate}&endDate=${data?.endDate}`
    }
    if (data.latitude && data.longitude) {
      url += `&latitude=${data?.latitude}&longitude=${data?.longitude}`

    }
    return this.http.get<EventsCampaignsResponse>(url)
      .pipe(map((e: EventsCampaignsResponse) => e))
  }

  ////////////////////////// get detail ////////////////////
  getById(itemId:string) {
    const url = `${this.rootURL}${this.endPointUrl.getById}`.replace('{id}', itemId)
    return this.http.get<EventsCampaignsDetailResponseFE>(url)
      .pipe(map((e: EventsCampaignsDetailResponseFE) => e))
  }
}
