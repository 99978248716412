import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bookmark } from 'shared/src/interfaces/metadata.interface';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';

@Injectable({
  providedIn: 'root',
})
export class DocumentBookmarksService {
  private rootURL: string;

  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  /**
   * Get bookmarks for a specific document ID
   * @param docId The ID of the document
   * @returns Observable<Bookmark[]>
   */
  getBookmarksByDocId(docId: number): Observable<Bookmark[]> {
    return this.http.get<Bookmark[]>(`${this.rootURL}${WEB_API_ENDPOINTS.metadata.bookmarks}?docIds=${docId}`);
  }

  /**
   * Add a new bookmark
   * @param bookmark The bookmark data to add
   * @returns Observable<Bookmark>
   */
  addBookmark(bookmark: Omit<Bookmark, 'ID'>): Observable<Bookmark> {
    return this.http.post<Bookmark>(`${this.rootURL}${WEB_API_ENDPOINTS.metadata.bookmarks}`, bookmark);
  }

  /**
   * Delete a bookmark
   * @param bookmarkId The ID of the bookmark to delete
   * @returns Observable<void>
   */
  deleteBookmark(bookmarkId: number): Observable<void> {
    return this.http.delete<void>(`${this.rootURL}${WEB_API_ENDPOINTS.metadata.bookmarks}/${bookmarkId}`);
  }
}
