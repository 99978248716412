import { createReducer, on } from '@ngrx/store';
import { Bookmark } from 'shared/src/interfaces/metadata.interface';
import { DocumentBookmarksActions } from '../../actions';

export interface State {
  bookmarks: Bookmark[];
  loading: boolean;
  error: any;
}

export const initialState: State = {
  bookmarks: [],
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(DocumentBookmarksActions.loadBookmarks, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(DocumentBookmarksActions.loadBookmarksSuccess, (state, { bookmarks }) => ({
    ...state,
    loading: false,
    bookmarks,
  })),

  on(DocumentBookmarksActions.loadBookmarksFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(DocumentBookmarksActions.addBookmarkSuccess, (state, { bookmark }) => ({
    ...state,
    bookmarks: [...state.bookmarks, bookmark],
  })),

  on(DocumentBookmarksActions.addBookmarkFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    DocumentBookmarksActions.deleteBookmarkSuccess,
    (state, { bookmarkId }) => ({
      ...state,
      bookmarks: state.bookmarks.filter((b) => b.ID !== bookmarkId),
    })
  )
);
