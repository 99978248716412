import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AppLoadingService } from '../common/services/app-loading.service';
import { ToastComponent } from 'shared/src/lib/components/toast/toast.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  standalone: true,
  imports: [RouterModule,ToastComponent],
  selector: 'panjab-digi-lib-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'panjab-digi-lib';

  constructor(private appLoadingService: AppLoadingService, 
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.appLoadingService.initApp()
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
          const gtmTag = {
              event: 'page',
              pageName: item.url
          };

          this.gtmService.pushTag(gtmTag);
      }
  });
  }
}
