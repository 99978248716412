/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { AddEditNewsAndArticlesPayload, GetNewsAndArticlesPayload, NewsArticles, UpdateRecordStatusPayload } from 'shared/src/interfaces/news-and-articles.interface';
export const LoadRecords = createAction(
  '[NewsArticles] Load NewsArticles List',
  props<{ payload: GetNewsAndArticlesPayload }>()
);

export const LoadRecordsSuccess = createAction(
  '[NewsArticles] Load NewsArticles Success',
  props<{ recordsList: NewsArticles[]; totalRecords: number }>()
);

export const LoadRecordsError = createAction(
  '[NewsArticles] Load NewsArticles Error',
  props<{ message: string }>()
);

export const AddNewRecord = createAction(
  '[NewsArticles] Add item to the List',
  props<{ payload: AddEditNewsAndArticlesPayload }>()
);

export const RecordAddedSuccess = createAction(
  '[NewsArticles] Record Added Success',
  props<{ record: NewsArticles; message: string }>()
);

export const AddRecordError = createAction(
  '[NewsArticles] Add Record Error',
  props<{ message: string }>()
);

export const UpdateRecord = createAction(
  '[NewsArticles] Update Record in List',
  props<{ payload: AddEditNewsAndArticlesPayload, id:number }>()
);

export const RecordUpdatedSuccess = createAction(
  '[NewsArticles] Record Updated Success',
  props<{ record: NewsArticles; message: string }>()
);

export const UpdateRecordError = createAction(
  '[NewsArticles] Update Record Error',
  props<{ message: string }>()
);

export const UpdateRecordStatus = createAction(
  '[NewsArticles] Update Record Status in List',
  props<{ payload: UpdateRecordStatusPayload }>()
);

export const RecordStatusUpdatedSuccess = createAction(
  '[NewsArticles] Record Updated Status Success',
  props<{ record: NewsArticles[]; message: string }>()
);

export const UpdateRecordStatusError = createAction(
  '[NewsArticles] Update Record Status Error',
  props<{ message: string }>()
);

