/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent, AppState, CountryActions, CountrySelector, environment, SharedCommonService } from '@panjab-digi-lib/shared';
import { SocialLoginComponent } from '../common/social-login/social-login.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NgbDateStruct, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaComponent } from 'ng-recaptcha';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { UtilService } from '../../../common/services/util.service';
import { TrimmedTextDirective } from '../../../common/directives/trimmed-text.directive';
import { NumberonlyDirective } from '../../../common/directives/numberonly.directive';
import { DigitizationRequestService } from 'shared/src/services/website/digitization-request/digitization-request.service';
@Component({
  selector: 'panjab-digi-lib-digitization-request',
  standalone: true,
  imports: [CommonModule, TranslateModule, SocialLoginComponent, BreadcrumbsComponent, ReactiveFormsModule, NgbTooltipModule, RouterLink, AlertsComponent, SpinnerButtonComponent, RecaptchaModule,
     RecaptchaFormsModule, TrimmedTextDirective, NumberonlyDirective,NgbModule],
  providers: [DatePipe],
  templateUrl: './digitization-request.component.html',
  styleUrl: './digitization-request.component.scss',
})
export class DigitizationRequestComponent implements OnInit, OnDestroy {
  pageTitle = 'Upload a document';
  digitizationRequestForm!: FormGroup;
  countryList$!: Observable<any>;
  successMsg = false;
  isSubmitted = false;
  sitekey = environment.recaptcha.siteKey;
  isMobile = false;
  subscription = new Subscription();
  minDate!: NgbDateStruct;
  formattedStartDate: string = '';
  formattedEndDate: string = '';
  reasonList! : {ID: number,Reason:string}[];
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent;
  @ViewChild('moveToTop', { static: false }) moveToTop!: ElementRef;
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private el: ElementRef,
    private alertService: AlertService,
    private renderer: Renderer2,
    private utilService: UtilService,
    private service: DigitizationRequestService,
    private sharedService: SharedCommonService
  ) {
    this.getCountriesList();
    this.initContactForm();
  }

  ngOnInit() {
    // change recaptcha size when it is on mobile view
    if(window.innerWidth < 370) {
      this.isMobile = true;
    }
  }


  getCountriesList() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({payload}));
    this.countryList$ = this.store.select(CountrySelector.getCountries);
  }

  initContactForm() {
    this.digitizationRequestForm = this.fb.group(
      {
        type: [1],
        name: ['', [Validators.required, Validators.minLength(5), Validators.pattern(APP_CONSTANTS.regExp.alphaNumericOnly)]],
        phone: ['', [Validators.required, Validators.pattern(APP_CONSTANTS.regExp.numberOnly) ,Validators.minLength(10), Validators.maxLength(12)]],
        email: ['', {
          validators: [Validators.required, Validators.pattern(APP_CONSTANTS.regExp.email)],
          updateOn: 'blur'
        }],
        copyright: ['',[Validators.required, this.sharedService.commaSeparatedValidator(1)]],
        documentCount: [null,[Validators.required,Validators.pattern('^[0-9]*$')]],
        address: ['', [Validators.required]],
        city: ['', [Validators.required, Validators.maxLength(30)]],
        state: ['', [Validators.required, Validators.maxLength(30)]],
        country: [null, Validators.required],
        cpt: [null, Validators.required]

    });
  }


  resetForm() {
    this.digitizationRequestForm.reset();
    this.moveToFirstElement();
    this.resetReCaptcha();
  }

  moveToFirstElement(): void {
    this.renderer.selectRootElement(this.moveToTop.nativeElement).focus();
  }

  resolveCaptcha(captchaResponse: string | null) {
    if(captchaResponse) {
      this.digitizationRequestForm.patchValue({'cpt': captchaResponse})
    } else {
      this.digitizationRequestForm.controls['cpt'].setErrors({'invalid': true});
    }
  }

  resetReCaptcha() {
    this.captchaRef.reset();
  }

  onSubmit() {
    this.alertService.clear();
    this.digitizationRequestForm.markAllAsTouched();
    if(this.digitizationRequestForm.valid) {
      this.isSubmitted = true;
      const formData = this.digitizationRequestForm.value;
      formData.documentCount = Number(formData.documentCount);
      this.service.request(formData).subscribe({
        next: (res) => {
          this.successMsg = true;
          this.alertService.success(res.message);
          this.digitizationRequestForm.reset({
            type: 1 // Reset the 'status' to 'Author'
          });
          this.resetReCaptcha();
          this.isSubmitted = false;
        },
        error: (error) => {
          this.isSubmitted = false;
          this.resetReCaptcha();
          this.alertService.error(error.error.error.message || APP_CONSTANTS.defaultErrMsg);
        }
      });
    } else {
      this.utilService.focusOnInvalidField(this.digitizationRequestForm, this.el);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
