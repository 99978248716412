import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StaticPageHeadComponent } from '../staticPageHead/staticPageHead.component';
import { StaticPageService } from '../StaticPageService';
import { SafeHtmlPipe } from 'shared/src/pipes/safe-html.pipe';
import { environment } from '@panjab-digi-lib/shared';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import { SpinnerComponent } from '../../../../../../../shared/src/lib/components/spinner/spinner.component';

@Component({
  selector: 'panjab-digi-lib-team',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    StaticPageHeadComponent,
    SafeHtmlPipe,
    SpinnerComponent,
  ],
  templateUrl: './team.component.html',
  styleUrl: './team.component.scss',
})
export class TeamComponent {
  userTypes: { TypeID: number; Name: string }[] = [{ TypeID: 0, Name: ' ' }];
  activeType: { TypeID: number; Name: string } = { TypeID: 0, Name: ' ' };
  staffDetails: any = [];
  azureCloudStorageUrl = environment.azureCloudStrorage.publicUrl;
  fallbackImage =
    'https://api.dicebear.com/5.x/initials/svg?scale=50&size=197&backgroundColor=669074&seed=';
  loading: boolean = true;
  constructor(private StaticPageService: StaticPageService) {}
  ngOnInit(): void {
    this.StaticPageService.getUserTypes().subscribe((data: any) => {
      if (data.code === 200 && data?.body?.length) {
        this.userTypes = data.body;
        this.setActiveType(data.body[0]);
      }
    });
  }
  setActiveType = (type: any) => {
    if (this.activeType === type) return;
    this.activeType = type;
    this.loading = true
    this.getStaffDetails();
  };
  getStaffDetails = () => {
    this.StaticPageService.getStaff(this.activeType.TypeID).subscribe(
      (data: any) => {
        if (data.code === 200 && data.body?.staffDetails?.length) {
          this.staffDetails = data.body.staffDetails;
        } else {
          this.staffDetails = [];
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  };
  handleImageError(event: Event, staff: any) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage + staff.FName;
  }
  setStaffBio = (bio: string, id: number) => {
    if (bio.length > 126) {
      bio = bio.substring(0, 125);
    }
    return `${bio}`;
  };
}
