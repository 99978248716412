@if((donations$ | async)!.length !== 0) {
<div class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-between mb-4">
    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
        @if((donations$ | async)!.length > 0 && !(isLoading$ | async)) {
        <span>{{'pdl-shared.pagination.showing' | translate}} </span>
        <span>{{ totalRecords > 0 ? (pageNo - 1) * pageSize + 1 : 0 }} </span>
        <span>{{'pdl-shared.pagination.to' | translate}}</span>
        <span>{{ pageNo * pageSize < totalRecords ? pageNo * pageSize : totalRecords }} </span>
                <span>{{'pdl-shared.pagination.of' | translate}} </span>
                <span>{{totalRecords}} </span>
                <span>{{'pdl-shared.pagination.entries' | translate}} </span>
                }
    </div>
    <div class="page-filters-dropdown">
        <div class="dropdown page-filter-dropdown">
            <a class="page-filter-action btn btn-secondary dropdown-toggle" href="#" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                {{'pdl-admin.download.sortBy' | translate}}
            </a>

            <ul class="dropdown-menu">
                <li><a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='recent' ? 'active-sort' : ''"
                        (click)="sortDonations('Date', 'DESC', 'recent')">{{'pdl-shared.content.recent' |
                        translate}}</a></li>
                <li><a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='donationType' ? 'active-sort' : ''"
                        (click)="sortDonations('DonationType', 'ASC', 'donationType')">{{'pdl-shared.content.tableColumns.donationType'
                        | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>
}
@if((donations$ | async)!.length > 0 && !(isLoading$ | async)) {
<div class="table-responsive">
    <table class="table donation__table">
        <thead>
            <tr>
                <th>{{'pdl-shared.content.tableColumns.nameAndEmail' | translate}}</th>
                <th>{{'pdl-shared.content.tableColumns.country' | translate}}</th>
                <th>{{'pdl-shared.content.tableColumns.contactNumber' | translate}}</th>
                <th>{{'pdl-shared.content.tableColumns.donationType' | translate}}</th>
                <th>{{'pdl-shared.content.tableColumns.details' | translate}}</th>
                <th>{{'pdl-shared.content.tableColumns.makeMyDonation' | translate}}</th>
                <th>{{'pdl-shared.content.tableColumns.date' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            @for(donation of donations$ | async; track donation.DonID) {
            <tr>
                <td>
                    <p class="mb-0">{{donation.Name}}</p>
                    <small>{{donation.Email}}</small>
                </td>
                <td>{{donation.CountryName}}</td>
                <td>{{donation.Phone}}</td>
                <td>{{donation.DonationType}}</td>
                <td [ngClass]="{'wideColumn': donation.Description && donation.Description.length > 160}">
                    @if(donation.DonCatID == 1) {
                    {{donation.Currency}} {{donation.Amount}}
                    } @else {
                    <span class="text__ellipse" popoverClass="tooltip-custom-class">
                        {{donation.Description}}
                    </span>
                    @if(donation.Description && donation.Description.length > 160) {
                    <span class="grey-color cursor-pointer" [ngbPopover]="donorDescription"
                        triggers="mouseenter:mouseleave" popoverClass="tooltip-custom-class" #p="ngbPopover">
                        {{'pdl-shared.content.readMoreText' | translate}}
                    </span>
                    <ng-template #donorDescription>{{donation?.Description}}</ng-template>
                    }
                    }
                </td>
                <td>{{donation.PaySystem}}</td>
                <td>{{donation.Date | date:'dd/MMMM/yy HH:mm'}}</td>
            </tr>
            } @empty {
            <tr>
                <td colspan="6" class="text-center">
                    {{'pdl-shared.content.noRecordsFound' | translate}}
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>

@if (totalRecords > pageSize) {
<div class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4">
    <ngb-pagination class="donations__pagination" [collectionSize]="totalRecords" [(page)]="pageNo"
        [pageSize]="pageSize" [rotate]="true" [ellipses]="true" (pageChange)="loadDonations()" />
</div>
}
} @else if(isLoading$| async) {
<div class="loader__wrapper">
    <panjab-digi-lib-spinner></panjab-digi-lib-spinner>
</div>
} @else {
<div class="text-center mt-5">
    <p>{{'pdl-website.userProfile.userWithoutDonationTxt' | translate}}</p>
    <a [routerLink]="['/donate']" class="btn btn-primary">
        {{'pdl-website.userProfile.makeFirstDonation' | translate}}
    </a>
</div>
}
