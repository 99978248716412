<section class="bg-pdl-color" [ngClass]="!isLoading ? ['pt-5', 'pb-3'] : ''">
  @if(isLoading) {
    <div class="loader__wrapper">
      <panjab-digi-lib-spinner [isFullScreen]="false"></panjab-digi-lib-spinner>
    </div>
  } @else if(adopterDetails && adopterDetails.AdoptorType == adopterTypes.ORGANIZATION) {
  <div class="container mb-4">
    @if(adopterDetails.Image) {
      <img src="{{imageBaseURL}}{{adopterDetails.Image}}" alt="user image" width="200" height="200" class="rounded-circle ofc">
    } @else {
      <img src="assets/images/default-green-user.svg" alt="">
    }
    <div class="d-inline-block org__info">
      <span class="light_txt text-uppercase">
        {{adopterDetails.State}}, {{adopterDetails.Country}}
      </span>
      <h1 class="heading mt-3" [innerHTML]="adopterDetails.DisplayName"></h1>
      <p class="website__info">
        <span class="light_txt">Website: </span>
        <a [href]="adopterDetails.Website | httpFormat" target="_blank" rel="noopener noreferrer">{{ adopterDetails.Website }}</a>
      </p>
    </div>
  </div>

  <div class="bg-pdl-color-light container lrborder-mob mb-5">
    <h5 class="heading mb-3">About</h5>
    <p class="ms-3" [innerHTML]="adopterDetails.AboutOrg"></p>

    <section class="pt-5">
      <h5 class="heading">{{documentList.length}} Books Adopted</h5>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="documents-in-grid mt-2">
              <div class="row justify-content-center">
                @for (document of documentList; track document.DocID;) {
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a [href]="'/metadata/'+document.DocID" class="search-card mb-4">
                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          {{document.CategoryName}}
                        </span>
                        <span class="ratings d-none">
                          @for (star of getRating(document.UserRating); track star) {
                          <ng-container>
                            <span>
                              <img [src]="getStarImage(star)" alt="star icon" />
                            </span>
                          </ng-container>
                          }
                        </span>
                      </div>
                      <img alt="Thumbimage" class="img-fluid"
                        src="{{imageBaseURL}}{{document.CategoryName}}/{{document.AccessionNo}}/Thumbnails/{{document.AccessionNo}}_90.jpg"
                        onerror="this.src='assets/images/icons/no-image-placeholder.svg'">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14 no-scrollbar" [title]="document.Title"
                        style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden;">{{document?.Title}}</h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">{{document?.ScriptNames}}</span>
                        <span class="font-10 d-flex">{{document?.TotalPages}} PAGES</span>
                      </div>
                    </div>
                  </a>
                </div>
                } @empty {
                <div class="no-results d-flex">
                  {{'pdl-shared.content.noRecordsFound' | translate}}
                </div>
                }
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
  </div>
  } @else {
    <div class="no-results d-flex">
      <p>{{'pdl-shared.content.noRecordsFound' | translate}}</p>
    </div>
  }
</section>