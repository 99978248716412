import { Injectable } from '@angular/core';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GetNewsAndArticlesPayload, GetNewsAndArticlesResponse, NewsAndArticlesDetailResponse, SubjectResponse } from '../../../interfaces/news-and-articles.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsAndArticlesService {

  rootURL = '';
  endPointUrl = WEB_API_ENDPOINTS.newsAndArticles;
  constructor(
    private readonly http: HttpClient
  ) {
    this.rootURL = environment.rootApiUrl;
  }

  ////////////// Video detail ////////////////
  getDetail(subjectId: string, id: string) {
    const url = `${this.rootURL}${this.endPointUrl.newsArticlesById}`
      .replace('{subjectId}', (subjectId ?? '').toString())
      .replace('{id}', (id ?? '').toString());
    return this.http
      .get<NewsAndArticlesDetailResponse>(url)
      .pipe(map((e: NewsAndArticlesDetailResponse) => e?.body));
  }

  loadNewsAndArticles(payload: GetNewsAndArticlesPayload) {
    const url = `${this.rootURL}${this.endPointUrl.default}`
    .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
    .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
    .replace('{subjectId}', (payload?.subjectId ?? 1).toString())
    .replace('{orderBy}', (payload?.orderBy ?? 'DateAdded').toString())
    .replace('{order}', (payload?.order ?? 'DESC').toString());
    return this.http
      .get<GetNewsAndArticlesResponse>(url)
      .pipe(map((e: GetNewsAndArticlesResponse) => e?.body));
  }

  getSubjects() {
    const url = `${this.rootURL}${this.endPointUrl.getNewsAndArticlesSubjects}`;
    return this.http
      .get<SubjectResponse>(url)
      .pipe(map((e: SubjectResponse) => e?.body));
  }
}
