/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminContributorActions, AdminEmployeeActions, DonorActions } from '../actions';
import {
  catchError,
  exhaustMap,
  map,
  of,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import { EmployeeService } from 'shared/src/services/admin/employee/employee.service';
import { Store, select } from '@ngrx/store';
import { AdminEmployeeSelector, AppState, DonorSelector } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';

@Injectable()
export class AdminEmployeeEffects {
  constructor(
    private actions$: Actions,
    private service: EmployeeService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  //////////////// get all Employees ////////////
  loadEmployeeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEmployeeActions.LoadEmployees),
      exhaustMap((action) => {
        return this.service.loadEmployeeList(action?.payload).pipe(
          map((response) => {
            return AdminEmployeeActions.LoadEmployeesSuccessfully({
              employeeList: response?.data,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminEmployeeActions.LoadEmployeesError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Employee ////////////
  AddEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEmployeeActions.AddEmployee),
      exhaustMap((action) => {
        return this.service.addEmployee(action?.payload).pipe(
          map((response) => {
            return AdminEmployeeActions.AddEmployeeSuccessfully({
              employee: response?.body,
              message: response?.message,
              referrer: action.referrer
            });
          }),
          catchError((error) =>
            of(
              AdminEmployeeActions.AddEmployeeError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update employee ////////////
  UpdateEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEmployeeActions.UpdateEmployee),
      exhaustMap((action) => {
        return this.service.updateSponsorship(action?.payload, action?.id).pipe(
          map((response) => {
            return AdminEmployeeActions.UpdateEmployeeSuccessfully({
              employee: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEmployeeActions.UpdateEmployeeError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Employee status////////////
  UpdateEmployeeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEmployeeActions.UpdateEmployeeStatus),
      exhaustMap((action) => {
        return this.service.updateEmployeeStatus(action?.payload).pipe(
          map((response) => {
            return AdminEmployeeActions.UpdateEmployeeStatusSuccessfully({
              employee: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEmployeeActions.UpdateEmployeeStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  // //////////////// update role of Employee with services ////////////
  UpdateRoleEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEmployeeActions.UpdateRoleEmployee),
      exhaustMap((action) => {
        return this.service.updateRoleServices(action?.payload).pipe(
          map((response) => {
            return AdminEmployeeActions.UpdateRoleEmployeeSuccessfully({
              employee: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEmployeeActions.UpdateRoleEmployeeError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

    /////////////////////// delete employee //////////////////////////
    DeleteEmployee$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AdminEmployeeActions.DeleteEmployee),
        exhaustMap((action) =>
          this.service.deleteEmployee(action?.payload).pipe(
            map((response) => {
              return AdminEmployeeActions.DeleteEmployeeSuccess({
                message: response?.message,
              });
            }),
            catchError((res) =>
              of(
                AdminEmployeeActions.DeleteEmployeeError({
                  message: res.error?.error?.message,
                })
              )
            )
          )
        )
      )
    );

  ///////////////////// redirect after adding Employee /////////////////////
  AddEmployeeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEmployeeActions.AddEmployeeSuccessfully),
      withLatestFrom(
        this.store.pipe(select(AdminEmployeeSelector.loadEmployeeList))
      ),
      switchMap(([action, state]) => {
        if(action?.employee?.UserTypeName == 'Contributor') {
          if(action.referrer == 'donor') {
            this.router.navigateByUrl('/web-user-management/donors/list-of-donors');
            return this.store.select(DonorSelector.getListingPayload).pipe(
              take(1),
              switchMap((payload) => of(DonorActions.LoadDonorList({ payload })))
            );
          } else if(action.referrer == 'adopter') {
            this.router.navigateByUrl('/fundraising/adopt/list-adopters');
          } else {
            this.router.navigateByUrl('/pdl-employees/board-of-directors');  
          }
        } else if (action?.employee?.UserType === 'T') {
          this.router.navigateByUrl('/pdl-employees/board-of-directors');
        } else if (action?.employee?.UserType === 'E') {
          this.router.navigateByUrl('/pdl-employees/employees');
        }
        
        const pageNumber = 1;
        const payload = {
          pageNumber: pageNumber,
          pageSize: state?.pageSize,
          type: action?.employee?.UserType,
        };
        return of(AdminEmployeeActions.LoadEmployees({ payload }));
      })
    )
  );

    ///////////////////// delete employee success /////////////////////
    DeleteEmployeeSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AdminEmployeeActions.DeleteEmployeeSuccess),
        withLatestFrom(
          this.store.pipe(select(AdminEmployeeSelector.loadEmployeeList))
        ),
        switchMap(([action, state]) => {
          const pageNumber = 1;
          const payload = {
            pageNumber: pageNumber,
            pageSize: state?.pageSize,
            type: "T"
          };
          return of(AdminEmployeeActions.LoadEmployees({ payload }));
        })
      )
    );


}
