import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment, WebNewsAndArticlesSelector, WebNewsAndArticlesActions, DocumentFilesActions, WebDocumentFileSelector, SharedCommonService } from '@panjab-digi-lib/shared';
import { APP_CONSTANTS as CONSTANTS } from '../../../../common/constants/app.constants';
import { filter, Subject, Subscription, take, takeUntil, throwError } from 'rxjs';
import { ImageReaderComponent } from '../../meta-data/image-reader/image-reader.component';
import { ReaderComponent } from '../../meta-data/reader/reader.component';
import { MetadataService } from 'shared/src/services/website/metadata/metadata.service';
import { GrowSpinnerComponent } from 'shared/src/lib/components/grow-spinner/grow-spinner.component'
@Component({
  selector: 'panjab-digi-lib-news-and-article-detail',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, ReaderComponent, ImageReaderComponent, GrowSpinnerComponent],
  templateUrl: './news-and-article-detail.component.html',
  styleUrl: './news-and-article-detail.component.scss',
})
export class NewsAndArticleDetailComponent implements OnInit, OnDestroy {
  document:any;
  subjectId!:string;
  articleId!:string;
  relatedArticles:any;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  fallbackImage = CONSTANTS.newsAndArticlesFallbackImage;
  subscription = new Subscription();
  metadata: any = null;
  accession_number = '';
  old_accession_number = '';
  isLoading = true;
  documentsByTopic = [];
  publishedInSameYear: any = [];
  imageFiles: any[] = [];
  pdfFiles: any[] = [];
  loadingImageAndPdf = true;
  unsubscribe$ = new Subject<void>();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly metaDataService: MetadataService,
    private readonly sharedService: SharedCommonService
  ) {}
  ngOnInit() {
    // monitor route changes and load the details
    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
        this.subjectId = params.get('subjectId') ?? '';
        this.articleId = params.get('id') ?? '';
        // Load document details for the aricle ID
        this.loadDocDetails(this.subjectId, this.articleId);
    });
  }

  loadDocFiles(docId:string) {
    // dispatch the loadDocumentFiles request for the given docID
    this.store.dispatch(DocumentFilesActions.loadDocumentFiles({ docID: Number(docId) }));
    this.store
      .select(WebDocumentFileSelector.selectImageFiles)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(imageFiles => {
        this.imageFiles = imageFiles;
      });

    this.store
      .select(WebDocumentFileSelector.selectPdfFiles)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pdfFiles => {
        this.pdfFiles = pdfFiles;
      });

    this.store
      .select(WebDocumentFileSelector.selectIsLoading)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isLoading => {
        this.loadingImageAndPdf = isLoading;
      });
  }

  getMetaData(docId: string): any {
    this.metadata = null;
    this.isLoading = true;
    this.documentsByTopic = [];
    if (!docId) {
      return throwError(() => new Error('Document ID is missing.'));
    }
    const decodedId = this.sharedService.decodeFromBase64(docId);
    this.metaDataService
      .getMetaData(decodedId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response:any) => {
          this.isLoading = false;
          this.metadata = response.body.data;
          this.accession_number = this.metadata.AccessionNo;
          this.old_accession_number = this.metadata.OldAccessionNo;
          // Load document files after metadata
          this.loadDocFiles(decodedId);
        },
        error: error => {
          this.isLoading = false;
          this.metadata = null;
          this.accession_number = '';
          this.old_accession_number = '';
          if (error?.error?.error?.message) {
            console.error(error?.error?.error?.message);
          }
        }
      });
  }

  getArticlesOnSameTopic() {
    this.store.select(WebNewsAndArticlesSelector.getRelatedNewsAndArticles)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.relatedArticles = data?.body;
      });
  }

  loadDocDetails(subjectId:string, id: string) {
    this.document = '';
    this.store.dispatch(WebNewsAndArticlesActions.LoadNewsAndArticlesDetails({ payload: {subjectId, id}}));
    this.store.select(WebNewsAndArticlesSelector.getNewsAndArticlesDetails)
      .pipe(
        filter((data) => !!data?.body),
        take(1)
      )
      .subscribe((data) => {
        this.document = data?.body;
        // get relelated articles with same subject
        this.getArticlesOnSameTopic();
        // fetch document metadata and load the document reader
        this.getMetaData(this.document?.DocID);
      });
  }

  //////////// get thumbnail image //////////////
  getThumbnailImg(item: any): string {
    return `${this.imageBaseUrl}${item.CategoryName}/${item.AccessionNo}/Thumbnails/${item.AccessionNo}_200.jpg`;
  }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
