/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminNewsAndArticlesActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { NewsArticles } from 'shared/src/interfaces/news-and-articles.interface';

export interface State extends EntityState<NewsArticles> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber : number,
  pageSize: number
}

export const adapter = createEntityAdapter<NewsArticles>({
  selectId: (e) => e?.ID,
});

export const initialState : State = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0
});

export const reducer = createReducer(
  initialState,

  on(AdminNewsAndArticlesActions.LoadRecords, (state,action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
    orderBy: action.payload?.orderBy,
    order: action.payload?.order
  })),

  on(AdminNewsAndArticlesActions.LoadRecordsSuccess, (state, action) =>
    adapter.setAll(action?.recordsList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  on(AdminNewsAndArticlesActions.LoadRecordsError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminNewsAndArticlesActions.AddNewRecord, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminNewsAndArticlesActions.RecordAddedSuccess, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminNewsAndArticlesActions.AddRecordError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminNewsAndArticlesActions.UpdateRecord, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminNewsAndArticlesActions.RecordUpdatedSuccess, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.record?.ID, changes: action.record },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminNewsAndArticlesActions.UpdateRecordError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminNewsAndArticlesActions.UpdateRecordStatus, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminNewsAndArticlesActions.RecordStatusUpdatedSuccess, (state, action) => {
    const updates = action?.record;
    const updatedEntities = adapter.updateMany(
      updates.map((update: NewsArticles) => ({
        id: update.ID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminNewsAndArticlesActions.UpdateRecordStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),
);

export const { selectAll } = adapter.getSelectors();
