<ng-container>
    <div class="d-flex align-items-center">
        <a class="breadcrumb-link font-regular" [routerLink]="'/'">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="home icon">
            Home
        </a>
        <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="right arrow">
        </span>
        <a class="breadcrumb-link font-regular" [routerLink]="route || null">
          {{ pageName }}
        </a>
    </div>
</ng-container>
