/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { GlobalDividerComponent } from '../../divider/global-divider.component';
import { ToastService } from 'shared/src/services/toast/toast.service';
import {
  environment,
  SharedCommonService,
  SpinnerComponent,
} from '@panjab-digi-lib/shared';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { GoogleMapsService } from 'shared/src/services/common/google-maps.service';
import { TranslateModule } from '@ngx-translate/core';

import {
  EventsCampaignsDetailFE,
  OtherLocation,
} from 'shared/src/interfaces/events-campaigns.interface';
import { EventsCampaignsService } from 'shared/src/services/website/events-campaigns/events-campaigns.service';
declare const google: any;
export interface MarkerData {
  position: google.maps.LatLngLiteral;
  title: string;
  location: string;
  date: {
    startDate: Date;
    endDate: Date;
  };
  time: {
    startTime: string;
    endTime: string;
  };
}
@Component({
  selector: 'panjab-digi-lib-events-campaign-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    GlobalDividerComponent,
    SpinnerComponent,
    TranslateModule,
  ],
  providers: [DatePipe],
  templateUrl: './events-campaign-detail.component.html',
  styleUrl: './events-campaign-detail.component.scss',
})
export class EventsCampaignDetailComponent implements OnInit, OnDestroy {
  itemId!: string;
  subscription = new Subscription();
  isLoading: boolean = true;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  itemDetail!: EventsCampaignsDetailFE;
  currentSlideIndex: number = 0;
  map!: google.maps.Map;
  mapOptions!: { center: object; zoom: number };
  fallbackImage = 'assets/images/icons/no-image-placeholder.svg';
  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('gallery') gallery!: ElementRef;
  zoom: number = 8;
  markerPositions: MarkerData[] = [];

  constructor(
    private readonly service: EventsCampaignsService,
    private readonly toastService: ToastService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly mapsService: GoogleMapsService,
    private readonly router: Router,
    private readonly datePipe: DatePipe,
    private readonly commonService: SharedCommonService
  ) {}

  ngOnInit(): void {
    this.itemId = this.activatedRoute.snapshot.params['id'];
    this.loadDetail(this.itemId);
  }

  ////////////////////  load details ///////////////////
  loadDetail(itemId: string) {
    this.isLoading = true;
    this.subscription.add(
      this.service.getById(itemId).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.itemDetail = response.body;
          this.mapsService
            .loadMapsApi()
            .then(() => {
              this.markerPositions.push({
                position: {
                  lat: this.itemDetail.Latitude,
                  lng: this.itemDetail.Longitude,
                },
                title: this.itemDetail.Title,
                location: this.itemDetail.Location,
                date: this.itemDetail.Date,
                time: this.itemDetail.Time,
              });

              // Add additional locations if available
              if (this.itemDetail.OtherLocations) {
                this.itemDetail.OtherLocations.forEach(
                  (location: OtherLocation) => {
                    this.markerPositions.push({
                      position: {
                        lat: location.Latitude,
                        lng: location.Longitude,
                      },
                      title: this.itemDetail.Title,
                      location: location.Location,
                      date: location.Date,
                      time: location.Time,
                    });
                  }
                );
              }
              this.mapInitializer();
            })
            .catch((error) => {
              console.error('Error loading Google Maps API:', error);
            });
        },
        error: (error) => {
          this.isLoading = false;
          this.toastService.showError(
            error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
          );
        },
      })
    );
  }

  /////////////////////// set up google map and its coordinates ///////////////////////
  mapInitializer(): void {
    setTimeout(() => {
      this.mapOptions = {
        center: {
          lat: this.itemDetail.Latitude,
          lng: this.itemDetail.Longitude,
        },
        zoom: this.zoom,
      };
      this.map = new google.maps.Map(
        this.mapContainer.nativeElement,
        this.mapOptions
      );
      const svgIcon = `
      <svg width="27" height="37" viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 0C6 0 0 6.02308 0 13.5519C0 21.0808 6 27.1038 13.5 36.1385C21 27.1038 27 21.0808 27 13.5519C27 6.02308 21 0 13.5 0ZM13.5 9.03461C15.9898 9.03461 18 11.0526 18 13.5519C18 16.0513 15.9898 18.0692 13.5 18.0692C11.0102 18.0692 9 16.0513 9 13.5519C9 11.0526 11.0102 9.03461 13.5 9.03461Z" fill="#477958"/>
      </svg>
      `;
      const encodedSvg = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
        svgIcon
      )}`;
      this.markerPositions.forEach((markerData: MarkerData) => {
        const marker = new google.maps.Marker({
          position: markerData.position,
          map: this.map,
          icon: encodedSvg,
        });
        const content = this.generateMapMarkers(markerData);
        marker.addListener('click', () => {
          const infoWindow = new google.maps.InfoWindow({
            content: content,
          });
          infoWindow.open(this.map, marker);
        });
      });
    }, 500);
  }

  //////////// generate google map markers ///////////
  generateMapMarkers(markerData: any) {
    return `
        <div style="width: 220px;box-shadow: 5px 5px 10px 0px #0000000F;padding: 12px 14px 2px 12px; display: flex; flex-direction: column; gap: 10px;" class="">
           <span style="font-family: Lato;font-size: 12px;font-weight: 700;line-height: 14.4px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #333333;">
           ${markerData.title}
           </span>
           <div class="d-flex align-items-center" style="gap: 5px;">
              <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M3.42545 9.98209C3.33209 9.93537 3.25376 9.85996 3.20051 9.76548C2.33346 8.4528 1.45985 7.14515 0.597515 5.82857C0.269065 5.32326 0.0702313 4.73475 0.0207646 4.12158C-0.124809 2.52439 0.682481 0.988062 2.05176 0.354371C3.3808 -0.261513 4.6361 -0.0738474 5.75993 0.911413C6.0988 1.20601 6.37991 1.56935 6.58715 1.98045C6.79438 2.39161 6.92373 2.8424 6.96768 3.30712C7.07933 4.24065 6.85075 5.18431 6.32926 5.94242C5.48988 7.20684 4.64993 8.47362 3.80926 9.74261C3.75458 9.84656 3.67157 9.93018 3.57125 9.98219L3.42545 9.98209ZM4.66216 3.7573C4.66334 3.42782 4.54244 3.11127 4.32596 2.87689C4.10943 2.64252 3.8149 2.50945 3.50688 2.50673C3.19881 2.50401 2.90233 2.63192 2.68226 2.86247C2.46221 3.09302 2.3365 3.40739 2.33265 3.73689C2.32874 4.0663 2.4471 4.38397 2.66168 4.62035C2.87626 4.85668 3.16968 4.99253 3.4777 4.99803C3.78876 5.00207 4.0885 4.87346 4.31076 4.64062C4.53296 4.40784 4.6594 4.08997 4.66213 3.75728L4.66216 3.7573Z" fill="#525964"/>
              </svg>
              <span style="font-family: Lato;
                 font-size: 10px;
                 font-weight: 400;
                 line-height: 12px;
                 text-align: left;
                 text-underline-position: from-font;
                 text-decoration-skip-ink: none;
                 ">${markerData.location}</span>
           </div>
           <div class="pill" style="font-family: Lato;
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              border-radius: 2px 2px 10px 10px;
              background: #edf2ee;
              font-size: 7px;
              font-weight: 500;
              line-height: 13px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              padding: 4px 11px;
              color: #477958;">${this.datePipe.transform(
                markerData.date.startDate,
                'd MMMM yyyy'
              )} - ${this.datePipe.transform(
      markerData.date.endDate,
      'd MMMM yyyy'
    )} | ${this.formatTime(markerData.time.startTime)} - ${this.formatTime(
      markerData.time.endTime
    )}</div>
        </div>
        `;
  }

  formatTime(timeString: string): string | null {
    if (!timeString) return null;
    const today = new Date();
    const [hours, minutes] = timeString.split(':').map(Number);
    today.setHours(hours, minutes, 0, 0);
    return this.datePipe.transform(today, 'h:mm a');
  }

  sanitizeHTML = (htmlStr: string) => this.commonService.sanitizeHTML(htmlStr);
  fixLength = (text: string, length: number) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length - 1)}...`;
  };
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
