import { Route } from '@angular/router';
import { CategoriesComponent } from './main/categories/categories.component';
import { HomeComponent } from './main/home/home.component';
import { MainComponent } from './main/main.component';
import { DownloadsComponent } from './main/downloads/downloads.component';
import { AdvancedSearchComponent } from './main/advanced-search/advanced-search.component';
import { AllResultsComponent } from './main/all-results/all-results.component';
import { MetadataComponent } from './main/metadata/metadata.component';
import { RegisterUserComponent } from './main/register-user/register-user.component';
import { LoginUserComponent } from './main/login-user/login-user.component';
import { authGuard } from '../common/guards/auth.guard';
import { VerifyUserComponent } from './main/verify-user/verify-user.component';
import { ReaderComponent } from './main/meta-data/reader/reader.component';
import { RecoverPasswordComponent } from './main/recover-password/recover-password.component';
import { ResetPasswordComponent } from './main/reset-password/reset-password.component';
import { VideoGalleryComponent } from './main/video-gallery/video-gallery.component';
import { ForgotUsernameComponent } from './main/forgot-username/forgot-username.component';
import { VideoViewComponent } from './main/video-gallery/video-view/video-view.component';
import { UserProfileComponent } from './main/user-profile/user-profile.component';
import { DonationComponent } from './main/donation/donation.component';
import { DonationsComponent } from './main/user/donations/donations.component';
import { UserComponent } from './main/user/user.component';
import { PrivacyPolicyComponent } from './main/static/privacy-policy/privacy-policy.component';
import { AdoptedBooksComponent } from './main/user/adopted-books/adopted-books.component';
import { DisclaimerComponent } from './main/static/disclaimer/disclaimer.component';
import { WorkingGroupsComponent } from './main/static/workingGroups/working-groups.component';
import { CurrentProjectsComponent } from './main/static/current-projects/currentProjects.component';
import { DataMiningComponent } from './main/static/data-mining/dataMining.component';
import { InterlibraryComponent } from './main/static/interlibrary/interlibrary.component';
import { PreservationComponent } from './main/static/preservation/preservation.component';
import { DigitizationComponent } from './main/static/digitization/digitization.component';
import { DigitizationTraintingComponent } from './main/static/digitization-training/digitization-trainting.component';
import { MetadataSchemaComponent } from './main/static/metadata-schema/metadata-schema.component';
import { PolicyComponent } from './main/static/policy/policy.component';
import { DonorLevelComponent } from './main/static/donor-level/donor-level.component';
import { InfoForCustodiansComponent } from './main/static/info-for-custodians/info-for-custodians.component';
import { FormsComponent } from './main/static/forms/forms.component';
import { HistoryComponent } from './main/static/history/history.component';
import { TeamComponent } from './main/static/team/team.component';
import { ListOfCustodiansComponent } from './main/static/list-of-custodians/list-of-custodians.component';
import { SearchResultsComponent } from './main/search-results/search-results.component';
import { FAQComponent } from './main/static/FAQ/FAQ.component';
import { AcknowledgementsComponent } from './main/static/acknowledgements/acknowledgements.component';
import { CaseStudiesComponent } from './main/static/case-studies/case-studies.component';
import { ContactUsComponent } from './main/contact-us/contact-us.component';
import { VolunteerRequestComponent } from './main/volunteer-request/volunteer-request.component';
import { ExhibitionRequestComponent } from './main/exhibition-request/exhibition-request.component';
import { AdoptABookComponent } from './main/adopt-a-book/adopt-a-book.component';
import { AdoptBookFormComponent } from './main/adopt-a-book/adopt-book-form/adopt-book-form.component';
import { MyLibraryComponent } from './main/user/my-library/my-library.component';
import { MyResearchTopicDetailComponent } from './main/user/my-library/my-research-topic-detail/my-research-topic-detail.component';
import { ExhibitionsComponent } from './main/exhibitions/exhibitions.component';
import { AllExhibitionsComponent } from './main/exhibitions/all-exhibitions/all-exhibitions.component';
import { ExhibitionDetailsComponent } from './main/exhibitions/exhibition-details/exhibition-details.component';
import { ExhibitionItemsComponent } from './main/exhibitions/exhibition-details/exhibition-items/exhibition-items.component';
import { NewsAndArticlesComponent } from './main/news-and-articles/news-and-articles.component';
import { NewsAndArticleDetailComponent } from './main/news-and-articles/news-and-article-detail/news-and-article-detail.component';
import { CuratedCollectionListComponent } from './main/curated-collection/curated-collection-list/curated-collection-list.component';
import { CollectionDetailsComponent } from './main/curated-collection/collection-details/collection-details.component';
import { TeamMemberComponent } from './main/static/team/team-member/team-member.component';
import { DigitizationRequestComponent } from './main/digitization-request/digitization-request.component';
import { UploadDocumentComponent } from './main/upload-document/upload-document.component';
import { canDeactivateGuard } from '../common/guards/can-deactivate.guard';
import { UploadHistoryComponent } from './main/user/upload-history/upload-history.component';
import { EventsCampaignsComponent } from './main/events-campaigns/events-campaigns.component';
import { EventsCampaignDetailComponent } from './main/events-campaigns/events-campaign-detail/events-campaign-detail.component';
import { OrgAdoptedDocsComponent } from './main/org-adopted-docs/org-adopted-docs.component';
import { CanDeactivateGuard } from '../common/guards/canDeactivate.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        // canActivate: [authGuard],
      },
      {
        path: 'all-collections',
        component: CuratedCollectionListComponent,
      },
      {
        path: 'collection/:id',
        component: CollectionDetailsComponent,
      },
      {
        path: 'all-exhibitions',
        component: AllExhibitionsComponent,
      },
      {
        path: 'exhibition/:id',
        component: ExhibitionDetailsComponent,
      },
      {
        path: 'exhibitions',
        component: ExhibitionsComponent,
        children: [],
      },
      {
        path: 'exhibition/:id/items',
        component: ExhibitionItemsComponent,
      },
      {
        path: 'all-categories',
        component: CategoriesComponent,
      },
      {
        path: 'advanced-search',
        component: AdvancedSearchComponent,
      },
      {
        path: 'all-downloads',
        component: DownloadsComponent,
      },
      {
        path: 'search',
        component: SearchResultsComponent,
      },
      {
        path: 'all-results',
        component: AllResultsComponent,
      },
      {
        path: 'metadata/:id',
        component: MetadataComponent,
      },
      {
        path: 'adopted-docs/:id',
        component: OrgAdoptedDocsComponent,
      },
      {
        path: 'donate',
        component: DonationComponent,
      },
      {
        path: 'register-user',
        component: RegisterUserComponent,
      },
      {
        path: 'login-user',
        component: LoginUserComponent,
      },
      {
        path: 'verify-user/:token',
        component: VerifyUserComponent,
      },
      {
        path: 'reader',
        component: ReaderComponent,
      },
      {
        path: 'recover-password',
        component: RecoverPasswordComponent,
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
      },
      {
        path: 'video-gallery',
        component: VideoGalleryComponent,
      },
      {
        path: 'video/:id',
        component: VideoViewComponent,
      },
      {
        path: 'forgot-username',
        component: ForgotUsernameComponent,
      },
      {
        path: 'profile/:id',
        component: UserProfileComponent,
        canActivate: [authGuard],
      },
      {
        path: 'user',
        component: UserComponent,
        canActivate: [authGuard],
        children: [
          { path: '', redirectTo: 'donations', pathMatch: 'full' },
          {
            path: 'donations',
            component: DonationsComponent,
            data: {
              title: 'pdl-website.userProfile.donationTitle',
            },
          },
          {
            path: 'adopted-books',
            component: AdoptedBooksComponent,
            data: {
              title: 'pdl-website.userProfile.adoptedBooks',
            },
          },
          {
            path: 'my-library',
            component: MyLibraryComponent,
            data: {
              title: 'pdl-website.userProfile.myLibrary',
            },
          },
          {
            path: 'my-collections',
            component: CuratedCollectionListComponent,
            data: {
              title: 'pdl-website.userProfile.myCollections',
            },
          },
          {
            path: 'upload-history',
            component: UploadHistoryComponent,
            data: {
              title: 'pdl-website.userProfile.uploadedDocuments',
            },
          },
        ],
      },
      {
        path: 'user/collection/:id',
        component: CollectionDetailsComponent,
      },
      {
        path: 'user/research-topic/:id',
        component: MyResearchTopicDetailComponent,
        canActivate: [authGuard],
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'disclaimer',
        component: DisclaimerComponent,
      },
      {
        path: 'working-groups',
        component: WorkingGroupsComponent,
      },
      {
        path: 'current-projects',
        component: CurrentProjectsComponent,
      },
      {
        path: 'data-mining',
        component: DataMiningComponent,
      },
      {
        path: 'interlibrary',
        component: InterlibraryComponent,
      },
      {
        path: 'preservation',
        component: PreservationComponent,
      },
      {
        path: 'digitization',
        component: DigitizationComponent,
      },
      {
        path: 'digitization-training',
        component: DigitizationTraintingComponent,
      },
      {
        path: 'metadata-schema',
        component: MetadataSchemaComponent,
      },
      {
        path: 'policy',
        component: PolicyComponent,
      },
      {
        path: 'donor-levels',
        component: DonorLevelComponent,
      },
      {
        path: 'info-for-custodians',
        component: InfoForCustodiansComponent,
      },
      {
        path: 'forms',
        component: FormsComponent,
      },
      {
        path: 'history',
        component: HistoryComponent,
      },
      {
        path: 'team',
        component: TeamComponent,
      },
      {
        path: 'team/:id',
        component: TeamMemberComponent,
      },
      {
        path: 'list-of-custodians',
        component: ListOfCustodiansComponent,
      },
      {
        path: 'faq',
        component: FAQComponent,
      },
      {
        path: 'Acknowledgments',
        component: AcknowledgementsComponent,
      },
      {
        path: 'case-studies',
        component: CaseStudiesComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'digitization-request',
        component: DigitizationRequestComponent,
      },
      {
        path: 'upload-document',
        component: UploadDocumentComponent,
        canActivate: [authGuard],
        canDeactivate: [canDeactivateGuard],
      },
      {
        path: 'digitization-request',
        component: DigitizationRequestComponent,
      },
      {
        path: 'volunteer',
        component: VolunteerRequestComponent,
      },
      {
        path: 'exhibition-request',
        component: ExhibitionRequestComponent,
      },
      {
        path: 'adopt-a-book',
        component: AdoptABookComponent,
      },
      {
        path: 'adopt-a-book/checkout',
        component: AdoptBookFormComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'news-and-articles',
        component: NewsAndArticlesComponent,
      },
      {
        path: 'news-and-articles/:subjectId/:id',
        component: NewsAndArticleDetailComponent,
      },
      {
        path: 'events-campaigns',
        component: EventsCampaignsComponent,
      },
      {
        path: 'events-campaigns/:id',
        component: EventsCampaignDetailComponent,
      },
      {
        path: '**',
        redirectTo: '/',
      },
    ],
  },
];
