/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GetNewsAndArticlesPayload, NewsArticles, SubjectResponse } from "shared/src/interfaces/news-and-articles.interface";

export const LoadSubjects = createAction(
    '[WebNewsArticles] Load Sujects List',
    props<{payload: GetNewsAndArticlesPayload}>()
)

export const LoadSubjectsSuccess = createAction(
    '[WebNewsArticles] Load Sujects Successfully',
    props<{subjects: SubjectResponse}>()
)

export const LoadSubjectsError = createAction(
    '[WebNewsArticles] Load Sujects Error',
    props<{message: string}>()
)

export const LoadNewsAndArticles = createAction(
    '[WebNewsArticles] Load NewsArticles List',
    props<{payload: GetNewsAndArticlesPayload}>()
)

export const LoadNewsAndArticlesSuccess = createAction(
    '[WebNewsArticles] Load NewsArticles Successfully',
    props<{ recordsList: NewsArticles[]; totalRecords: number }>()
)

export const LoadNewsAndArticlesError = createAction(
    '[WebNewsArticles] Load NewsArticles Error',
    props<{message: string}>()
)

export const LoadNewsAndArticlesDetails = createAction(
    '[WebNewsArticles] Load NewsArticles details by id',
    props<{payload: {subjectId: string, id: string, }}>()
)

export const LoadNewsAndArticlesDetailsSuccess = createAction(
    '[WebNewsArticles] Load NewsArticles details Successfully',
    props<{ record: {articleDetails: NewsArticles, relatedArticles: NewsArticles[]} }>()
)

export const LoadNewsAndArticlesDetailsError = createAction(
    '[WebNewsArticles] Load NewsArticles details Error',
    props<{message: string}>()
)