<panjab-digi-lib-static-page-head
  [pageName]="'pdl-website.newsandarticles.heading' | translate" [pagePath]="pagePath"></panjab-digi-lib-static-page-head>
<section class="news-articles-section section-padding">
  <div class="container">
    <div class="d-flex align-items-center justify-content-center">
      <div class="text-about-page">
        <p class="pdl-grey text-center">{{'pdl-website.newsandarticlesSection.listPageDescription' | translate }}</p>
      </div>
    </div>
    <div class="faq-header-wrapper mb-5 no-scrollbar mt-3">
      @for (subject of subjectsList; track subject.id) {
        <span class="faq-item" [ngClass]="{'active-item' : subject.id === activeTab}"> 
          <span (click)="setActiveTab(subject.id)" class="cursor-pointer" tabindex="">{{subject.name}}</span>
        </span>
      }
    </div>
    <div class="list_section d-flex flex-wrap justify-content-center">
      @for(item of newsAndArticlesList; track item.ID) {
      <div class="item">
        <a href="javascript:void(0);" [routerLink]="'/news-and-articles/'+activeTab+'/'+item.ID">
          <img [src]="getThumbnailImg(item)" (error)="handleImageError($event)"  class="banner_img" alt="" />
        </a>
        <div class="item_content">
          <span class="pill">Published on: {{item.PublishedDate | date : 'dd MMMM YYYY'}} </span>
          <span class="heading"><a href="javascript:void(0);" [routerLink]="'/news-and-articles/'+activeTab+'/'+item.ID">{{item.Title | titlecase}}</a></span>
        </div>
      </div>
      }
    </div>
    @if(newsAndArticlesList.length > maxSize) {
    <div class="row">
      <div class="col-12">
        <div class="pdl-pagination d-flex align-items-center justify-content-center webPagination__wrapper mt-5">
          <nav aria-label="Page navigation example">
            <ngb-pagination class="donations__pagination" [collectionSize]="totalRecords" [page]="pageNumber" [pageSize]="pageSize"
              [rotate]="true" [ellipses]="true" [maxSize]="maxSize" (pageChange)="loadNewsAndArticles()" />
          </nav>
        </div>
      </div>
    </div>
    }
  </div>
</section>
