import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { DocumentNotesActions, WebDocumentNotesSelector } from '@panjab-digi-lib/shared';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-notes',
  standalone: true,
  imports: [CommonModule, NgbPopover, FormsModule, TranslateModule],
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss',
})
export class NotesComponent  implements OnInit{
  @Input() showNotesContainer: boolean = false;
  @Input() docId!: number;
  @Input() pages!: number[]; // Receive document ID as input
  @Output() oncloseNotesContainer =  new EventEmitter();
  notes$!: Observable<any[]>;
  isLoading$!: Observable<boolean>;
  errorMessage$!: Observable<any | null>;
  currentPageNumber: number = 0;

  hasNotes$!: Observable<boolean>;
  enableAddNotes = false;
  // newNoteContent = '';

  showLoader = false; // Loader for adding notes
  popoverNoteId: number | null = null;

  editNoteId: number | null = null; // Track the note being edited
  editNoteContent: string = ''; // Hold the updated content temporarily

  enableEditNotes = false;
  editingNote: any = null;




  constructor(private store: Store, private actions$: Actions) {
    this.notes$ = this.store.select(WebDocumentNotesSelector.selectAllNotes);
    this.isLoading$ = this.store.select(WebDocumentNotesSelector.selectIsLoading);
    this.errorMessage$ = this.store.select(WebDocumentNotesSelector.selectErrorMessage);
    this.hasNotes$ = this.notes$.pipe(
      map((notes) => notes && notes.length > 0)
    );
  }

  ngOnInit(): void {
    if (this.docId) {
      this.loadNotes(this.docId);
    }
    this.actions$
    .pipe(ofType(DocumentNotesActions.createNoteSuccess))
    .subscribe(() => {
      this.closeAddNotes();
      this.loadNotes(this.docId);
    });
    this.actions$
    .pipe(ofType(DocumentNotesActions.deleteNoteSuccess))
    .subscribe(() => {
      this.loadNotes(this.docId); 
    });
    this.actions$
    .pipe(ofType(DocumentNotesActions.updateNoteByIdSuccess))
    .subscribe(() => {
      this.loadNotes(this.docId); 
    });
    this.actions$
    .pipe(ofType(DocumentNotesActions.loadNotesByDocIdSuccess))
    .subscribe(() => {
      this.showLoader = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['pages'] &&
      changes['pages'].currentValue !== changes['pages'].previousValue
    ) {
      this.currentPageNumber = changes['pages'].currentValue[0];
    }
  }

  enableEditMode(noteId: number, currentContent: string) {
    this.editNoteId = noteId; // Set the note ID being edited
    this.editNoteContent = currentContent; // Set the current note content
  }
  showEditNotes(note: any) {
    this.enableEditNotes = true;
    this.editingNote = note;
    this.currentPageNumber = note.PageNumber; // Set the current page for editing
  }

  updateNote(updatedText: string) {
    if (!this.editingNote) return;
  
    const updatedPayload = {
      ...this.editingNote,
      Note: updatedText,
      PageNumber: this.editingNote.PageNumber,
    };
  
    this.store.dispatch(
      DocumentNotesActions.updateNoteById({ payload: updatedPayload })
    );
  
    this.closeEditNotes();
  }
  
  
  cancelEdit() {
    this.editNoteId = null; // Exit edit mode
    this.editNoteContent = ''; // Clear the temporary content
  }
  closeEditNotes() {
    this.enableEditNotes = false;
    this.editingNote = null;
  }

  updatePageNo (pageNo: number) {
    this.currentPageNumber = pageNo;
  }

  showAddNotes() {
    this.enableAddNotes = true;
  }
  
  loadNotes(docID: number) {
    this.store.dispatch(DocumentNotesActions.loadNotesByDocId({ docID }));
  }

  addNote(noteText: any) {
    this.showLoader = true;
    this.closeAddNotes();
    let payload = {Note: noteText, DocID: this.docId, PageNumber: this.currentPageNumber};
    this.store.dispatch(DocumentNotesActions.createNote({ payload }));
  }

  deleteNote(noteId: number) {
    this.showLoader = true;
    this.store.dispatch(DocumentNotesActions.deleteNoteById({ noteId }));
    this.popoverNoteId = null;
  }

  closeNotes() {
    this.oncloseNotesContainer.emit();
  }
  closeAddNotes() {
    this.enableAddNotes = false;
  }
}
