/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminEventsCampaignsActions } from '../actions';
import {
  catchError,
  exhaustMap,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AdminEventsCampaignsSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';
import { EventsCampaignsService } from 'shared/src/services/admin/events-campaigns/events-campaigns.service';

@Injectable()
export class AdminEventsCampaignsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: EventsCampaignsService,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}

  //////////////// get list ////////////
  LoadEventsCampaignsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEventsCampaignsActions.LoadRecords),
      exhaustMap((action) => {
        return this.service.getAllEventsCampaigns(action?.payload).pipe(
          map((response) => {
            return AdminEventsCampaignsActions.LoadRecordsSuccess({
              recordsList: response?.recordsList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminEventsCampaignsActions.LoadRecordsError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add detail ////////////
  SaveEventsCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEventsCampaignsActions.SaveRecord),
      switchMap((action) => {
        return this.service.saveDetails(action?.payload).pipe(
          map((response) => {
            return AdminEventsCampaignsActions.SaveRecordSuccess({
              record: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEventsCampaignsActions.SaveRecordError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// delete record  ////////////
  DeleteEventsCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEventsCampaignsActions.DeleteRecord),
      switchMap((action) => {
        return this.service
          .deleteItem(action?.payload?.id)
          .pipe(
            map((response) => {
              return AdminEventsCampaignsActions.DeleteRecordSuccess({
                message: response?.message,
              });
            }),
            catchError((error) =>
              of(
                AdminEventsCampaignsActions.DeleteRecordError({
                  message: error?.error?.error?.message,
                })
              )
            )
          );
      })
    )
  );

  //////////////// update status ////////////
  UpdateEventsCampaignsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEventsCampaignsActions.UpdateRecordStatus),
      exhaustMap((action) => {
        return this.service.updateStatus(action?.payload).pipe(
          map((response) => {
            return AdminEventsCampaignsActions.UpdateRecordStatusSuccess({
              record: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEventsCampaignsActions.UpdateRecordStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////////// redirect after add/delete/update record /////////////////////
  AddEventsCampaignsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminEventsCampaignsActions.SaveRecordSuccess,
        AdminEventsCampaignsActions.DeleteRecordSuccess,
        AdminEventsCampaignsActions.UpdateRecordStatusSuccess
      ),
      withLatestFrom(
        this.store.pipe(select(AdminEventsCampaignsSelector.loadEventsCampaignsList))
      ),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/events-campaigns');
        const pageNumber =
          action.type === AdminEventsCampaignsActions.SaveRecordSuccess.type
            ? 1
            : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, type : state?.type, orderBy: 'CURRENT', order: 'Desc' };
        return of(AdminEventsCampaignsActions.LoadRecords({ payload }));
      })
    )
  );
}
