/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import {
  ExhibitionDetailResponse,
  ExhibitionResponse,
} from 'shared/src/interfaces/exhibition.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExhibitionService {
  appURL = '';
  endPointUrl = WEB_API_ENDPOINTS.exhibition;
  constructor(private http: HttpClient) {
    this.appURL = environment.rootApiUrl + environment.appApiPrefix;
  }

  EXHIBITIONS = {
    Current: 'Current',
    Upcoming: 'Upcoming',
    Past: 'Past',
  };
  ///////////// list all exhibitions ///////////////////
  get(data: any) {
    let url = `${this.appURL}${this.endPointUrl.get}`
      .replace(
        '{pageSize}',
        data?.pageSize?.toString() ?? APP_CONSTANTS.pageSize.toString()
      )
      .replace('{pageNumber}', data?.pageNumber?.toString() ?? '1');
    if (data.filters) {
      url += `&filters=${data?.filters?.toString()}`;
    }
    if (data.startDate && data.endDate) {
      url += `&startDate=${data?.startDate}&endDate=${data?.endDate}`;
    }
    if (data.latitude && data.longitude) {
      url += `&latitude=${data?.latitude}&longitude=${data?.longitude}`;
    }
    return this.http
      .get<ExhibitionResponse>(url)
      .pipe(map((e: ExhibitionResponse) => e));
  }

  ////////////////////////// website api's ////////////////////
  getById(exhibitionId: number) {
    const url = `${this.appURL}${this.endPointUrl.getById}`.replace(
      '{id}',
      exhibitionId?.toString()
    );
    return this.http
      .get<ExhibitionDetailResponse>(url)
      .pipe(map((e: ExhibitionDetailResponse) => e));
  }

  ////////////////////// exhibition List /////////////////////
  exhibitionList() {
    const url = `${this.appURL}${this.endPointUrl.list}`;
    return this.http
      .get<{ body: { ExhibitionID: number; Title: string }[] }>(url)
      .pipe(map((e: { body: { ExhibitionID: number; Title: string }[] }) => e));
  }

  ////////////////////// exhibition request /////////////////////
  request(payload: any) {
    const url = `${this.appURL}${this.endPointUrl.request}`;
    return this.http.post(url, payload).pipe(map((e) => e));
  }
}
