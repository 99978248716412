/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { DocumentInfo } from "shared/src/interfaces/document-info.interface";
import { AddAdopters } from "shared/src/interfaces/fundraising.interface";

export const LoadToAdopt = createAction(
    '[ToAdopt] Load To Adopt Documents',
    props<{payload: GenericListingPayload}>(),
)

export const LoadToAdoptSuccess = createAction(
    '[ToAdopt] Load To Adopt Documents Successfully',
    props<{documentList: DocumentInfo[], totalCount: number}>()
)

export const LoadToAdoptError = createAction(
    '[ToAdopt] Load To Adopt Documents Error',
    props<{message: string}>()
)

export const AddAdopter = createAction(
    '[ToAdopt] Add Adopter details for Documents',
    props<{payload: AddAdopters}>(),
)

export const AddAdopterSuccess = createAction(
    '[ToAdopt] Add Adopter details for Documents Success',
    props<{createdAdopters: any, message: string, reload: boolean}>(),
)

export const AddAdopterError = createAction(
    '[ToAdopt] Add Adopter details for Documents Error',
    props<{message: string}>(),
)

export const ListForAdoptionStatusUpdate = createAction(
    '[ArticleComment] Update ListForAdoption Status',
    props<{ids: number[], status: boolean}>(),
)

export const ListForAdoptionStatusUpdateSuccess = createAction(
    '[ArticleComment] Update ListForAdoption Status Success',
    props<{updatedDocuments: any, message: string}>(),
)

export const ListForAdoptionStatusUpdateError = createAction(
    '[ArticleComment] Update ListForAdoption Status Error',
    props<{message: string}>(),
)