import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NewsAndArticlesPath, GetNewsAndArticlesPayload, GetNewsAndArticlesResponse, AddEditNewsAndArticlesPayload, AddEditNewsAndArticlesResponse, UpdateRecordStatusPayload, UpdateStatusResponse } from '../../../interfaces/news-and-articles.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsAndArticlesService {

  rootURL = '';
  endPointUrl = API_ENDPOINTS.newsAndArticles;
  constructor(
    private readonly http: HttpClient
  ) {
    this.rootURL = environment.rootApiUrl;
  }
  ////////// get accession detail //////////////////
  accessionDetail(accessionNo: string) {
    const url = `${this.rootURL}${this.endPointUrl.path}`.replace(
      '{accessionNumber}',
      accessionNo ?? ''
    );
    return this.http.get<NewsAndArticlesPath>(url).pipe(map((e: NewsAndArticlesPath) => e));
  }

  ////////// add Video to Gallery /////////
  addNewsAndArticle(payload: AddEditNewsAndArticlesPayload) {
    const url = `${this.rootURL}${this.endPointUrl.add}`;
    return this.http
      .post<AddEditNewsAndArticlesResponse>(url, payload)
      .pipe(map((e: AddEditNewsAndArticlesResponse) => e));
  }

  ////////// update detail /////////
  updateDetail(payload: AddEditNewsAndArticlesPayload, id: number) {
    let url = `${this.rootURL}${this.endPointUrl.newsArticlesById}`;
    url = url.replace('{id}', (id ?? '').toString());
    return this.http
      .put<AddEditNewsAndArticlesResponse>(url, payload)
      .pipe(map((e: AddEditNewsAndArticlesResponse) => e));
  }

  ////////// update status/////////
  updateStatus(payload: UpdateRecordStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.newsArticles}`;
    return this.http
      .patch<UpdateStatusResponse>(url, payload)
      .pipe(map((e: UpdateStatusResponse) => e));
  }

  ////////////// Video detail ////////////////
  getDetail(id: number) {
    const url = `${this.rootURL}${this.endPointUrl.newsArticlesById}`.replace(
      '{id}',
      (id ?? '').toString()
    );
    return this.http
      .get<AddEditNewsAndArticlesResponse>(url)
      .pipe(map((e: AddEditNewsAndArticlesResponse) => e?.body));
  }

  loadNewsAndArticles(payload: GetNewsAndArticlesPayload) {
    const url = `${this.rootURL}${this.endPointUrl.default}`
    .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
    .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
    .replace('{type}', (payload?.type ?? 'all').toString())
    .replace('{orderBy}', (payload?.orderBy ?? 'DateAdded').toString())
    .replace('{order}', (payload?.order ?? 'DESC').toString());
    return this.http
      .get<GetNewsAndArticlesResponse>(url)
      .pipe(map((e: GetNewsAndArticlesResponse) => e?.body));
  }
}
