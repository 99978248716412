export const WEB_API_ENDPOINTS = {
  homePage: {
    getBanners: 'app/banner',
    getTrendingKeywords: 'app/trending',
    getCategories: 'app/category',
    getDownloads: 'app/downloads',
    videos: 'app/videos?type=homepage',
    search: 'app/suggestion?query={query}&categoryId={categoryId}',
    popularReads: 'app/popularRead/get',
  },
  downloads: {
    allDownloads:
      'app/allDownloads?pageNumber={pageNumber}&pageSize={pageSize}&sortBy={sortBy}',
  },
  userDownloads: {
    downloadTypes: 'app/userDownload/types/{id}',
    checkLimit: 'app/userDownload/checkLimit',
    download: 'app/userDownload/download{type}/{id}',
  },
  videos: {
    video: 'app/video/{id}',
    videos: 'app/videos?orderBy={orderBy}&type=all',
  },
  user: {
    donations: 'user/donations',
    getAdoptedBooks: 'user/adoptedBooks',
  },
  volunteer: {
    validateReq: 'volunteer/validate',
    addReq: 'volunteer/add-request',
  },
  search: {
    default: 'app/search',
    filters: 'app/filters',
    searchCollection: 'app/searchCollection',
    save: 'app/saveSearch',
    update: 'app/updateSavedSearch/{id}',
    delete: 'app/deleteSearch/{id}',
  },
  userLibrary: {
    addResearchTopic: 'app/library/topic/add',
    editResearchTopic: 'app/library/topic/edit/{id}',
    assignDocumentToTopic: 'app/library/topic/assign',
    researchTopics:
      'app/library/topic/get?pageSize={pageSize}&pageNumber={pageNumber}',
    topicDetail:
      'app/library/topic/get/{id}?pageSize={pageSize}&pageNumber={pageNumber}',
    deleteResearchTopic: 'app/library/topic/delete/{id}',
    deleteDocumentFromResearchTopic:
      'app/library/topic/assigned/delete/{id}/{docId}',
    userResearchTopic: 'app/library/topic/myResearchTopics/get',
    suggestedReads: 'app/suggestedReads',
  },
  exhibition: {
    get: 'exhibitions?pageNumber={pageNumber}&pageSize={pageSize}',
    getById: 'exhibition/{id}',
    list: 'exhibitions/list',
    request: 'exhibitionRequest',
  },
  contactUs: {
    reason: 'contactUs/reason',
    request: 'contactUs/add',
  },
  digitizationRequest: {
    add: 'digitizationRequest/add',
  },
  uploadDocument: {
    uploadTypes: 'userUpload/uploadTypes/{id}',
    metaDataFields: 'userUpload/metadataDetail/{id}',
    add: 'userUpload/add',
    search:
      'userUpload/search?collection={collection}&searchField={searchField}&query={query}',
    addNewFieldData: 'userUpload/fieldData/add',
    default: 'userUpload/upload',
    list: 'userUpload/list?pageNumber={pageNumber}&pageSize={pageSize}',
  },
  curatedCollection: {
    get: 'app/curatedCollections',
    getFeatured: 'app/curatedCollections/featured',
    getUserCollections: 'app/curatedCollections/user/collections',
    document: 'app/curatedCollections/document',
    addToUserCollection: 'app/curatedCollections/user/addCollection',
    removeFromUserCollection: 'app/curatedCollections/user/removeCollection',
  },
  adoption: {
    getBooks: 'app/adoption',
    getAdoptionDetails: 'app/adoption/getAdoptionDetails',
    getIPBasedLocation: 'app/getIPBasedLocation',
    cart: 'app/adoption/cart',
    getOrderStatus: 'app/getOrderStatus',
    syncCart: 'app/adoption/syncCart',
    guestUserCart: 'app/adoption/guestUserCart',
    validateCart: 'app/adoption/validateCart',
    createOrder: 'app/adoption/createOrder',
    cancelOrder: 'app/adoption/cancelOrder',
    verifyPaypalPayment: 'app/adoption/verifyPaypalPayment',
    verifyPayment: 'app/adoption/verifyPayment',
    saveOrderInfo: 'app/adoption/saveOrderInfo',
  },
  metadata: {
    getFiles: 'app/documentInfo/files',
    notes: 'app/notes',
    bookmarks: 'app/bookmarks',
  },
  newsAndArticles: {
    default:
      'app/newsAndArticles/list?pageNumber={pageNumber}&pageSize={pageSize}&subjectId={subjectId}&orderBy={orderBy}&order={order}',
    newsArticles: 'app/newsAndArticles/list',
    newsArticlesById: 'app/newsAndArticles/{subjectId}/{id}',
    getNewsAndArticlesSubjects: 'app/newsAndArticles/subjectsList',
  },
  eventsCampaigns: {
    default:
      'app/eventsCampaigns/list?pageNumber={pageNumber}&pageSize={pageSize}&subjectId={subjectId}&orderBy={orderBy}&order={order}',
    homePage: 'app/eventsCampaigns',
    getById: 'app/eventsCampaigns/{id}',
  },
};
