import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { DocumentBookmarksActions } from '../../actions';
import { DocumentBookmarksService } from 'shared/src/services/website/metadata/document-bookmarks.service';

@Injectable()
export class DocumentBookmarksEffects {
    constructor(private actions$: Actions, private bookmarksService: DocumentBookmarksService) {}

    loadBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentBookmarksActions.loadBookmarks),
            mergeMap(({ docId }) =>
                this.bookmarksService.getBookmarksByDocId(docId).pipe(
                    map((bookmarks: any) => DocumentBookmarksActions.loadBookmarksSuccess({ bookmarks: bookmarks?.body })),
                    catchError((error) => of(DocumentBookmarksActions.loadBookmarksFailure({ error: error?.error?.error }))),
                )
            )
        )
    );

    addBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentBookmarksActions.addBookmark),
            mergeMap(({ bookmark }) =>
                this.bookmarksService.addBookmark(bookmark).pipe(
                    map((newBookmark) => DocumentBookmarksActions.addBookmarkSuccess({ bookmark: newBookmark })),
                    catchError((error) => of(DocumentBookmarksActions.addBookmarkFailure({ error: error?.error?.error }))),
                )
            )
        )
    );

    deleteBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentBookmarksActions.deleteBookmark),
            mergeMap(({ bookmarkId }) =>
                this.bookmarksService.deleteBookmark(bookmarkId).pipe(
                    map(() => DocumentBookmarksActions.deleteBookmarkSuccess({ bookmarkId })),
                    catchError((error) => of(DocumentBookmarksActions.deleteBookmarkFailure({ error: error?.error?.error }))),
                )
            )
        )
    );
}
