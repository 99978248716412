/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { EventsCampaignsResponse, UpdateRecordStatusPayload, UpdateStatusResponse, EventsCampaignsDetailResponse, ChildEventsCampaignsPayload, GetEventsCampaignsPayload, SaveEventsCampaignsResponse } from 'shared/src/interfaces/events-campaigns.interface';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';

@Injectable({
  providedIn: 'root'
})
export class EventsCampaignsService {
  rootURL = environment.rootApiUrl;
  endPointUrl = API_ENDPOINTS.eventsCampaigns;
  constructor(
    private readonly http: HttpClient
  ) { }

  ///////////////// save event/campaign details //////////////////
  saveDetails(payload: FormData) {
    const url = `${this.rootURL}${this.endPointUrl.add}`;
    return this.http
      .post<EventsCampaignsDetailResponse>(url, payload)
      .pipe(map((e: EventsCampaignsDetailResponse) => e));
  }

  ///////////////// fetch all parent child ///////////////
  getAllEventsCampaigns(data: GetEventsCampaignsPayload) {
    const url = `${this.rootURL}${this.endPointUrl.default}`
      .replace('{pageSize}', data?.pageSize?.toString() ?? APP_CONSTANTS.pageSize.toString())
      .replace('{pageNumber}', data?.pageNumber?.toString() ?? '1')
      .replace('{type}', data?.type?.toString() ?? '1')
      .replace('{orderBy}', data?.orderBy ?? '')
      .replace('{order}', data?.order ?? '');
    return this.http
      .get<EventsCampaignsResponse>(url)
      .pipe(map((response: EventsCampaignsResponse) => response?.body));
  }

  //////////////////// get details ///////////////
  getDetails(id: number) {
    const url = `${this.rootURL}${this.endPointUrl.eventsCampaignsById}`.replace('{id}', id.toString());
    return this.http
      .get<EventsCampaignsDetailResponse>(url)
      .pipe(map((response: EventsCampaignsDetailResponse) => response?.body));
  }

  //////////// add child //////////////////
  addChildItem(payload: ChildEventsCampaignsPayload) {
    const url = `${this.rootURL}${this.endPointUrl.addChild}`.replace('{id}', payload?.itemId.toString());
    return this.http
      .post<SaveEventsCampaignsResponse>(url, payload)
      .pipe(map((response: SaveEventsCampaignsResponse) => response));
  }

  //////////// edit child //////////////////
  editChildItem(payload: ChildEventsCampaignsPayload) {
    const url = `${this.rootURL}${this.endPointUrl.editChild}`.replace('{id}', payload?.itemId.toString());
    return this.http
      .put<SaveEventsCampaignsResponse>(url, payload)
      .pipe(map((response: SaveEventsCampaignsResponse) => response));
  }

  //////////// enable/disable item //////////////////
  updateStatus(payload: UpdateRecordStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.eventsCampaigns}`;
    return this.http
      .patch<UpdateStatusResponse>(url, payload)
      .pipe(map((response: UpdateStatusResponse) => response));
  }

  ////////////// delete item ////////////
  deleteItem(id: number) {
    const url = `${this.rootURL}${this.endPointUrl.eventsCampaignsById}`.replace('{id}', id.toString());
    return this.http
      .delete<EventsCampaignsResponse>(url)
      .pipe(map((response: EventsCampaignsResponse) => response));
  }
}


