<div class="container pt-4 pb-4">
  <div class="d-flex justify-content-end upload__document__btn">
    <a class="btn btn__primary" routerLink="/upload-document">Upload a document</a>
  </div>
  <!-- loop here -->
   @for(document of uploadedDocumentList;track document.DocID;let index = $index) {
  <div class="content" [ngClass]="index%2 === 0 ? 'odd' : 'even'">
    <div class="d-flex justify-content-between">
    <a class="text-capitalize doc__title" [routerLink]="document.DocumentStatus === 1 ? '/metadata/' + document.DocID : null">{{document.Title}}</a>
    <span class="oval" [ngClass]="document.Status === 3? 'rejected' : document.Status === 2 ? 'approved' : 'pending'">
      {{document.Status === 3? 'rejected' : document.Status === 2 ? 'approved' : 'pending'}}
    </span>
    
    </div>
    <div class="d-flex meta_info">
      <div class="icon_text">
        <img src="assets/images/icons/book.svg" /> {{document.CategoryName}}
      </div>
      <div class="icon_text">
        <img src="assets/images/icons/calendar-ico.svg" /> {{document.DateAdded | date: 'dd MMMM YYYY'}}
      </div>
    </div>
    <div class="text">
      <span class="des" [innerHTML]="isExpandedMap[document.DocID] ? document.Description : getTruncatedText(document.Description)"></span>
       @if(shouldShowReadMore(document.Description)) { 
        <span class="grey-color cursor-pointer" (click)="toggleDescription(document.DocID)">
          {{ isExpandedMap[document.DocID] ? ('pdl-shared.content.readLessText' | translate) : ('pdl-shared.content.readMoreText' | translate) }}
        </span>
      }
    </div>
  </div>
 }@empty {
         <div class="text-center my-5">
              {{'pdl-shared.content.noRecordsFound' | translate}}
            </div>
 }
    @if(totalRecords > pageSize) {
    <div class="row">
      <div class="col-12">
        <div class="pdl-pagination d-flex align-items-center justify-content-center webPagination__wrapper mt-5">
          <nav aria-label="Page navigation example">
            <ngb-pagination class="donations__pagination" [collectionSize]="totalRecords" [(page)]="pageNumber"  [pageSize]="pageSize"
              [rotate]="true" [ellipses]="true" [maxSize]="maxSize" (pageChange)="fetchUserUploadHistory()" />
          </nav>
        </div>
      </div>
    </div>
  }
