import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HighlightTextDirective } from 'apps/panjab-digi-lib/src/common/directives/highlight-text';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthSelector, environment } from '@panjab-digi-lib/shared';
import { NotesComponent } from '../notes/notes.component';
import { BookmarksComponent } from '../bookmarks/bookmarks.component';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { LoggedInUser } from 'shared/src/interfaces/auth-user.interface';

declare const BookReader: any;
declare const document: any;
@Component({
  selector: 'panjab-digi-lib-image-reader',
  standalone: true,
  imports: [CommonModule, FormsModule, HighlightTextDirective, TranslateModule, NotesComponent, BookmarksComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrl: './image-reader.component.scss',
  templateUrl: './image-reader.component.html'
})
export class ImageReaderComponent implements OnChanges {
  private br: any;
  @Input() images: any[] = [];
  @Input() docId!: number;
  @Input() accessionNo: string = '';
  @Input() metadata: any = {};
  bookmarks: number[] = [];
  settingsMenuVisible = false;
  fontSize = 16;
  bgColor = '#ffffff';
  currentPageText = '';
  openFullImmersionTheater = false;
  searchTerm: string= '';
  activeUser: any;

  isHovered = false;
  showNotesContainer = false;
  showBookMarksContainer = false;

  constructor(
    private store: Store,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    const pollForElements = setInterval(() => {
      const bookReaderElement = document.querySelector('ia-bookreader');
      if (bookReaderElement?.shadowRoot) {

        const itemNavigator = bookReaderElement.shadowRoot.querySelector('iaux-item-navigator');
        const menuSlider = itemNavigator?.shadowRoot?.querySelector('ia-menu-slider');
        const menuButtons = menuSlider?.shadowRoot?.querySelectorAll('menu-button');
    
        if (menuButtons && menuButtons.length > 0) {
          menuButtons.forEach((btn: any) => {
            const iconElement = btn.shadowRoot?.querySelector('.icon');
            if (iconElement && iconElement.style) {
              iconElement.style.minWidth = "2.7rem";
              iconElement.style.marginLeft = "3px";
            }
            if(btn.shadowRoot?.querySelector("ia-icon-search")) {
              const icon = btn.shadowRoot?.querySelector("ia-icon-search");
              icon.style.width = "30px";
              icon.style.marginTop = "8px";
            }
            if(btn.shadowRoot?.querySelector("ia-icon-visual-adjustment")) {
              const icon = btn.shadowRoot?.querySelector("ia-icon-visual-adjustment");
              icon.style.width = "30px";
              icon.style.marginTop = "8px";
            }
          })
          const searchButton = itemNavigator?.shadowRoot?.querySelector("ia-icon-search");
          
          searchButton.addEventListener("click", () => {
            setTimeout(() => {
              this.setupSearchValidation();
            }, 100);
          })
          clearInterval(pollForElements); // Stop polling
        }
      }
    }, 1000);
    this.store
    .select(AuthSelector.selectWebUser)
    .subscribe((user: LoggedInUser) => {
      if (user) {
        this.activeUser = user;
      } else if (this.localStorage.get('webUser')) {
        this.activeUser = this.localStorage.get('webUser');
      }
    })
  }

  setupSearchValidation(): void {
    const bookReaderElement = document.querySelector('ia-bookreader');
    const itemNavigator = bookReaderElement.shadowRoot.querySelector('iaux-item-navigator');
    const menuSlider = itemNavigator?.shadowRoot?.querySelector('ia-menu-slider');
    const searchResultContainer = menuSlider?.shadowRoot?.querySelector('ia-book-search-results');
    const searchInput = searchResultContainer?.shadowRoot?.querySelector('input[type="search"]');
    if (searchInput) {
      searchInput.autocomplete = "off";
      searchInput.addEventListener('input', (event: Event) => {
        const inputElement = event.target as HTMLInputElement;
        const searchTerm = inputElement.value;
  
        if (searchTerm.trim().length < 3) {
          inputElement.setCustomValidity("Enter at least 3 characters for search.");
          inputElement.reportValidity();
        } else {
          inputElement.setCustomValidity(""); // Clear validation message
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images'] && changes['images'].currentValue && changes['images'].currentValue?.length > 0) {
      setTimeout(() => {
        this.getImageDimension().subscribe(dimensions => {
          this.initializeBookReader(dimensions);
        });
      }, 100);
    }
  }

  getImageDimension(): Observable<any> {
    return new Observable(observer => {
        const img = new Image();
        img.onload = function (event) {
            const loadedImage: any = event.currentTarget;
            const width = loadedImage.width;
            const height = loadedImage.height;
            observer.next({height, width});
            observer.complete();
        }
        // get first image url if single image exists
        img.src = this.images.length >= 4 ? this.images[4].url : this.images[0].url;
    });
  }
  initializeBookReader(dimensions: any): void {
    const options = {
      getNumLeafs: () => this.images.length,
      getPageWidth: () => dimensions.width ? dimensions.width : 2000,
      getPageHeight: () => dimensions.height ? dimensions.height : 3000,
      getPageURI: (index: number) => this.images[index]?.url || '',
      getPageSide: (index: number) => (index % 2 === 0 ? 'R' : 'L'),
      pageProgression: 'lr',
      getSpreadIndices: function (pindex: number) {
        const spreadIndices: (number | null)[] = [null, null];
        if (this.pageProgression === 'rl') {
          if (this.getPageSide(pindex) === 'R') {
            spreadIndices[1] = pindex;
            spreadIndices[0] = pindex + 1;
          } else {
            spreadIndices[0] = pindex;
            spreadIndices[1] = pindex - 1;
          }
        } else {
          if (this.getPageSide(pindex) === 'L') {
            spreadIndices[0] = pindex;
            spreadIndices[1] = pindex + 1;
          } else {
            spreadIndices[1] = pindex;
            spreadIndices[0] = pindex - 1;
          }
        }
        return spreadIndices;
      },
      getPageNum: (index: number) => index + 1,
      bookTitle: this.metadata.Title,
      thumbnail: this.images[0].url,
      metadata: [{ label: 'Title', value: this.metadata.Title }],
      ui: 'full',
      enableExperimentalControls: true,
      showToolbar: false,
      showNavbar: true,
      protected: true,
      search: 'visible',
      sideBar: 'visible',
      bookmarks: 'visible',
      share: 'visible',
      enableSearch: true,
      enableBookmark: true,
      enableFullscreen: true,
      enableShare: true,
      enableSidebar: true,
      enableDownload: true,
      searchInsidePostTag: "}}}",
      searchInsidePreTag: "{{{",
      searchInsideProtocol: "https",
      searchInsideUrl: environment.reader.iiifSearchPath,
      initialSearchTerm: "",
      bookId: this.accessionNo
    };
  
    // Instantiate BookReader
    this.br = new BookReader(options);
    this.br.server = environment.reader.server;
    
    setTimeout(() => {
      this.br.bookId = this.accessionNo;
      this.br.init();
    }, 1000);
  }

  addBookmark(page: number): void {
    if (!this.bookmarks.includes(page)) {
      this.bookmarks.push(page);
    }
  }

  removeBookmark(page: number): void {
    this.bookmarks = this.bookmarks.filter(b => b !== page);
  }

  downloadPage(page: number): void {
    const imageUrl = this.br.getPageURI(page);
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `Page-${page}.jpg`;
    link.click();
  }

  adjustFontSize(size: number): void {
    this.fontSize = size;
  }

  adjustBackgroundColor(color: string): void {
    this.bgColor = color;
  }

  sharePage(page: number): void {
    const pageUrl = window.location.origin + `/reader?page=${page}`;
    navigator.clipboard.writeText(pageUrl);
    alert('Page link copied to clipboard!');
  }

  toggleSettingsMenu(): void {
    this.settingsMenuVisible = !this.settingsMenuVisible;
  }

  showControls() {
    this.isHovered = true;
  }
  showBookMarksView() {
    this.showBookMarksContainer = true;
    this.isHovered = false;
  }
  closeToggle() {
    this.isHovered = false;
  }
  showNotesView() {
    this.showNotesContainer = true;
    this.isHovered = false;
  }
  
  closeNotes() {
    this.showNotesContainer = false;
  }

  getCurrentPage () {
    if (this.br?.currentIndex() >= 0) {
      const currentPage = this.br.currentIndex() + 1;
      if (this.br.mode === 2) {
        if (currentPage == 1 || currentPage == this.images.length) {
          return  [currentPage]
        }
        return [currentPage, currentPage+1]
      }
    }
    return [1];
  }
  goToPageNo(pageNo: any) {
    this.br.jumpToIndex(pageNo-1)
  }
  closeBookmarks() {
    this.showBookMarksContainer = false;
  }
}
