import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { AuthActions, CartActions } from '../../actions';
import { AdoptionService } from 'shared/src/services/website/adoption/adoption.service';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { Cart } from 'shared/src/interfaces/website/adoption.interface';
import { Router } from '@angular/router';


@Injectable()
export class CartEffects {
  isUserLoggedIn = this.authService.isTokenExpired('webToken')
  constructor(
    private actions$: Actions,
    private service: AdoptionService,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  LoadCartList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.LoadCartList),
      exhaustMap((action) => {
        const isUserLoggedIn = !this.authService.isTokenExpired('webToken');

        if (isUserLoggedIn) {
          // Handle logged-in user: Fetch cart from database
          return this.service.getCartList(action.currencyCode).pipe(
            map((response) =>
              CartActions.LoadCartListSuccess({
                cartList: response?.cartList,
                totalAmount: response?.totalAmount,
              })
            ),
            catchError((error) =>
              of(
                CartActions.LoadCartListError({
                  message: error?.error?.error?.message,
                })
              )
            )
          );
        } else {
          
          // Handle non-logged-in user: Fetch cart for acurate pricing/currency
          const cartList = JSON.parse(localStorage.getItem('cartList') || '[]');
          const DocIDs = cartList.map((cart: any) => cart.DocID);
          
          return this.service.guestUserCart(DocIDs, action.currencyCode).pipe(
            map((response) =>
              CartActions.LoadCartListSuccess({
                cartList: response?.cartList,
                totalAmount: response?.totalAmount,
              })
            ),
            catchError((error) =>
              of(
                CartActions.LoadCartListError({
                  message: error?.error?.error?.message,
                })
              )
            )
          );
        }
      })
    )
  );

  calculateTotalAmount(cartList: Cart[]) {
    const totalAmount = cartList.reduce((total, document) => {
      return Number(total) + Number(document.AdoptionPrice);
    }, 0);

    return Math.round(totalAmount * 100) / 100;
  }

  AddToCartList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.AddToCartList),
      exhaustMap((action) => {
        const isUserLoggedIn = !this.authService.isTokenExpired('webToken');
        const document = action.document;

        if (isUserLoggedIn) {
          // Logged-in user: Add to cart using the service
          return this.service.addToCart(document.DocID, action.currencyCode).pipe(
            map((response) =>
              CartActions.AddToCartListSuccess({
                cart: response?.addedCart,
                checkout: action.checkout
              })
            ),
            catchError((error) =>
              of(
                CartActions.AddToCartListError({
                  message: error?.error?.error?.message,
                })
              )
            )
          );
        } else {
          // Non-logged-in user: Add to local storage
          const localCart = JSON.parse(localStorage.getItem('cartList') || '[]');

          localCart.push(document);
          localStorage.setItem('cartList', JSON.stringify(localCart));

          return of(
            CartActions.AddToCartListSuccess({
              cart: document,
              checkout: action.checkout
            })
          );
        }
      })
    )
  );

  RemoveFromCartList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.RemoveFromCartList),
      exhaustMap((action) => {
        const isUserLoggedIn = !this.authService.isTokenExpired('webToken');

        if (isUserLoggedIn) {
          // Logged-in user: Remove from cart using the service
          return this.service.removeFromCart(action.document.DocID).pipe(
            map((response) =>
              CartActions.RemoveFromCartListSuccess({
                CartID: response?.CartID,
                AdoptionPrice: Number(action.document.AdoptionPrice)
              })
            ),
            catchError((error) =>
              of(
                CartActions.RemoveFromCartListError({
                  message: error?.error?.error?.message,
                })
              )
            )
          );
        } else {
          // Non-logged-in user: Remove from local storage
          let localCart = JSON.parse(localStorage.getItem('cartList') || '[]');
          localCart = localCart.filter((item: any) => item.DocID !== action.document.DocID);
          localStorage.setItem('cartList', JSON.stringify(localCart));

          return of(
            CartActions.RemoveFromCartListSuccess({
              CartID: action.document.DocID, // Use DocID as CartID for consistency
              AdoptionPrice: Number(action.document.AdoptionPrice)
            })
          );
        }
      })
    )
  );

  CheckCartOnLogin$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AuthActions.webLoginSuccess),
      map(({ webToken, user }) => {
        const guestCart = JSON.parse(localStorage.getItem('cartList') || '[]');
        if (guestCart.length > 0) {
          const DocIDs = guestCart.map((cart: Cart) => cart.DocID);
          return CartActions.SyncGuestCartOnLogin({ DocIDs });
        }
        return CartActions.noGuestCartToSync();
      })
    )
  );

  SyncGuestCartOnLogin$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CartActions.SyncGuestCartOnLogin),
      exhaustMap((action) => {
        
        return this.service.syncCart(action.DocIDs).pipe(
          map((response) => {
            console.log('sync response', response);
            return CartActions.SyncGuestCartOnLoginSuccess()
          }),
          catchError((error) =>
            of(
              CartActions.SyncGuestCartOnLoginError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
        
      })
    )
  );

  EmptyGuestCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.EmptyGuestCart),
      exhaustMap(() => {
        const isUserLoggedIn = !this.authService.isTokenExpired('webToken');

        if (!isUserLoggedIn) {
          // Non-logged-in user: Clear cart in localStorage
          localStorage.removeItem('cartList');

          return of(
            CartActions.EmptyGuestCartSuccess({cartList:[], totalAmount: 0}) // Dispatch success action
          );
        } else {
          return of(
            CartActions.EmptyGuestCartError({
              message: 'Action not allowed for logged-in users.',
            })
          );
        }
      })
    )
  );

  OnAddToCartListSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CartActions.AddToCartListSuccess),
      tap((action) => {
        if(action.checkout) {
          this.router.navigate(['/adopt-a-book/checkout'])
        }
      })
    ),
    { dispatch: false } // This effect doesn't dispatch a new action
  )
  
  SyncGuestCartOnLoginSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CartActions.SyncGuestCartOnLoginSuccess),
      tap((action) => {
        // remove cart from localstorage 
        localStorage.removeItem('cartList');
      })
    ),
    { dispatch: false } // This effect doesn't dispatch a new action
  )

  noGuestCartToSync$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.noGuestCartToSync),
        tap(() => {
          console.log('No guest cart to sync.');
        })
      ),
    { dispatch: false } // No further action to dispatch
  );
}
