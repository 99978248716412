// reducers/notes.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { DocumentNotesActions } from '../../actions';
import { Note } from 'shared/src/interfaces/metadata.interface';

export interface State {
  notes: Note[];
  isLoading: boolean;
  errorMessage: string | null;
}

export const initialState: State = {
  notes: [],
  isLoading: false,
  errorMessage: null,
};

export const reducer = createReducer(
  initialState,
  on(DocumentNotesActions.loadNotesByDocId, (state) => ({ ...state, isLoading: true, errorMessage: null })),
  on(DocumentNotesActions.loadNotesByDocIdSuccess, (state, { notes }) => ({
    ...state,
    notes,
    isLoading: false,
    errorMessage: null,
  })),
  on(DocumentNotesActions.loadNotesByDocIdError, (state, { message }) => ({
    ...state,
    isLoading: false,
    errorMessage: message,
  })),
  on(DocumentNotesActions.createNoteSuccess, (state, { note }) => ({
    ...state,
    notes: [...state.notes, note],
  })),
  // Update Note By ID
  on(DocumentNotesActions.updateNoteById, (state) => ({
    ...state,
    isLoading: true,
    errorMessage: null,
  })),
  on(DocumentNotesActions.updateNoteByIdSuccess, (state, { updatedNote }) => ({
    ...state,
    isLoading: false,
    notes: state.notes.map((note) =>
      note.ID === updatedNote.ID ? { ...note, ...updatedNote } : note
    ),
  })),
  on(DocumentNotesActions.updateNoteByIdFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    errorMessage: error,
  })),
  on(DocumentNotesActions.deleteNoteSuccess, (state, { noteId }) => ({
    ...state,
    notes: state.notes.filter((note) => note.ID !== noteId),
  }))
);
