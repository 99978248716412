import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { RouterLink } from '@angular/router';
import { AdoptionService } from 'shared/src/services/website/adoption/adoption.service';
import { GenericListingPayloadWithCategoryFilter, SortOrder } from 'shared/src/interfaces/common.interface';
import { AdoptionBooks } from 'shared/src/interfaces/website/adoption.interface';
import { Subject, takeUntil } from 'rxjs';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';

@Component({
  selector: 'panjab-digi-lib-donate',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './donate.component.html',
  styleUrl: './donate.component.scss',
})
export class DonateComponent implements OnInit, OnDestroy {
  pageNo = 1;
  pageSize = 3;
  sortOrder: SortOrder = {orderBy: 'Title', order: 'ASC'};

  isLoading: boolean = true;
  currencyCode!: string;
  documentList: AdoptionBooks[] = [];
  private unsubscribe$: Subject<void> = new Subject();

  constructor( private adoptionService: AdoptionService, private localStorageService: LocalStorageService,) {}

  ngOnInit() {
    const locationInfo = this.localStorageService.get('locationInfo');
    if (locationInfo) {
      this.currencyCode = locationInfo.currencyCode;
      this.getBooksForAdoption();
    } else {
      this.getUserLocationInfo();
    }
  }

  getUserLocationInfo() {
    this.adoptionService.getUserCurrency().pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        if(response.status == "success") {
          this.currencyCode = response.currency;
          
          const locationInfo = {
            'currencyCode': this.currencyCode,
            'countryCode': response.countryCode,
            'countryName': response.country
          }
          this.localStorageService.set('locationInfo', locationInfo);

          this.getBooksForAdoption();
        }
      }, error: (error) => {
        this.isLoading = false;
        console.log('error', error);
      }
    })
  }
  
  getBooksForAdoption(): void {
    let payload: GenericListingPayloadWithCategoryFilter = { 
      ...this.sortOrder, 
      pageNumber: this.pageNo, 
      pageSize: this.pageSize
    };
    this.isLoading = true;
    this.adoptionService.getBooksForAdoption(payload, this.currencyCode).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.documentList = response.documentList;
        this.isLoading = false;
      }, error: (error) => {
        console.log('error', error);
        this.documentList = [];
        this.isLoading = false;
      }
    })
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
