/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import {
  GenericListingPayload,
  KeywordDetailPayload,
  KeywordDetailResponse,
  updateStatusPayload,
  UserDetailPayload,
  UserDetailResponse,
  UserListResponse,
} from 'shared/src/interfaces/user-services.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.webUserMgmt.userService;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  ////////// load user list /////////
  loadUserList(payload: GenericListingPayload) {
    let url = `${this.rootURL}${this.endPointUrl.userList}`
      .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{orderBy}', (payload?.orderBy ?? 'TotalSearchCount').toString())
      .replace('{order}', (payload?.order ?? 'ASC').toString());
    if (payload?.query) {
      url += `&query=${payload?.query}`;
    }
    return this.http
      .get<UserListResponse>(url)
      .pipe(map((e: UserListResponse) => e?.body));
  }

  ////////// update user status /////////

  updateStatus(payload: updateStatusPayload) {
    let url = `${this.rootURL}${this.endPointUrl.updateStatus}`;
    return this.http.patch(url, { ...payload });
  }

  //////////  user detail /////////
  userDetail(payload: UserDetailPayload) {
    let url = `${this.rootURL}${this.endPointUrl.userDetail}`
      .replace('{id}', payload.userId.toString())
      .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{orderBy}', (payload?.orderBy ?? 'TotalSearches').toString())
      .replace('{order}', (payload?.order ?? 'ASC').toString());
    if (payload?.query) {
      url += `&query=${payload?.query}`;
    }
    return this.http
      .get<UserDetailResponse>(url)
      .pipe(map((e: UserDetailResponse) => e?.body));
  }

  //////////  keyword detail /////////
  keywordDetail(payload: KeywordDetailPayload) {
    let url = `${this.rootURL}${this.endPointUrl.keywordDetail}`
      .replace('{id}', payload.keywordId.toString())
      .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{orderBy}', (payload?.orderBy ?? 'TotalSearchCount').toString())
      .replace('{order}', (payload?.order ?? 'ASC').toString());
    if (payload?.query) {
      url += `&query=${payload?.query}`;
    }
    return this.http
      .get<KeywordDetailResponse>(url)
      .pipe(map((e: KeywordDetailResponse) => e?.body));
  }
}
