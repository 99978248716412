/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from "../../static/staticPageHead/staticPageHead.component";
import { Subscription } from 'rxjs';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { UploadDocumentService } from 'shared/src/services/website/upload-document/upload-document.service';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedCommonService } from '@panjab-digi-lib/shared';
import { RouterModule } from '@angular/router';
interface UploadedDocumentInterface {
  DocID: number;
  Title: string;
  Status: number;
  Description: string;
  DateAdded: Date;
  CategoryName: string,
  DocumentStatus :number;
}
@Component({
  selector: 'panjab-digi-lib-upload-history',
  standalone: true,
  imports: [
    CommonModule,
    StaticPageHeadComponent,
    TranslateModule,
    NgbPaginationModule,
    RouterModule
  ],
  templateUrl: './upload-history.component.html',
  styleUrl: './upload-history.component.scss',
})
export class UploadHistoryComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  subscription = new Subscription();
  pageSize = APP_CONSTANTS.pageSize;
  pageNumber: number = 1;
  fromRecords: number = 0;
  toRecords: number = 0;
  totalRecords: number = 0;
  maxSize: number = APP_CONSTANTS.maxSize;
  uploadedDocumentList: UploadedDocumentInterface[] = [];
  isExpandedMap: { [key: number]: boolean } = {};
  constructor(
    private service: UploadDocumentService,
    private toastService: ToastService,
    private sharedService: SharedCommonService
  ) {}
  ngOnInit(): void {
    this.fetchUserUploadHistory();
  }

  /////////////////// payload which need to send in request ////////////////////
  getPayload(pageNumber: number) {
    return { pageNumber: pageNumber, pageSize: this.pageSize };
  }
  ///////////////// fetch upload history ///////////////////
  fetchUserUploadHistory(): void {
    this.isLoading = true;
    const payload = this.getPayload(this.pageNumber);
    this.subscription.add(
      this.service.uploadHistory(payload).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.uploadedDocumentList = response?.documentList;
          this.totalRecords = response.totalRecords;
          this.calculatePagination(this.uploadedDocumentList);
        },
        error: (error) => {
          this.isLoading = false;
          this.toastService.showError(
            error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
          );
        },
      })
    );
  }
  //////////////////////////////create pagination //////////////////////////////////
  calculatePagination(data: Array<object>) {
    this.fromRecords =
      this.pageNumber === 1 && this.uploadedDocumentList?.length > 0
        ? 1
        : this.pageNumber === 1 && this.uploadedDocumentList?.length === 0
        ? 0
        : (this.pageNumber - 1) * this.pageSize + 1;
    this.toRecords = (this.pageNumber - 1) * this.pageSize + data?.length;
  }

  toggleDescription(docId: number) {
    this.isExpandedMap[docId] = !this.isExpandedMap[docId];
  }
  //////////////// show limited text upto defined characters ////////
  getTruncatedText(description: string) {
    return this.sharedService.getTruncatedText(description);
  }
  ///////////// should show read more or not /////////////
  shouldShowReadMore(description: string): boolean {
    return this.sharedService.shouldShowReadMore(description);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
