/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { catchError, map, Observable, retry, switchMap, throwError } from 'rxjs';
import { GenericListingPayload, GenericListingPayloadWithCategoryFilter } from 'shared/src/interfaces/common.interface';
import { AdoptedDocument, AdopterDetails, Cart, CreateOrderPayload, CurrencyOfCountry, OrderStatusResponse, SavedOrderInfoResponse, SaveOrderPayload, UserIPInfo, VerifyPaymentPayload } from 'shared/src/interfaces/website/adoption.interface';

@Injectable({
  providedIn: 'root'
})
export class AdoptionService {

  rootURL = environment.rootApiUrl;
  endPointUrl = WEB_API_ENDPOINTS.adoption;
  constructor(private http: HttpClient) { }

  getBooksForAdoption(payload: GenericListingPayloadWithCategoryFilter, currencyCode: string) {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.getBooks}`, {params: {...payload, currencyCode}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  getUserIPAddress(): Observable<{ip: string}> {  

    return this.http.get(environment.getIPAddressEndpoint)
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  getUserLocationData(ipAddress: string): Observable<UserIPInfo> {
    // const geoAPIEndpoint = environment.geoAPIEndpoint.replace('{IPAddress}', ipAddress);
    return this.http.get(`${this.rootURL}${this.endPointUrl.getIPBasedLocation}?ipAddress=${ipAddress}`)
    .pipe(
      map((e: any) => ({ipAddress, ...e.body})),
      catchError(this.handleError)
    );
  }

  getCurrencyOfCountry(countryName: string): Observable<CurrencyOfCountry> {
    const countryinfoapi = environment.countryinfoapi.replace('{countryName}', countryName);
    return this.http.get(countryinfoapi).pipe(
      retry(1),  // Retry once before failing
      map((e: any) => e),
      catchError(this.handleError) // Handle error after retrying
    );
  }

  getUserCurrency(): Observable<UserIPInfo> {
    return this.getUserIPAddress().pipe(
      switchMap((ipAddress: {ip: string}) => this.getUserLocationData(ipAddress.ip)),
      catchError(this.handleError)
    )
  }

  getCartList(currencyCode: string) {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.cart}?currencyCode=${currencyCode}`)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  guestUserCart(DocIDs: number[], currency: string) {
    return this.http.post(`${this.rootURL}${this.endPointUrl.guestUserCart}`, {DocIDs, currency})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  validateCart(DocIDs: number[]): Observable<{alreadyAdoptedIDs: number[]}> {
    return this.http.post(`${this.rootURL}${this.endPointUrl.validateCart}`, {ids: DocIDs})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  syncCart(DocIDs: number[]) {
    return this.http.post(`${this.rootURL}${this.endPointUrl.syncCart}`, {DocIDs})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  addToCart(DocID: number, currencyCode: string): Observable<{addedCart: Cart}> {
    return this.http.post(`${this.rootURL}${this.endPointUrl.cart}`, {DocID, currencyCode})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  removeFromCart(DocID: number) {
    return this.http.delete(`${this.rootURL}${this.endPointUrl.cart}/${DocID}`)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  createOrder(payload: CreateOrderPayload) {
    return this.http.post(`${this.rootURL}${this.endPointUrl.createOrder}`, payload)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  cancelOrder(OrderID: string) {
    return this.http.post(`${this.rootURL}${this.endPointUrl.cancelOrder}`, {OrderID})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  verifyPayment(payload: VerifyPaymentPayload) {
    return this.http.post(`${this.rootURL}${this.endPointUrl.verifyPayment}`, payload)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  getOrderStatus(OrderID: string): Observable<SavedOrderInfoResponse> {
    return this.http.get(`${this.rootURL}${this.endPointUrl.getOrderStatus}/${OrderID}`)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  async createPaypalOrder(payload: CreateOrderPayload, token: string) {
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }) // Add token if provided
    };
  
    const response = await fetch(`${this.rootURL}${this.endPointUrl.createOrder}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload)
    });
  
    return await response.json();
  }  

  async capturePaypalOrder(orderID: string) {
    
    const response = await fetch(`${this.rootURL}${this.endPointUrl.verifyPaypalPayment}`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({orderID})
    });

    return await response.json();
  }


  saveOrderInfo(payload: SaveOrderPayload) {
    return this.http.post(`${this.rootURL}${this.endPointUrl.saveOrderInfo}`, payload)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  
  getAdoptionDetails(adopterID: string): Observable<{adopter: AdopterDetails, documents: AdoptedDocument[]}> {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.getAdoptionDetails}/${adopterID}`)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }


    return throwError(() => transformedError);
  }
}
