import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DocumentBookmarksActions, WebDocumentBookmarksSelector } from '@panjab-digi-lib/shared';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-bookmarks',
  standalone: true,
  imports: [CommonModule, NgbPopover, FormsModule, TranslateModule],
  templateUrl: './bookmarks.component.html',
  styleUrl: './bookmarks.component.scss',
})
export class BookmarksComponent implements OnInit {
  @Input() showBookMarksContainer: boolean = false;
  @Input() docId!: number;
  @Input() pages!: number[];
  @Output() oncloseBookmarksContainer = new EventEmitter();
  @Output() goToPage = new EventEmitter();

  bookmarks$!: Observable<any[]>;
  isLoading$!: Observable<boolean>;
  errorMessage$!: Observable<any>;
  hasBookmarks$!: Observable<boolean>;

  enableAddBookmark = false;

  currentPageNumber: number = 0;
  showLoader = false;

  constructor(private store: Store, private actions$: Actions) {
    this.bookmarks$ = this.store.select(WebDocumentBookmarksSelector.selectBookmarks);
    this.isLoading$ = this.store.select(WebDocumentBookmarksSelector.selectBookmarksLoading);
    this.errorMessage$ = this.store.select(WebDocumentBookmarksSelector.selectBookmarksError);
    this.hasBookmarks$ = this.bookmarks$.pipe(map((bookmarks) => bookmarks && bookmarks.length > 0));
  }

  ngOnInit(): void {
    if (this.docId) {
      this.loadBookmarks(this.docId);
    }

    this.actions$
      .pipe(ofType(DocumentBookmarksActions.addBookmarkSuccess))
      .subscribe(() => {
        this.closeAddBookmark();
        this.loadBookmarks(this.docId);
      });

    this.actions$
      .pipe(ofType(DocumentBookmarksActions.deleteBookmarkSuccess))
      .subscribe(() => {
        this.loadBookmarks(this.docId);
      });

    this.actions$
      .pipe(ofType(DocumentBookmarksActions.loadBookmarksSuccess))
      .subscribe(() => {
        this.showLoader = false;
      });
    this.actions$
      .pipe(ofType(DocumentBookmarksActions.addBookmarkFailure))
      .subscribe(() => {
        this.showLoader = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pages'] && 
      changes['pages'].currentValue !== changes['pages'].previousValue && 
      changes['pages'].currentValue.indexOf(this.currentPageNumber) == -1
    ) {
      this.currentPageNumber = changes['pages'].currentValue[0];
    }
  }

  updatePageNo (pageNo: number) {
    this.currentPageNumber = pageNo;
  }

  loadBookmarks(docId: number): void {
    this.store.dispatch(DocumentBookmarksActions.loadBookmarks({ docId }));
  }

  showAddBookmark(): void {
    this.enableAddBookmark = true;
  }

  addBookmark(): void {
    const payload = { DocID: this.docId, PageNumber: this.currentPageNumber };
    this.store.dispatch(DocumentBookmarksActions.addBookmark({ bookmark: payload }));
    this.showLoader = true;
  }

  deleteBookmark(bookmarkId: number): void {
    this.store.dispatch(DocumentBookmarksActions.deleteBookmark({ bookmarkId }));
  }

  closeAddBookmark(): void {
    this.enableAddBookmark = false;
  }


  closeBookmarks(): void {
    this.oncloseBookmarksContainer.emit();
  }
  goToPageNo (pageNo: number) {
    this.goToPage.emit(pageNo)
  }
}

