import { AfterViewInit, Component, ElementRef, HostListener, NgZone, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from 'shared/src/services/website/common.service';
import { AppState, CartActions, CartSelector, CountryActions, CountrySelector, environment } from '@panjab-digi-lib/shared';
import { AdoptionService } from 'shared/src/services/website/adoption/adoption.service';
import { lastValueFrom, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Cart, CreateOrderPayload, CreateOrderResponse, SavedOrderInfoResponse, SaveOrderPayload } from 'shared/src/interfaces/website/adoption.interface';
import { RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Country } from 'shared/src/interfaces/master-form.interface';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { CanComponentDeactivate } from 'apps/panjab-digi-lib/src/common/guards/canDeactivate.guard';

declare var Razorpay: any;
declare var paypal: any;

@Component({
  selector: 'panjab-digi-lib-adopt-book-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './adopt-book-form.component.html',
  styleUrl: './adopt-book-form.component.scss',
})
export class AdoptBookFormComponent implements OnInit, AfterViewInit, CanComponentDeactivate {
  mobileFullCartView = false;
  isMobile = false;
  toggleCartView = () => (this.mobileFullCartView = !this.mobileFullCartView);

  OrderStatuses = APP_CONSTANTS.OrderStatus;

  cartList: Cart[] = [];
  adoptBookForm!: FormGroup;
  imageBaseURL = environment.azureCloudStrorage.publicUrl;
  individualAdopter = APP_CONSTANTS.AdopterType.INDIVIDUAL;
  isUserLoggedIn: boolean = !this.authService.isTokenExpired('webToken');

  private unsubscribe$: Subject<void> = new Subject();

  private scriptElements: HTMLScriptElement[] = [];
  isCreatingOrder: boolean = false;
  totalAmount!: number;
  countries!: Country[];
  loadingCart: boolean = true;
  checkoutCompleted: boolean = false;
  currencyCode!: string;
  countryCode!: string;
  isSavingOrderInfo: boolean = false;
  savedOrderInfo!: SavedOrderInfoResponse;
  internalOrderID!: string;

  constructor(
    private commonService: CommonService,
    private adoptionService: AdoptionService,
    private localStorageService: LocalStorageService,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private elRef: ElementRef,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getCountriesList();
    this.initiateForm();
    this.checkIfMobile();

    const locationInfo = this.localStorageService.get('locationInfo');
    if (locationInfo) {
      this.currencyCode = locationInfo.currencyCode;
      this.countryCode = locationInfo.countryCode;
      this.getCartList();
      if (this.currencyCode != 'INR') {
        this.loadPaypalScript();
      }
    } else {
      this.getUserLocationInfo();
    }
  }

  //////////////// get form control /////////////
  get f() {
    return this.adoptBookForm.controls;
  }

  getUserLocationInfo() {
    this.adoptionService.getUserCurrency().pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (response.status == "success") {
            this.currencyCode = response.currency;
            this.countryCode = response.countryCode;
            delete response.status;
            this.getCartList();
            if (this.currencyCode != 'INR') {
              this.loadPaypalScript();
            }
            const locationInfo = {
              'currencyCode': this.currencyCode,
              'countryCode': response.countryCode,
              'countryName': response.country
            }
            this.localStorageService.set('locationInfo', locationInfo);
          }
        }, error: (error) => {
          this.loadingCart = false;
          console.log('error', error);
        }
      })
  }

  initiatePaypal() {
    paypal.Buttons({
      style: {
        layout: 'horizontal',
        color: 'blue',
        shape: 'rect',
        label: 'paypal',
        height: 35
      },
      createOrder: async () => {
        if (this.adoptBookForm.invalid) {
          const firstInvalidControl = this.elRef.nativeElement.querySelector('form .ng-invalid');
          if (firstInvalidControl) {
            firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            firstInvalidControl.focus();
          }
          this.adoptBookForm.markAllAsTouched();
          this.toggleCartView();
          return;
        }

        if(this.cartList.some(cart => cart.AlreadyAdopted)) {
          return;
        }
        const ids = this.cartList.map(cart => cart.DocID);

        try {
          // Validate the cart items
          const response = await lastValueFrom(this.adoptionService.validateCart(ids).pipe(takeUntil(this.unsubscribe$)));

          const alreadyAdoptedIDs = response.alreadyAdoptedIDs;

          if (alreadyAdoptedIDs.length === 0) {
            const controls = this.adoptBookForm.controls;
            // Call create order API
            const payload: CreateOrderPayload = { 
              ids, currency: this.currencyCode, receipt: '', provider: 'paypal',
              name: controls['name'].value,
              state: controls['state'].value,
              countryId: controls['country'].value,
              email: controls['email'].value,
              remarks: controls['type'].value == this.individualAdopter ? controls['remarks'].value : '',
              website: controls['type'].value == this.individualAdopter ? '' : controls['website'].value,
              aboutOrg: controls['type'].value == this.individualAdopter ? '' : controls['aboutOrg'].value,
              adopterType: controls['type'].value
            };
            const token = this.localStorageService.get('webToken') || '';
            const createOrderResponse = await this.adoptionService.createPaypalOrder(payload, token);
            if (createOrderResponse?.body?.order) {
              this.internalOrderID = createOrderResponse?.body.order.customOrderID;
              return createOrderResponse.body.order.id; // Return order ID synchronously
            } else {
              console.error('Create order response is invalid');
            }
          } else {
            // Remove already adopted items from cart
            this.removeAlreadyAdoptedDocsFromCart(alreadyAdoptedIDs);
          }
        } catch (error) {
          console.error('Error during cart validation or order creation', error);
        }
      },
      onApprove: async (data: any) => {
        
        const details = await this.adoptionService.capturePaypalOrder(data.orderID);
        if (details.body.status == "COMPLETED") {
          this.pollOrderStatus();
        } else {
          console.log('Something went wrong, details', details);
        }
      },
      onCancel: () => {
        this.cancelOrder();
      },
    }).render('#paypal-button-container');
  }

  cancelOrder() {
    if(this.internalOrderID) {
      this.adoptionService.cancelOrder(this.internalOrderID).pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          // order cancelled
        }
      })
    }
  }

  pollOrderStatus() {
    this.isSavingOrderInfo = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const interval = setInterval(() => {
      this.adoptionService.getOrderStatus(this.internalOrderID).subscribe(response => {
        
        if(response.orderStatus !== 1) { // other than pending
          clearInterval(interval);
          this.ngZone.run(() => {
            this.savedOrderInfo = response;
            if (!this.isUserLoggedIn) { // empty cart
              this.store.dispatch(CartActions.EmptyGuestCart());
            }
            this.isSavingOrderInfo = false;
            this.checkoutCompleted = true;
          })
        }
      });
    }, 5000); // Poll every 5 seconds
  }

  initiateForm() {
    const activeUser = this.localStorageService.get('webUser');
    this.adoptBookForm = this.fb.group({
      name: [activeUser?.fullname || '', Validators.required],
      email: [activeUser?.email || '', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      state: [activeUser?.state || '', Validators.required],
      country: [activeUser?.country || '', Validators.required],
      remarks: ['', [Validators.required, Validators.maxLength(300)]],
      type: [1, Validators.required],
      website: [''],
      aboutOrg: [''],
    });

    this.updateFormOnTypeChange();
  }

  updateFormOnTypeChange() {
    this.adoptBookForm.controls['type'].valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((type) => {

        const controls = this.adoptBookForm.controls;

        if (type == this.individualAdopter) {
          // controls['email'].setValidators([Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]);
          controls['remarks'].setValidators([Validators.required]);
          controls['website'].clearValidators();
          controls['aboutOrg'].clearValidators();
        } else { // organisation
          controls['website'].setValidators([Validators.required, Validators.pattern(
            '^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$'
          )
          ]);
          controls['aboutOrg'].setValidators([Validators.required]);
          controls['remarks'].clearValidators();
        }

        ['remarks', 'website', 'aboutOrg'].forEach(field =>
          controls[field].updateValueAndValidity()
        );
      })
  }

  getCartList() {
    this.store.dispatch(CartActions.LoadCartList({ currencyCode: this.currencyCode }));

    this.store.select(CartSelector.onCartChange).pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ cartList, isUpdating }) => {
        if (isUpdating) {
          return;
        }
        this.cartList = cartList.filter((cart): cart is Cart => cart !== undefined);
        if(this.cartList.length == 0) {
          this.adoptBookForm.disable();
        } else {
          this.adoptBookForm.enable();
        }
        this.loadingCart = false;
      });

    this.store.select(CartSelector.totalAmount).pipe(takeUntil(this.unsubscribe$))
      .subscribe((totalAmount) => {
        this.totalAmount = totalAmount;
      })
  }

  removeFromCart(cart: Cart) {
    this.store.dispatch(CartActions.RemoveFromCartList({ document: cart }));
  }

  getCountriesList() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({ payload }));
    this.store.select(CountrySelector.getCountries).pipe(takeUntil(this.unsubscribe$)).subscribe((countries) => {
      this.countries = countries;
    })
  }

  createOrder(currency: string, receipt: string) {

    if (this.adoptBookForm.invalid) {
      const firstInvalidControl = this.elRef.nativeElement.querySelector('form .ng-invalid');
      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        firstInvalidControl.focus();
      }
      this.adoptBookForm.markAllAsTouched();
      this.toggleCartView();
      return;
    }

    if(this.cartList.some(cart => cart.AlreadyAdopted)) {
      return;
    }

    this.isCreatingOrder = true;
    const ids = this.cartList.map(cart => cart.DocID);
    const controls = this.adoptBookForm.controls;
    const payload: CreateOrderPayload = { 
      ids, currency, receipt, provider: 'razorpay',
        name: controls['name'].value,
        state: controls['state'].value,
        countryId: controls['country'].value,
        email: controls['email'].value,
        remarks: controls['type'].value == this.individualAdopter ? controls['remarks'].value : '',
        website: controls['type'].value == this.individualAdopter ? '' : controls['website'].value,
        aboutOrg: controls['type'].value == this.individualAdopter ? '' : controls['aboutOrg'].value,
        adopterType: controls['type'].value
    };
    this.adoptionService.validateCart(ids)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((validateCartResponse) => {
          if (validateCartResponse.alreadyAdoptedIDs.length === 0) {
            return this.adoptionService.createOrder(payload);
          }

          // Return adoptedIDs of items already adopted
          return of(validateCartResponse.alreadyAdoptedIDs);
        })
      )
      .subscribe({
        next: (response) => {
          if (response.order) {
            // Proceed only if a valid response is returned
            const { order } = response as { order: CreateOrderResponse };
            this.isCreatingOrder = false;
            this.internalOrderID = order.customOrderID;
            this.initiatePayment(order.id, order.amount);
          } else {
            // Handle case where no order was created
            this.isCreatingOrder = false;
            this.removeAlreadyAdoptedDocsFromCart(response);
          }
        },
        error: (error) => {
          this.isCreatingOrder = false;
          console.error('Error during validation or order creation:', error);
        }
      });

  }

  initiatePayment(orderId: string, amount: number) {
    const options: any = {
      key: environment.razorPayConfig.razorPayKeyID, // Replace with your Razorpay Key ID
      amount: amount, // Razorpay accepts amount in paise
      currency: 'INR',
      name: 'Adoption',
      description: 'Adopt your selected documents!',
      order_id: orderId, // Generated on the backend
      prefill: {
        name: this.adoptBookForm.controls['name'],
        email: this.adoptBookForm.controls['email'],
        // contact: '1234567890',
      },
      notes: {},
      theme: {
        color: '#F37254',
      },
      handler: (response: any) => {
        // On successful payment
        this.pollOrderStatus();
      },
      modal: {
        ondismiss: () => {
          // alert('Payment process was interrupted.');
          this.cancelOrder();
        },
      },
    };

    const razorpay = new Razorpay(options);
    razorpay.open();
  }

  removeAlreadyAdoptedDocsFromCart(alreadyAdoptedIDs: number[]): void {
    this.cartList = this.cartList.map((cart) => {
      if (alreadyAdoptedIDs.includes(cart.DocID)) {
        return { ...cart, AlreadyAdopted: true };
      }
      return cart;
    });

    const firstInvalidControl = this.elRef.nativeElement.querySelector('.err__msg');
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }

    setTimeout(() => {
      this.cartList.forEach(cart => {
        if (cart.AlreadyAdopted) {
          this.removeFromCart(cart);
        }
      })
    }, 5000);
  }

  saveOrderInfo(paymentId: string, gateway: string, captureId: string = '') {
    this.isSavingOrderInfo = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const controls = this.adoptBookForm.controls;
    const orderInfoPayload: SaveOrderPayload = {
      // totalAmount: amount,
      name: controls['name'].value,
      state: controls['state'].value,
      countryId: controls['country'].value,
      email: controls['email'].value,
      remarks: controls['type'].value == this.individualAdopter ? controls['remarks'].value : '',
      website: controls['type'].value == this.individualAdopter ? '' : controls['website'].value,
      aboutOrg: controls['type'].value == this.individualAdopter ? '' : controls['aboutOrg'].value,
      adopterType: controls['type'].value,
      transactionId: paymentId,
      currency: this.currencyCode,
      gateway: gateway == 'razorpay' ? 1 : 2, // 1: razorpay, 2: paypal
      captureId,
      cartDocs: this.cartList.map(cart => cart.DocID)
    }
    this.adoptionService.saveOrderInfo(orderInfoPayload).pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.checkoutCompleted = true;
          console.log('response', response);
          this.savedOrderInfo = response;
          if (!this.isUserLoggedIn) { // empty cart
            this.store.dispatch(CartActions.EmptyGuestCart());
          }
          this.isSavingOrderInfo = false;
        }, error: (error) => {
          console.log('error', error);
          this.isSavingOrderInfo = false;
        }
      })
  }

  ngAfterViewInit() {
    this.commonService.loadScript([
      {
        src: environment.razorPayConfig.razorPayCheckoutJS,
        attributes: {
          'data-payment_button_id': environment.razorPayConfig.razorPayBtnID
        },
        targetElementId: 'razor-pay-checkout-wrapper'
      }
    ]).then((scripts) => {
      this.scriptElements = scripts;
    }).catch((error) => {
      console.error('Error loading scripts:', error);
    });
  }

  loadPaypalScript(): void {
    let queryParams = '';
    if (this.countryCode) {
      queryParams += `&buyer-country=${this.countryCode}`;
    }

    if (this.countryCode) {
      queryParams += `&currency=${this.currencyCode}`;
    }

    this.commonService.loadScript([
      {
        src: `${environment.paypalConfig.PAYPAL_JS}${queryParams}`,
        targetElementId: 'paypal-wrapper'
      }
    ]).then((scripts) => {
      this.scriptElements = [...scripts, ...this.scriptElements];
      this.initiatePaypal();
    }).catch((error) => {
      console.error('Error loading scripts:', error);
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkIfMobile();
  }

  private checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if (this.isSavingOrderInfo) {
      $event.returnValue = true;
    }
  }

  // Implement the canDeactivate method
  canDeactivate(): boolean {
    if (this.isSavingOrderInfo) {
      return confirm(this.translate.instant('pdl-shared.content.orderInProgress')+' '+this.translate.instant('pdl-shared.content.sureToLeave'));
    }
    return true;
  }

  ngOnDestroy() {
    this.commonService.removeScript(this.scriptElements);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
