<section class="upload-document-form">
    <div class="container reg-container">
        <div class="row d-flex">
            <!-- Column 1 - Static Content -->
            <div class="col-md-5 column-1">
                <div class="content fw-normal text-justify">
                    <!-- Hide breadcrumbs in mobile view -->
                    <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                        <panjab-digi-lib-breadcrumbs
                            [pageName]="'pdl-website.forms.labels.uploadDocument.heading' | translate"></panjab-digi-lib-breadcrumbs>
                    </div>
                    <h5 class="fw-bold">{{'pdl-website.forms.labels.uploadDocument.heading'
                        | translate }}</h5>
                    <div class="static-txt"
                        [innerHtml]="'pdl-website.forms.labels.uploadDocument.leftColText' | translate"></div>
                </div>
            </div>
            <!-- Column 2 - upload document Form -->
            <div class="col-md-7 column-2">
                <div class="right-section mb-5">
                    <div class="top-content">
                        <div class="header-title" #moveToTop tabindex="-1">
                            <!-- Show breadcrumbs in mobile view -->
                            <div
                                class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                                <panjab-digi-lib-breadcrumbs [route]="'/user/upload-history'"
                                    [pageName]="'pdl-website.userProfile.uploadedDocuments' | translate"></panjab-digi-lib-breadcrumbs>
                            </div>
                            <div class="title-txt mt-4">
                                <h5 class="fw-bolder">{{'pdl-website.forms.labels.uploadDocument.requestFormTitle'
                                    |
                                    translate }}
                                </h5>
                            </div>
                        </div>
                        <div class="header-body">
                            <p class="fw-light"
                                [innerHtml]="'pdl-website.forms.labels.uploadDocument.rightColText' | translate">
                            </p>
                        </div>
                    </div>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
                        <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                        <div class="form-section2">
                            <p class="fw-bold">{{'pdl-website.forms.labels.uploadDocument.informationText'
                                | translate
                                }}
                            </p>
                            <!-- catgeory ,upload types -->
                            <div class="row">
                                <div class="col-md-6 position-relative">
                                    <div class="d-flex">
                                        <label for="categoryID"
                                            class="form-label reqd">{{'pdl-website.forms.labels.uploadDocument.documentCategory'
                                            | translate }}</label>

                                    </div>
                                    <select class="form-select" formControlName="categoryID" id="categoryID"
                                        (change)="fetchUploadTypes($event)"
                                        [ngClass]="{'border-danger': form.controls['categoryID'].invalid && form.controls['categoryID'].touched, 'select_option': form.controls['categoryID'] === null || form.controls['categoryID'].invalid}">
                                        <option class="select_option" value="null" disabled>Select</option>
                                        @for(category of categoryList; track
                                        category.categoryID) {
                                        <option value="{{category.categoryID}}">{{category?.Name}}</option>
                                        }
                                    </select>
                                    <ng-container>
                                        @if(form.controls['categoryID'].touched
                                        &&
                                        form.get('categoryID')?.invalid &&
                                        form.get('categoryID')?.errors?.['required'])
                                        {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @if(form.controls['categoryID'].valid &&
                                        form.controls['categoryID'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </ng-container>
                                </div>
                                <div class="col-md-6 position-relative">
                                    <div class="d-flex">
                                        <label for="item"
                                            class="form-label reqd">{{'pdl-website.forms.labels.uploadDocument.documentType'
                                            | translate }}</label>
                                    </div>
                                    <ng-select [items]="documentAttachments" (change)="updateDocumentTypeSelection()"
                                        [multiple]="true" bindLabel="Label" [closeOnSelect]="false" bindValue="ConfigID"
                                        formControlName="documentAttachments" class="uploadTypes pb-0"
                                        placeholder="Select"
                                        [ngClass]="{'is-invalid': !form.get('documentAttachments')?.valid && form.controls['documentAttachments'].touched }">
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
                                            {{ item.Label }}
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                         @for(item of items.slice(0, 2);track item; let i = $index)  { 
                                         <div class="ng-value">
                                        <label for="checkbox-{{ item.ConfigID }}" class="ng-value-label">{{ item.Label }}</label>
                                         </div>
                                        }
                                        @if(items.length > 2) {
                                      <div class="ng-value">
                                      <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                                    </div>
                                         } 
                                    </ng-template>
                                    </ng-select>
                                    <ng-container>
                                        @if(form.controls['documentAttachments'].touched
                                        &&
                                        form.get('documentAttachments')?.invalid
                                        &&
                                        form.get('documentAttachments')?.errors?.['required'])
                                        {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @if(form.controls['documentAttachments'].valid
                                        &&
                                        form.controls['documentAttachments'].touched)
                                        {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </ng-container>
                                </div>
                            </div>
                            <!-- title -->
                            <div class="row">
                                <div class="col-md-12 position-relative">
                                    <div class="d-flex">
                                        <label for="title"
                                            class="form-label reqd">{{'pdl-website.forms.labels.uploadDocument.title'
                                            | translate }}</label>
                                        <span
                                            class="flex-grow-1 ms-1 select_option">({{'pdl-website.forms.labels.uploadDocument.characterAllowed200'
                                            | translate }})</span>
                                    </div>
                                    <input type="text" autocomplete="off" id="title" class="form-control"
                                        formControlName="title" placeholder="Title" [maxlength]="200"
                                        [ngClass]="{'border-danger': form.controls['title'].invalid && form.controls['title'].touched}"
                                        panjabDigiLibTrimmedText>
                                    <ng-container>
                                        @if(form.controls['title'].touched &&
                                        form.get('title')?.invalid &&
                                        form.get('title')?.errors?.['required'])
                                        {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @if(form.controls['title'].valid &&
                                        form.controls['title'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </ng-container>
                                </div>
                            </div>
                            <!-- description -->
                            <div class="row">
                                <div class="col-md-12 position-relative">
                                    <div class="d-flex">
                                        <label for="description"
                                            class="form-label reqd">{{'pdl-website.forms.labels.uploadDocument.description'
                                            |
                                            translate}} </label>
                                        <span
                                            class="flex-grow-1 ms-1 select_option">({{'pdl-website.forms.labels.uploadDocument.characterAllowed'
                                            |
                                            translate}})</span>
                                    </div>
                                    <textarea class="form-control" formControlName="description" rows="3" cols="50"
                                        placeholder="Description" [maxLength]="1000" id="message"
                                        [ngClass]="{'border-danger': form.controls['description'].invalid && form.controls['description'].touched}"
                                        panjabDigiLibTrimmedText> </textarea>
                                    <ng-container>
                                        @if(form.controls['description'].touched
                                        &&
                                        form.get('description')?.invalid &&
                                        form.get('description')?.errors?.['required'])
                                        {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @if(form.controls['description'].valid
                                        &&
                                        form.controls['description'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </ng-container>
                                </div>
                            </div>
                            @if(formConfig) {
                            <div class="row">
                                @for(key of getFormKeys();track key) {
                                <div class="field__section position-relative col-md-6 mb-3">
                                    <label [for]="key" class="form-label meta-label"
                                        [ngClass]="{'required': formConfig[key].IsRequired === 1}">{{
                                        key}}
                                    </label>
                                    @if(formConfig[key].Type == 'Dropdown')
                                    {
                                    @if(formConfig[key].DropdownType ===
                                    'Single') {
                                    <span class="flex-grow-1 select_option"
                                        [ngClass]="formConfig[key].IsRequired === 1 ? 'ms-3' : 'ms-1'">({{'pdl-website.forms.labels.uploadDocument.selectOne'
                                        | translate }})</span>
                                    } @else {
                                    <span class="flex-grow-1 select_option"
                                        [ngClass]="formConfig[key].IsRequired === 1 ? 'ms-3' : 'ms-1'">({{'pdl-website.forms.labels.uploadDocument.selectMultiple'
                                        | translate }})</span>
                                    }
                                    }
                                    <ng-container [ngSwitch]="formConfig[key].Type">

                                        <input type="text" [formControlName]="key"
                                            [attr.data]="formConfig[key].TextType" [id]="key"
                                            class="form-control form__field" [maxLength]="200" *ngSwitchCase="'Text'"
                                            placeholder="{{formConfig[key].TextType === 'Decimal' ? 0.0 : key}}"
                                            (input)="onInputChange($event, key)"
                                            [ngClass]="{'border-danger': form.controls[key].invalid && form.controls[key].touched}">

                                        <ng-container *ngSwitchCase="'Dropdown'" class="uploadTypes">
                                            <panjab-digi-lib-multi-select [formControlName]="key"
                                                [options]="formConfig[key].Data" [allowAdd]="formConfig[key].AllowAdd"
                                                [countryExists]="formConfig[key].CountryExists" [label]="key"
                                                [maxLimit]="formConfig[key].DropdownType === 'Single' ? 1 : 100"
                                                [searchCollection]="formConfig[key].SearchCollection"
                                                [searchField]="formConfig[key].SearchField" [id]="key"
                                                [countryList]="countryList"
                                                [classList]="'uploadTypes'"
                                                [addStyle]="form.controls[key].invalid && form.controls[key].touched ? {'border': '1px solid #DC3545'} : {}"
                                                [fetchService]="'uploadDocumentService'">
                                            </panjab-digi-lib-multi-select>
                                        </ng-container>
                                        @if(form.controls[key].touched &&
                                        form.get(key)?.invalid &&
                                        form.get(key)?.errors?.['required']) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @if(form.controls[key].valid &&
                                        form.controls[key].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </ng-container>
                                </div>
                                }

                            </div>

                            <div class="d-flex flex-wrap justify-content-evenly">
                                @for(uploadInfo of selectedDocumentAttachments;
                                track uploadInfo.Path) {
                                <panjab-digi-lib-upload-file [uploadInfo]="uploadInfo"
                                    [addStyle]="selectedFilesMap[uploadInfo.Path].length === 0 ? {'border': '1px solid #DC3545','background-image':'unset'} : {}"
                                    [uploadCompletedTrigger]="uploadCompletedTrigger"
                                    (uploadablefiles)="onFilesSelected($event, uploadInfo.Path)">
                                </panjab-digi-lib-upload-file>
                                }
                            </div>
                            }
                            <!-- recaptch -->
                            <div class="row mt-4">
                                <div class="col-12">
                                    <re-captcha #captchaRef (resolved)="resolveCaptcha($event)" formControlNme="cpt"
                                        siteKey="{{sitekey}}" [size]="isMobile ? 'compact' : 'normal'"></re-captcha>
                                </div>
                            </div>
                            <!-- /recaptch -->
                            <div class="row">
                                <div class="col-6 text-end">
                                    <button type="button" (click)="resetForm()" [disabled]="isSubmitted"
                                        class="btn btn-secondary btn__secondary">{{'pdl-website.forms.labels.resetBtn'
                                        |
                                        translate }}</button>
                                </div>
                                <div class="col-6 text-start">
                                    @if(isSubmitted && form.valid) {
                                    <panjab-digi-lib-spinner-button
                                        [name]="'pdl-shared.loaderState.pleaseWait' | translate"></panjab-digi-lib-spinner-button>
                                    } @else {
                                    <button type="submit" [disabled]="!form.valid || isButtonDisabled"
                                        class="btn btn-primary btn__primary">
                                        {{'pdl-website.forms.labels.uploadDocument.submit'
                                        | translate }}
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Template - required field error -->
    <ng-template #requiredError>
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
        </span>
    </ng-template>
    <!-- Template - valid field -->
    <ng-template #validFld>
        <span class="text-success">
            <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
        </span>
    </ng-template>
</section>
