import { provideHttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { TranslateLangModule } from '../common/modules/translate-lang/translate-lang.module';
import { effects, reducers } from '@panjab-digi-lib/shared';
import { appRoutes } from './app.routes';
import { HttpInterceptorService } from '../common/services/http-interceptor.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { provideGoogleTagManager } from 'angular-google-tag-manager';
import { environment } from '@panjab-digi-lib/shared';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);
  
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes,inMemoryScrollingFeature),
    provideStore(reducers,{}),
    provideEffects(effects),
    provideHttpClient(),
    importProvidersFrom(TranslateLangModule, RecaptchaModule, SocialLoginModule),
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    provideGoogleTagManager({
      id: environment.googleAnalytics.web,
      gtm_csp_none: 'CSP-NONCE'
    }),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        lang: 'en',
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.social.GOOGLE_CLIENT_ID
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.social.FB_APP_ID)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
};
