import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminNewsAndArticles from '../reducers/admin-news-and-articles.reducer';

export const selectState =
  createFeatureSelector<fromAdminNewsAndArticles.State>('adminNewsAndArticles');

export const loadNewsAndArticlesList = createSelector(selectState, (state) => ({
  recordsList: fromAdminNewsAndArticles.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber
}));

export const getNewsAndArticlesById = (id: number) => createSelector(
  selectState,
  (state) => ({body :state.entities[id]})
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);