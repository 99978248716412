/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MetadataService } from 'shared/src/services/staff/metadata.service';
import { debounceTime, Subject, Subscription, switchMap } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalPopupService } from 'shared/src/services/common/modal-popup.service';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { UploadDocumentService } from 'shared/src/services/website/upload-document/upload-document.service';

interface Option {
  id?: string;
  value: string;
  addedBy?: number;
  isSelected?: boolean;
}

@Component({
  selector: 'panjab-digi-lib-multi-select',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input() options: Option[] = [];
  @Input() placeholder: string = 'Select Items Here';
  @Input() searchBoxPlaceholder: string = 'search...';
  @Input() searchType: 'async' | 'local' = 'async';
  @Input() onSelect: (val: Option) => void = (val: Option) => this.select(val);
  @Input() onCreateNew: () => void = () => alert('Ok create new then!!');
  @Input() additionalClass: string = '';
  @Input() isCreateNew: boolean = true;
  @Input() maxLimit: number = 100;
  @Input() searchCollection: string = '';
  @Input() searchField: string = '';
  @Input() readonly: boolean = false;
  @Input() allowAdd: boolean = false;
  @Input() countryExists: boolean = true;
  @Input() countryList: any;
  @Input() label: string = 'item';
  @Input() fetchService: string = 'metaDataService';
  @Input() addStyle: { [key: string]: string } = {};
  showOptions = false;
  searchText: string = '';
  visibleOptions: Option[] = [];
  searchTerm = new Subject<string>();
  private onChange = (value: any) => {};
  private onTouched = () => {};
  @ViewChild('addModal') addModal!: TemplateRef<any>;
  addNewForm!: FormGroup;
  subscription = new Subscription();
  selectedValues: Set<any> = new Set(); // Stores either id or value
  serviceMapping: { [key: string]: any } = {};
  staticStaffId = APP_CONSTANTS.STATIC_STAFF_ID;
  constructor(
    private eRef: ElementRef,
    private metaDataService: MetadataService,
    private uploadDocumentService: UploadDocumentService,
    private translate: TranslateService,
    private modalPopupService: ModalPopupService,
    private fb: FormBuilder,
    readonly toastService: ToastService
  ) {
    this.serviceMapping = {
      metaDataService: metaDataService,
      uploadDocumentService: uploadDocumentService,
    };
  }

  ngOnInit(): void {
    this.updateVisibleOptions();
    if (this.searchType === 'async') {
      this.searchTerm
        .pipe(
          debounceTime(300),
          switchMap(async () => this.searchFromDb())
        )
        .subscribe();
    }
    if (this.allowAdd) {
      this.initAddNewForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countryList']) {
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (this.showOptions && !this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  writeValue(value: any): void {
    this.selectedValues.clear();
    value?.split(',').forEach((key: any) => {
      key = key.trim();
      if (key) {
        const option = this.options.find(
          (opt) => opt.id == key || opt.value == key
        );
        if (option) {
          this.selectedValues.add(option.id || option.value); // Add id if available, otherwise add value
        }
      }
    });

    this.updateVisibleOptions();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  markAsTouched(): void {
    this.onTouched(); // This tells Angular that the control has been touched
  }
  // Call this method when the user selects a value or interacts with the control
  onUserInteraction() {
    this.markAsTouched();
  }

  toggleOptions() {
    this.onUserInteraction();
    this.showOptions = !this.showOptions;
  }

  setSearch(e: any) {
    this.searchText = e.target.value;
    this.updateVisibleOptions();
    const enteredKeyword = (e.target as HTMLInputElement).value;
    this.searchTerm.next(enteredKeyword);
  }

  clearSearch() {
    this.searchText = '';
    this.updateVisibleOptions();
  }

  isMaxLimit() {
    return this.selectedValues.size >= this.maxLimit;
  }

  select(option: Option) {
    const key = this.getNormalizedKey(option);
    if (this.isMaxLimit() && !this.selectedValues.has(key)) {
      this.selectedValues.clear();
    }
    if (this.selectedValues.has(key)) {
      this.selectedValues.delete(key);
    } else {
      if (this.selectedValues.size < this.maxLimit) {
        this.selectedValues.add(key);
      }
    }
    this.onChange(this.getFormattedValue());
    this.updateVisibleOptions();
  }

  getFormattedValue(): string {
    return Array.from(this.selectedValues)
      .map((key) => {
        const option = this.options.find((opt) => opt.id === key);
        return option ? option.id || option.value : key; // Return id if present, otherwise value
      })
      .join(',');
  }

  updateVisibleOptions() {
    if (!this.searchText) {
      this.visibleOptions = this.options;
    } else {
      if (this.searchType === 'local') {
        this.visibleOptions = this.options.filter((option) =>
          option.value.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        // Handle async search if needed
      }
    }
  }

  getValueOptions(): Option[] {
    return this.options.filter((option) =>
      this.selectedValues.has(this.getNormalizedKey(option))
    );
  }

  getNormalizedKey(option: Option): string {
    return option.id || option.value;
  }

  onCheckboxChange(event: Event, option: Option) {
    const inputElement = event.target as HTMLInputElement;
    const key = this.getNormalizedKey(option);

    if (inputElement.checked) {
      if (this.maxLimit === 1) {
        this.selectedValues.clear();
      }
      this.selectedValues.add(key);
    } else {
      this.selectedValues.delete(key);
    }
    this.onChange(this.getFormattedValue());
    this.updateVisibleOptions();
  }

  getAdditionalItemsTooltip(): string {
    const options = this.getValueOptions();
    if (options.length > 2) {
      return options
        .slice(2)
        .map((option) => option.value)
        .join(', ');
    }
    return '';
  }

  //////////// find text from db ///////////////

  searchFromDb() {
    const service = this.serviceMapping[this.fetchService];
    service
      .search(this.searchCollection, this.searchField, this.searchText)
      .subscribe((res:any) => {
        for (let i = 0; i < res?.length; i++) {
          const index = this.options.findIndex(
            (item) => item?.id === res[i]?.id
          );
          if (index === -1) {
            this.options.push(res[i]);
          }
        }
        this.visibleOptions = res;
      });
  }

  /////////// initialize add new form  ////////////

  initAddNewForm() {
    this.addNewForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(300)]],
      countryId: [''],
    });
  }

  //////////////// open add new modal ///////////////
  openAddModal() {
    this.showOptions = false;
    this.addNewForm.patchValue({
      name: this.searchText,
    });
    if (this.countryExists) {
      this.addNewForm.patchValue({
        countryId: this.countryList[0]?.CountryID,
      });
    }
    this.modalPopupService.open(
      this.translate.instant('pdl-staff.metadata.createNew') + ' ' + this.label,
      '',
      this.addModal,
      this.translate.instant('pdl-staff.metadata.create'),
      this.translate.instant('pdl-staff.metadata.cancel'),
      this.handleOnSubmit.bind(this),
      this.handleOnCancel.bind(this),
      'md',
      this.addNewForm
    );
  }

  //////////////// submit modal data //////////////////
  handleOnSubmit() {
    const payload: any = {
      collection: this.searchCollection,
      searchField: this.searchField,
      value: this.addNewForm.controls['name'].value,
    };
    if (this.countryExists) {
      payload.countryId = this.addNewForm.controls['countryId'].value;
    }
    const service = this.serviceMapping[this.fetchService];
    service.addMetadataFieldData(payload).subscribe({
      next: (response: any) => {
        this.options.push(response?.body);
        const key = this.getNormalizedKey(response.body);
        this.selectedValues.add(key);
        this.onChange(this.getFormattedValue());
        this.updateVisibleOptions();
        this.addNewForm.reset();
        this.clearSearch();
      },
      error: (error: any) => {
        this.toastService.showError(
          error?.message || String(APP_CONSTANTS.defaultErrMsg)
        );
      },
    });
  }

  ///////////////// reset form on cancel or close ///////////////
  handleOnCancel() {
    this.addNewForm.reset();
    this.clearSearch();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
