/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CategoryDropdownList } from 'shared/src/interfaces/common.interface';
import { COMMON_API_ENDPOINTS } from './app.endpoints';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class SharedCommonService {
  changeComponentTitle$ = new BehaviorSubject(false);
  rootURL = '';
  sharedApiURL = '';
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {
    this.rootURL = environment.rootApiUrl;
    this.sharedApiURL = environment.rootApiUrl + environment.sharedApiPrefix;
  }

  getAllCountries() {
    return this.http
      .get(`${this.rootURL}admin/country`)
      .pipe(map((e: any) => e.body));
  }

  getCategories(): Observable<CategoryDropdownList[]> {
    return this.http
      .get(`${this.sharedApiURL}${COMMON_API_ENDPOINTS.shared.getCategoryList}`)
      .pipe(map((e: any) => e.body));
  }

  getContributors() {
    return this.http
      .get(
        `${this.sharedApiURL}${COMMON_API_ENDPOINTS.shared.getContributorList}`
      )
      .pipe(map((e: any) => e.body));
  }

  getCategoryProcess(id: string) {
    return this.http
      .get(
        `${this.sharedApiURL}${COMMON_API_ENDPOINTS.shared.categoryProcess}`.replace(
          '{id}',
          id
        )
      )
      .pipe(map((e: any) => e.body));
  }

  downloadFile(data: Blob, filename: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  generateAlphabets(): string[] {
    return Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  }

  encodeToBase64(str: any) {
    const utf8Bytes = new TextEncoder().encode(str);
    let binaryString = '';
    const bytes = new Uint8Array(utf8Bytes);
    bytes.forEach((byte) => (binaryString += String.fromCharCode(byte)));
    return btoa(binaryString);
  }

  decodeFromBase64(base64Str: any) {
    const binaryString = atob(base64Str);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new TextDecoder().decode(bytes);
  }

  sanitizeHTML = (htmlStr: string) => {
    return this.domSanitizer.bypassSecurityTrustHtml(
      htmlStr.replace(/&nbsp;/g, ' ')
    );
  };

  decodeHtmlEntities(encodedStr: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString(encodedStr, 'text/html');
    return dom.documentElement.textContent || '';
  }

  async getFileFromUrl(url: string, name: string): Promise<File | null> {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      const file = new File([blob], name, { type: blob.type });
      return file;
    } catch (error) {
      console.error('Failed to fetch image from URL:', error);
      return null; // Handle the error case
    }
  }

  getTruncatedText(description: string, length = APP_CONSTANTS.maxReadMoreSize) {
    const truncatedDescription = description.length > length 
    ? description.substring(0, length) + '...'
    : description;
   return this.domSanitizer.bypassSecurityTrustHtml(truncatedDescription);
  }

  shouldShowReadMore(description: string, length = APP_CONSTANTS.maxReadMoreSize): boolean {
    return description.length > length;
  }

  //////  to validte comma seapared values with no leading/trailing comma + no consecutive comma + have atleast 2 words ////////
  commaSeparatedValidator(length:number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.trim() !== '') {
        const value = control.value.trim();
        const pattern = /^(?!.*,,)(?!.*,$)(?!^,)(?!,)(?:[^,\s]+(?:\s+[^,\s]+)?(?:,[^,\s]+(?:\s+[^,\s]+)?)*)$/;
        const atleastWords = value.split(',').filter((part: any) => part.trim().length > 0).length >= length;
        return pattern.test(value) && atleastWords ? null : { 'commaSeparated': true };
      }
      return null;
    };
  }

  //////// convert time to hour minute second format /////////
  parseTimeString(timeString: string): NgbTimeStruct | null {
    const [hourStr, minuteStr] = timeString.split(':');
    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const second = 0;
    if (isNaN(hour) || isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
      return null; 
    }
    return { hour, minute,second };
  }

  ////////// convert time format///////////
  getFormattedTime(time: NgbTimeStruct): string {
    if (!time) return '';
    const hours = String(time.hour).padStart(2, '0');
    const minutes = String(time.minute).padStart(2, '0');
    return `${hours}:${minutes}`;
  }
}
