import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SlickCarouselComponent, SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment, WebNewsAndArticlesActions, WebNewsAndArticlesSelector } from '@panjab-digi-lib/shared';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { APP_CONSTANTS as CONSTANTS } from '../../../../common/constants/app.constants';
import { NewsAndArticlesService } from 'shared/src/services/website/news-and-articles/news-and-articles.service';
import { NewsArticles } from 'shared/src/interfaces/news-and-articles.interface';
import { SortOrder } from 'shared/src/interfaces/common.interface';

@Component({
  selector: 'panjab-digi-lib-news-and-articles',
  standalone: true,
  imports: [CommonModule,TranslateModule,SlickCarouselModule,RouterLink],
  templateUrl: './news-and-articles.component.html',
  styleUrl: './news-and-articles.component.scss',
})
export class NewsAndArticlesComponent implements OnInit, OnDestroy {
  
  constructor(
    private readonly store: Store,
    private readonly newsAndArticleService: NewsAndArticlesService,
    private readonly cdr: ChangeDetectorRef
  ) {}
  activeTab = 0;
  addNewsArticlesEnabled = false;
  subscription = new Subscription();
  maxSize = APP_CONSTANTS.maxSize;
  pageNumber = 1;
  pageSize = 4;
  type = 'all';
  newsAndArticlesList: NewsArticles[] = [];
  totalRecords = 0;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  fallbackImage = CONSTANTS.newsAndArticlesFallbackImage;
  defaultOrder = { orderBy: 'PublishedDate', order: 'DESC' };
  sortOrder: SortOrder = this.defaultOrder;
  subjectsList: any = [];
  unsubscribe$ = new Subject<void>();
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    // centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          dots: false
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          dots: false
        }
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      }
    ]
  };

  ngOnInit(): void {
    this.store
      .select(WebNewsAndArticlesSelector.loadNewsAndArticlesList)
      .pipe(
        filter((data) => !!data?.totalRecords),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        this.totalRecords = data?.totalRecords;
        this.newsAndArticlesList = data?.recordsList;
        // reinitiate the slider with updated data
          if (this.slickModal) {
            this.slickModal.unslick();
            this.cdr.detectChanges(); 
            this.slickModal.initSlick();
          }
      });
    // get subjects name used by news and articles
    this.loadSubjects();
  }

  setActiveTab = (key: any) => {
    this.activeTab = key;
    this.newsAndArticlesList = [];
    this.loadNewsAndArticles();
  };

  ///////////////// load subjects used in the newsandarticles list //////////////
  loadSubjects() {
    this.newsAndArticleService.getSubjects().subscribe((data) => {
      this.subjectsList = data;
      this.activeTab = data[0]?.id;
      // get list of articles
      this.loadNewsAndArticles();
    });
  }

  ////////////////// load all newsandarticles //////////////////
  loadNewsAndArticles() {
    const payload = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      subjectId: this.activeTab?.toString(),
      orderBy: this.sortOrder.orderBy,
      order: this.sortOrder.order
    };
    this.store.dispatch(WebNewsAndArticlesActions.LoadNewsAndArticles({ payload }));
  }

  //////////// get thumbnail image //////////////
  getThumbnailImg(item: any): string {
    return `${this.imageBaseUrl}${item.CategoryName}/${item.AccessionNo}/Thumbnails/${item.AccessionNo}_200.jpg`;
  }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  trackById(index: number, item: any): any {
    return item.ID;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
