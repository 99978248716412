<section class="exhibition-request-form">
  <div class="container reg-container">
    <div class="row d-flex">
      <!-- Column 1 - Static Content -->
      <div class="col-md-5 column-1">
        <div class="content fw-normal text-justify">
          <!-- Hide breadcrumbs in mobile view -->
          <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
            <panjab-digi-lib-breadcrumbs [pageName]="pageTitle" [route]="'/exhibitions'"></panjab-digi-lib-breadcrumbs>
          </div>
          <h5 class="fw-bold">{{'pdl-website.forms.labels.exhibitionForm.exhibition' | translate }}</h5>
          <div class="static-txt"
            [innerHtml]="'pdl-website.forms.labels.exhibitionForm.exhibitionLeftColText' | translate"></div>
        </div>
      </div>
      <!-- Column 2 - Exhibition Form -->
      <div class="col-md-7 column-2">
        <div class="right-section mb-5">
          <div class="top-content">
            <div class="header-title" #moveToTop tabindex="-1">
              <!-- Show breadcrumbs in mobile view -->
              <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                <panjab-digi-lib-breadcrumbs [pageName]="pageTitle" [route]="'/exhibitions'"></panjab-digi-lib-breadcrumbs>
              </div>
              <div class="title-txt mt-4">
                <h5 class="fw-bolder">{{'pdl-website.forms.labels.exhibitionForm.requestFormTitle' | translate }}</h5>
              </div>
            </div>
            <div class="header-body">
              <p class="fw-light"
                [innerHtml]="'pdl-website.forms.labels.exhibitionForm.exhibitionRightColText' | translate"></p>
            </div>
          </div>
          <form [formGroup]="exhibitionReqForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
            <div class="form-section2">
              <p class="fw-bold">{{'pdl-website.forms.labels.exhibitionForm.describeOrganization' | translate }}</p>
              <!-- organization -->
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="organizationName" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['organizationName'].invalid && exhibitionReqForm.controls['organizationName'].touched}">{{'pdl-shared.content.exhibition.organizationName'
                      | translate}}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationName'].invalid &&
                    exhibitionReqForm.controls['organizationName'].touched) {
                    <ng-container
                      *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.fullname' | translate }"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['organizationName'].valid &&
                    exhibitionReqForm.controls['organizationName'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }


                  </div>
                  <input type="text" autocomplete="off" id="organizationName" class="form-control"
                    formControlName="organizationName"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationName'].invalid && exhibitionReqForm.controls['organizationName'].touched}"
                    panjabDigiLibTrimmedText>
                </div>
              </div>
              <!-- address1 -->
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="organizationAddress" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['organizationAddress'].invalid && exhibitionReqForm.controls['organizationAddress'].touched}">{{'pdl-website.forms.labels.address'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationAddress'].invalid &&
                    exhibitionReqForm.controls['organizationAddress'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['organizationAddress'].valid &&
                    exhibitionReqForm.controls['organizationAddress'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" id="organizationAddress" class="form-control"
                    formControlName="organizationAddress"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationAddress'].invalid && exhibitionReqForm.controls['organizationAddress'].touched}"
                    panjabDigiLibTrimmedText>
                </div>
              </div>
              <!-- city, state -->
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="organizationCity" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['organizationCity'].invalid && exhibitionReqForm.controls['organizationCity'].touched}">{{'pdl-website.forms.labels.exhibitionForm.city'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationCity'].invalid &&
                    exhibitionReqForm.controls['organizationCity'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['organizationCity'].valid &&
                    exhibitionReqForm.controls['organizationCity'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" id="organizationCity" class="form-control"
                    formControlName="organizationCity"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationCity'].invalid && exhibitionReqForm.controls['organizationCity'].touched}"
                    panjabDigiLibTrimmedText>
                </div>
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="organizationState" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['organizationState'].invalid && exhibitionReqForm.controls['organizationState'].touched}">{{'pdl-website.forms.labels.exhibitionForm.state'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationState'].invalid &&
                    exhibitionReqForm.controls['organizationState'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['organizationState'].valid &&
                    exhibitionReqForm.controls['organizationState'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" id="organizationState" class="form-control"
                    formControlName="organizationState"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationState'].invalid && exhibitionReqForm.controls['organizationState'].touched}"
                    panjabDigiLibTrimmedText>
                </div>
              </div>
              <!-- country -->
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="organizationCountry" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['organizationCountry'].invalid && exhibitionReqForm.controls['organizationCountry'].touched}">{{'pdl-website.forms.labels.country'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationCountry'].invalid &&
                    exhibitionReqForm.controls['organizationCountry'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['organizationCountry'].valid &&
                    exhibitionReqForm.controls['organizationCountry'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <select class="form-select" formControlName="organizationCountry" id="organizationCountry"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationCountry'].invalid && exhibitionReqForm.controls['organizationCountry'].touched, 'select_option': exhibitionReqForm.controls['organizationCountry'] === null || exhibitionReqForm.controls['organizationCountry'].invalid}">
                    <option class="select_option" [ngValue]="null" disabled>Select</option>
                    @for(country of countryList$ | async; track country.CountryID) {
                    <option [ngValue]="country?.CountryID">{{country?.Name}}</option>
                    }
                  </select>
                </div>
              </div>
              <!-- phone, email -->
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="organizationPhone" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['organizationPhone'].invalid && exhibitionReqForm.controls['organizationPhone'].touched}">{{'pdl-website.forms.labels.phone'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationPhone'].touched &&
                    exhibitionReqForm.controls['organizationPhone'].errors &&
                    (exhibitionReqForm.controls['organizationPhone']['errors']['required'] ||
                    exhibitionReqForm.controls['organizationPhone']['errors']['invalidField'])) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @else if(exhibitionReqForm.controls['organizationPhone'].touched &&
                    exhibitionReqForm.controls['organizationPhone'].errors &&
                    exhibitionReqForm.controls['organizationPhone']['errors']) {
                    <ng-container
                      *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidPhonenum' | translate }"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['organizationPhone'].valid &&
                    exhibitionReqForm.controls['organizationPhone'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" [maxLength]="12" [minLength]="10" #phoneInput
                    class="form-control" formControlName="organizationPhone" id="organizationPhone"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationPhone'].invalid && exhibitionReqForm.controls['organizationPhone'].touched}"
                    panjabDigiLibNumberonly>
                </div>
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="organizationEmail" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['organizationEmail'].invalid && exhibitionReqForm.controls['organizationEmail'].touched}">{{'pdl-website.forms.labels.exhibitionForm.email'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationEmail'].touched &&
                    exhibitionReqForm.controls['organizationEmail'].errors &&
                    (exhibitionReqForm.controls['organizationEmail']['errors']['required'] ||
                    exhibitionReqForm.controls['organizationEmail']['errors']['invalidField'])) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @else if(exhibitionReqForm.controls['organizationEmail'].touched &&
                    exhibitionReqForm.controls['organizationEmail'].errors &&
                    exhibitionReqForm.controls['organizationEmail']['errors']['pattern']) {
                    <ng-container
                      *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidEmail' | translate }"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['organizationEmail'].valid &&
                    exhibitionReqForm.controls['organizationEmail'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="email" autocomplete="off" #emailInput class="form-control"
                    formControlName="organizationEmail" id="organizationEmail"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationEmail'].invalid && exhibitionReqForm.controls['organizationEmail'].touched}"
                    panjabDigiLibTrimmedText>

                </div>
              </div>
              <!-- website url -->
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="organizationWebsite" class="form-label">{{'pdl-shared.content.exhibition.website' |
                      translate}}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationWebsite'].touched &&
                    exhibitionReqForm.controls['organizationWebsite'].errors &&
                    exhibitionReqForm.controls['organizationWebsite']['errors']['pattern']) {
                    <ng-container
                      *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidUrl' | translate }"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" class="form-control" id="organizationWebsite"
                    formControlName="organizationWebsite" panjabDigiLibTrimmedText>
                </div>
              </div>
              <!-- about organization  -->
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="organizationDescription"
                      class="form-label reqd">{{'pdl-website.forms.labels.exhibitionForm.aboutOrganization' |
                      translate}}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['organizationDescription'].touched &&
                    exhibitionReqForm.controls['organizationDescription'].errors &&
                    (exhibitionReqForm.controls['organizationDescription']['errors']['required'] ||
                    exhibitionReqForm.controls['organizationDescription']['errors']['invalidField'])) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                  </div>
                  <textarea class="form-control" [maxLength]="1000" formControlName="organizationDescription"
                    id="organizationDescription"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['organizationDescription'].invalid && exhibitionReqForm.controls['organizationDescription'].touched}"
                    panjabDigiLibTrimmedText> </textarea>
                </div>
              </div>
              <div class="border-div mb-3 mt-4"></div>
              <p class="fw-bold">{{'pdl-website.forms.labels.exhibitionForm.describeYourself' | translate }}</p>
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="personName" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['personName'].invalid && exhibitionReqForm.controls['personName'].touched}">{{'pdl-website.forms.labels.exhibitionForm.name'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['personName'].touched &&
                    exhibitionReqForm.controls['personName'].errors &&
                    (exhibitionReqForm.controls['personName']['errors']['required'] ||
                    exhibitionReqForm.controls['personName']['errors']['invalidField'])) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['personName'].valid &&
                    exhibitionReqForm.controls['personName'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" class="form-control" [maxLength]="200" id="personName"
                    formControlName="personName"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['personName'].invalid && exhibitionReqForm.controls['personName'].touched}"
                    panjabDigiLibTrimmedText>

                </div>
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="personDesignation" class="form-label"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['personDesignation'].invalid && exhibitionReqForm.controls['personDesignation'].touched}">{{'pdl-website.forms.labels.exhibitionForm.designation'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['personDesignation'].touched &&
                    exhibitionReqForm.controls['personDesignation'].errors &&
                    (exhibitionReqForm.controls['personDesignation']['errors']['required'] ||
                    exhibitionReqForm.controls['personDesignation']['errors']['invalidField'])) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @else if(exhibitionReqForm.controls['personDesignation'].touched &&
                    exhibitionReqForm.controls['personDesignation'].errors &&
                    exhibitionReqForm.controls['personDesignation']['errors']['pattern']) {
                    <ng-container *ngTemplateOutlet="invalidError"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['personDesignation'].valid &&
                    exhibitionReqForm.controls['personDesignation'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" [maxLength]="100" class="form-control"
                    formControlName="personDesignation" id="personDesignation"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['personDesignation'].invalid && exhibitionReqForm.controls['personDesignation'].touched}"
                    panjabDigiLibTrimmedText>

                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="personPhone" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['personPhone'].invalid && exhibitionReqForm.controls['personPhone'].touched}">{{'pdl-website.forms.labels.exhibitionForm.mobile'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['personPhone'].touched &&
                    exhibitionReqForm.controls['personPhone'].errors &&
                    (exhibitionReqForm.controls['personPhone']['errors']['required'] ||
                    exhibitionReqForm.controls['personPhone']['errors']['invalidField'])) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @else if(exhibitionReqForm.controls['personPhone'].touched &&
                    exhibitionReqForm.controls['personPhone'].errors &&
                    exhibitionReqForm.controls['personPhone']['errors']) {
                    <ng-container
                      *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidPhonenum' | translate }"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['personPhone'].valid &&
                    exhibitionReqForm.controls['personPhone'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" id="personPhone" [maxLength]="12" [minLength]="10" #phoneInput
                    class="form-control" formControlName="personPhone"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['personPhone'].invalid && exhibitionReqForm.controls['personPhone'].touched}"
                    panjabDigiLibNumberonly>
                </div>
              </div>
              <div class="border-div mb-3 mt-4"></div>
              <p class="fw-bold">{{'pdl-website.forms.labels.exhibitionForm.exhibitionPlan' | translate }}</p>
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="exhibitionId" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['exhibitionId'].invalid && exhibitionReqForm.controls['exhibitionId'].touched}">{{'pdl-website.forms.labels.exhibitionForm.selectExhibition'
                      | translate}}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['exhibitionId'].invalid &&
                    exhibitionReqForm.controls['exhibitionId'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['exhibitionId'].valid &&
                    exhibitionReqForm.controls['exhibitionId'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <select class="form-select" formControlName="exhibitionId" id="exhibitionId"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['exhibitionId'].invalid && exhibitionReqForm.controls['exhibitionId'].touched, 'select_option': exhibitionReqForm.controls['exhibitionId'] === null || exhibitionReqForm.controls['exhibitionId'].invalid}">
                    <option [ngValue]="null" disabled>Select</option>
                    @for(exhibition of exhibitionList; track exhibition.ExhibitionID) {
                    <option [ngValue]="exhibition?.ExhibitionID">{{exhibition?.Title}}</option>
                    }
                  </select>
                </div>
              </div>
              @if(exhibitionReqForm.controls['exhibitionId'].value === -1) {
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="exhibitionName" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['exhibitionName'].invalid && exhibitionReqForm.controls['exhibitionName'].touched}">{{'pdl-shared.content.exhibition.exhibitionName'
                      | translate}}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['exhibitionName'].invalid &&
                    exhibitionReqForm.controls['exhibitionName'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['exhibitionName'].valid &&
                    exhibitionReqForm.controls['exhibitionName'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" class="form-control" id="exhibitionName"
                    formControlName="exhibitionName"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['exhibitionName'].invalid && exhibitionReqForm.controls['exhibitionName'].touched}"
                    panjabDigiLibTrimmedText>
                </div>
              </div>
              }
              <!-- address1 -->
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="exhibitionVenue" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['exhibitionVenue'].invalid && exhibitionReqForm.controls['exhibitionVenue'].touched}">{{'pdl-website.forms.labels.exhibitionForm.venue'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['exhibitionVenue'].invalid &&
                    exhibitionReqForm.controls['exhibitionVenue'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['exhibitionVenue'].valid &&
                    exhibitionReqForm.controls['exhibitionVenue'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" autocomplete="off" id="exhibitionVenue" class="form-control"
                    formControlName="exhibitionVenue"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['exhibitionVenue'].invalid && exhibitionReqForm.controls['exhibitionVenue'].touched}"
                    panjabDigiLibTrimmedText>
                </div>
              </div>
              <!-- start and end date -->
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="exhibitionStartDate" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['exhibitionStartDate'].invalid && exhibitionReqForm.controls['exhibitionStartDate'].touched}">{{'pdl-website.forms.labels.exhibitionForm.startDate'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['exhibitionStartDate'].invalid &&
                    exhibitionReqForm.controls['exhibitionStartDate'].touched) {
                    <ng-container
                      *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.fullname' | translate }"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['exhibitionStartDate'].valid &&
                    exhibitionReqForm.controls['exhibitionStartDate'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" class="form-control" ngbDatepicker [value]="formattedStartDate" readonly
                    (ngModelChange)="onStartDateChange($event)" #d="ngbDatepicker" [minDate]="minDate"
                    (click)="d.toggle()" id="exhibitionStartDate" formControlName="exhibitionStartDate"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['exhibitionStartDate'].invalid && exhibitionReqForm.controls['exhibitionStartDate'].touched}">
                </div>
                <div class="col-md-6">
                  <div class="d-flex">
                    <label for="exhibitionEndDate" class="form-label reqd"
                      [ngClass]="{'text-danger': exhibitionReqForm.controls['exhibitionEndDate'].invalid && exhibitionReqForm.controls['exhibitionEndDate'].touched}">{{'pdl-website.forms.labels.exhibitionForm.endDate'
                      | translate }}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['exhibitionEndDate'].invalid &&
                    exhibitionReqForm.controls['exhibitionEndDate'].touched) {
                    <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                    }
                    @if(exhibitionReqForm.controls['exhibitionEndDate'].valid &&
                    exhibitionReqForm.controls['exhibitionEndDate'].touched) {
                    <ng-container *ngTemplateOutlet="validFld"></ng-container>
                    }
                  </div>
                  <input type="text" ngbDatepicker readonly #endDate="ngbDatepicker" [minDate]="minDate"
                    [value]="formattedEndDate" (ngModelChange)="onEndDateChange($event)" (click)="endDate.toggle()"
                    class="form-control" formControlName="exhibitionEndDate"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['exhibitionEndDate'].invalid && exhibitionReqForm.controls['exhibitionEndDate'].touched}">
                  @if(exhibitionReqForm.get('exhibitionEndDate')?.hasError('endDateInvalid') &&
                  exhibitionReqForm.get('exhibitionEndDate')?.touched) {
                  <div class="text-danger mt-1">
                    End Date must be greater than Start Date.
                  </div>
                  }
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <label for="exhibitionOtherInformation"
                      class="form-label reqd">{{'pdl-website.forms.labels.exhibitionForm.otherInfo' |
                      translate}}</label>
                    <span class="flex-grow-1"></span>
                    @if(exhibitionReqForm.controls['exhibitionOtherInformation'].touched &&
                    exhibitionReqForm.controls['exhibitionOtherInformation'].errors &&
                    exhibitionReqForm.controls['exhibitionOtherInformation']['errors']['required']) {
                    <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                    }
                  </div>
                  <textarea class="form-control" formControlName="exhibitionOtherInformation"
                    id="exhibitionOtherInformation"
                    [ngClass]="{'border-danger': exhibitionReqForm.controls['exhibitionOtherInformation'].invalid && exhibitionReqForm.controls['exhibitionOtherInformation'].touched}"
                    panjabDigiLibTrimmedText> </textarea>
                </div>
              </div>
              <!-- recaptch -->
              <div class="row mt-4">
                <div class="col-12">
                  <re-captcha #captchaRef (resolved)="resolveCaptcha($event)" formControlNme="cpt" siteKey="{{sitekey}}"
                    [size]="isMobile ? 'compact' : 'normal'"></re-captcha>
                </div>
              </div>
              <!-- /recaptch -->
              <div class="row">
                <div class="col-6 text-end">
                  <button type="reset" class="btn btn-secondary btn__secondary">{{'pdl-website.forms.labels.resetBtn' |
                    translate }}</button>
                </div>
                <div class="col-6 text-start">
                  @if(isSubmitted) {
                  <panjab-digi-lib-spinner-button
                    [name]="'pdl-shared.loaderState.pleaseWait' | translate"></panjab-digi-lib-spinner-button>
                  } @else {
                  <button [disabled]="exhibitionReqForm.invalid" type="submit" class="btn btn-primary btn__primary">
                    {{'pdl-website.forms.labels.exhibitionForm.submitRequest' | translate }}
                  </button>
                  }
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Template - required field error -->
  <ng-template #requiredError>
    <span class="text-danger">
      <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
    </span>
  </ng-template>
  <!-- Template - invalid field error -->
  <ng-template #invalidError let-msg="msg">
    <span class="text-danger">
      <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
      <ng-template #tipContent>{{msg}}</ng-template>
    </span>
  </ng-template>
  <!-- Template - valid field -->
  <ng-template #validFld>
    <span class="text-success">
      <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
    </span>
  </ng-template>
</section>
