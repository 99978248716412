@if (loading) {
<div class="loader__wrapper">
  <panjab-digi-lib-spinner [isFullScreen]="true"></panjab-digi-lib-spinner>
</div>
} @else {
<panjab-digi-lib-static-page-head [pageName]="'pdl-website.team.titleText' | translate" pagePath="/team">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding pt-3 ">
  <div class="container">
    <div class="faq-header-wrapper mb-5 no-scrollbar mt-3">
      @for (userType of userTypes; track userType.TypeID) {
      <span class="faq-item cursor-pointer" (click)="setActiveType(userType)"
        [ngClass]="{'active-item' : userType.TypeID === activeType.TypeID}">
        <span>{{userType.Name}}</span>
      </span>
      }
    </div>
    <div class="d-flex flex-wrap staff_details_wrapper">
      @if (staffDetails?.length) {
      @for (staff of staffDetails; track staffDetails.StaffID) {
      <a [href]="'/team/'+staff.StaffID">
        <div class="staff_card">
          <div class="img_container mb-5">
            <img [src]="azureCloudStorageUrl + staff.Image" [alt]="staff.FName"
              (error)="handleImageError($event, staff)" />
            <span class="staffType">{{activeType.Name}}</span>
          </div>
          <h1 class="name">
            {{staff.FName}}
          </h1>
          <span class="addInfo mb-3">
            {{staff.AdditionalInfo}}
          </span>
          <p class="bio" [innerHTML]="setStaffBio(staff.Bio, staff.StaffID) | safeHtml">
          </p>
        </div>
      </a>
      }
      } @else {
      Sorry, Nothing to see here.
      }
    </div>
  </div>
</section>
}
