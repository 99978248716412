<div class="reader__container">
  <ia-bookreader>
    <div id="BookReader" class="BookReader" slot="main"></div>
    <noscript>
      <p>
        The BookReader requires JavaScript to be enabled. Please check that
        your browser supports JavaScript and that it is enabled in the
        browser settings.
      </p>
    </noscript>
  </ia-bookreader>
    <!-- // Bookmarks view -->
  @if (showBookMarksContainer) {
    <panjab-digi-lib-bookmarks
      [showBookMarksContainer]="showBookMarksContainer" 
      (oncloseBookmarksContainer)="closeBookmarks()" 
      [pages]="getCurrentPage()"
      (goToPage)="goToPageNo($event)" 
      [docId]="docId">
    </panjab-digi-lib-bookmarks>
  }
  @if (showNotesContainer) {
    <panjab-digi-lib-notes 
      [showNotesContainer]="showNotesContainer" 
      (oncloseNotesContainer)="closeNotes()" 
      [docId]="docId"
      [pages]="getCurrentPage()">
    </panjab-digi-lib-notes>
  }
  @if(!isHovered && !(showNotesContainer || showBookMarksContainer) && activeUser){
  <button class="btn btn-primary btn__primary toggle__right" (click)="showControls()">
    <img src="assets/images/icons/reader-control-btn.svg" alt="toggle" class="img-fluid" />
  </button>
  }
  @if (activeUser){
  <div class="btn-container controller__container" [class.hover_class]="isHovered">
    <ul>
      <li><button (click)="closeToggle()" class="btn btn-link" title="close menu">
          <img src="assets/images/icons/reader-close-icon.svg" alt="Search" class="img-fluid" /></button>
      </li>
      <li><button (click)="showNotesView()" class="btn btn-link" title="Show Notes">
          <img src="assets/images/icons/memo-pad.svg" alt="Notes" class="img-fluid" /></button>
      </li>
      <li><button (click)="showBookMarksView()" class="btn btn-link" title="Show Bookmarks">
          <img src="assets/images/icons/bookmark-ico.svg" alt="Bookmarks" class="img-fluid" /></button>
      </li>
    </ul>
  </div>
  }
</div>