@if(allExhibitions.length> 0) {
<section class="exhibitions section-padding">
   <div class="container">
      <div class="row">
         <div class="col-12">
            <h2 class="heading-style semibold text-center mb-4">
               {{'pdl-website.exhibitionsSection.sectionTitle' | translate }}
            </h2>
            <p class="title-description text-center mb-lg-40 pdl-grey">
               {{'pdl-website.exhibitionsDescription.descriptionText' | translate }}
            </p>
         </div>
         <div class="col-12">
            <div class="exhibitions-in-grid">
               <div class="row justify-content-center">
                  @for(exhibition of allExhibitions;track exhibition) {
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                     <div class="exhibition-card position-relative d-flex flex-column">
                        <div class="block-img cursor-pointer">
                           <img src="{{imageBaseUrl}}{{exhibition.ItemPath}}" alt="image"
                              (click)="navigateToDetail(exhibition)">
                        </div>
                        <div class="block-text ps-4 pe-4 d-flex flex-column justify-content-between flex-grow-1">
                           <h5 class="medium-font cursor-pointer" (click)="navigateToDetail(exhibition)">
                              {{exhibition.Title }}
                           </h5>
                           <div class="small-tags">
                              <span class="small-tag">
                                 <img src="assets/images/icons/calendar-ico.svg" alt="calendar-icon">
                                 {{exhibition.Date.startDate | date : 'dd MMMM YYYY'}} -
                                 {{exhibition.Date.endDate | date : 'dd MMMM YYYY'}}
                              </span>
                              <span class="small-tag">
                                 <img src="assets/images/icons/location-ico.svg" alt="calendar-icon">
                                 {{exhibition.Location}}
                              </span>
                           </div>
                           <p class="description-text exhibition_description_ellipses"
                              [innerHTML]="sanitizeHTML(exhibition.Description)"></p>
                           <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                              <a href="#"
                                 class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.exhibitionDirection.buttonText'
                                 | translate }} <img src="assets/images/icons/directions-ico.svg"
                                    alt="direction-icon"></a>
                              <a href="#"
                                 class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                 {{'pdl-website.sponsorVisit.buttonText' | translate }} <img
                                    src="assets/images/icons/visit-ico.svg" alt="visit-icon"></a>
                           </div>
                        </div>
                     </div>
                  </div>
                  }
               </div>
            </div>
         </div>
         <div class="col-12">
            <div class="centered-btn d-flex align-items-center justify-content-center">
               <a [routerLink]="['/exhibitions']"
                  class="btn pdl-btn">{{'pdl-website.exhibitionsmainButton.allExhibitionsText' | translate }}</a>
            </div>
         </div>
      </div>
   </div>
</section>
<div class="global-divider">
   <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
}
