<section class="newsarticles section-padding padding-top-md-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-lg-40 f-31">
                    {{'pdl-website.newsandarticlesSection.sectionTitle' |
                    translate }}</h2>
                <p class="title-description text-center mb-4 pdl-grey">
                    {{'pdl-website.newsandarticlesSection.homePageDescription' | translate}}
                </p>
            </div>
            @if(subjectsList && subjectsList.length > 0) {
            <div class="col-12 hidden-after-767">
                <div class="faq-header-wrapper mb-5 no-scrollbar mt-3">
                    @for (subject of subjectsList; track subject.id) {
                    <span class="faq-item" [ngClass]="{'active-item' : subject.id === activeTab}">
                        <span (click)="setActiveTab(subject.id)" class="cursor-pointer"
                            tabindex="">{{subject.name}}</span>
                    </span>
                    }
                </div>
                <div class="list_section row d-flex flex-wrap justify-content-center">
                    @for(item of newsAndArticlesList; track item.ID) {
                    <div class="item col-lg-3 col-md-4 col-sm-6 col-12">
                        <a href="javascript:void(0);" [routerLink]="'/news-and-articles/'+activeTab+'/'+item.ID">
                            <img src="{{imageBaseUrl}}{{item.CategoryName}}/{{item.AccessionNo}}/Thumbnails/{{item.AccessionNo}}_200.jpg" (error)="handleImageError($event)" class="banner_img"
                                alt="" />
                        </a>
                        <div class="item_content">
                            <span class="pill">Published on: {{item.PublishedDate | date : 'dd MMMM YYYY'}} </span>
                            <span class="heading"><a href="javascript:void(0);"
                                    [routerLink]="'/news-and-articles/'+activeTab+'/'+item.ID">{{item.Title | titlecase}}</a></span>
                        </div>
                    </div>
                    } @empty {
                    <div class="item" style="min-height: 300px;">
                        <div class="text-center">{{'pdl-shared.content.noRecordsFound' | translate}}</div>
                    </div>
                    }
                </div>
            </div>

            <div class="col-12 visible-below-767">
                <div class="faq-header-wrapper mb-5 no-scrollbar mt-3">
                    @for (subject of subjectsList; track subject.id) {
                    <span class="faq-item" [ngClass]="{'active-item' : subject.id === activeTab}">
                        <span (click)="setActiveTab(subject.id)" class="cursor-pointer"
                            tabindex="">{{subject.name}}</span>
                    </span>
                    }
                </div>
                <div class="newsarticles-slider">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <div *ngFor="let item of newsAndArticlesList; trackBy: trackById" class="item" ngxSlickItem class="slide newsarticles-slide pe-4">
                            <a href="javascript:void(0);" [routerLink]="'/news-and-articles/'+activeTab+'/'+item.ID"
                                class="newsarticles-card">
                                <img src="{{imageBaseUrl}}{{item.CategoryName}}/{{item.AccessionNo}}/Thumbnails/{{item.AccessionNo}}_200.jpg" (error)="handleImageError($event)"
                                    class="block-img banner_img img-responsive" alt="test" />
                            </a>
                            <div class="item_content">
                                <span class="pill">Published on: {{item.PublishedDate | date : 'dd MMMM YYYY'}} </span>
                                <span class="heading"><a href="javascript:void(0);"
                                        [routerLink]="'/news-and-articles/'+activeTab+'/'+item.ID">{{item.Title |
                                        titlecase}}</a></span>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>

            <div class="col-12 mt-4">
                <div class="centered-btn d-flex align-items-center justify-content-center">
                    <a href="javascript:void(0);" [routerLink]="'/news-and-articles'"
                        class="btn pdl-btn">{{'pdl-website.newsandarticlesButton.buttonText' | translate }}</a>
                </div>
            </div>
            }
        </div>
    </div>
</section>
