/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToAdoptAction } from '../actions';
import { ToAdoptService } from 'shared/src/services/admin/Fundraising/to-adopt.service';
import { DocumentInfo } from 'shared/src/interfaces/document-info.interface';
import { Update } from '@ngrx/entity';

@Injectable()
export class ToAdoptEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private router: Router,
        private toAdoptService: ToAdoptService
    ) {
    }
    
    LoadToAdopt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ToAdoptAction.LoadToAdopt),
            switchMap((action) => {

                return this.toAdoptService.loadToAdopt(action.payload).pipe(
                    map((response) => {
                        return ToAdoptAction.LoadToAdoptSuccess({ documentList: response.documentList, totalCount: response.totalCount });
                    }),
                    catchError((error: any) => of(ToAdoptAction.LoadToAdoptError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    AddAdopter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ToAdoptAction.AddAdopter),
            exhaustMap((action) => {
                return this.toAdoptService.addAdopter(action.payload).pipe(
                    map((response: any) => {
                        return ToAdoptAction.AddAdopterSuccess({ createdAdopters: response.createdAdopters, message: response.message, reload: true });
                    }),
                    catchError((error: any) => of(ToAdoptAction.AddAdopterError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );

    ListForAdoptionStatusUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ToAdoptAction.ListForAdoptionStatusUpdate),
            exhaustMap((action) => {
                return this.toAdoptService.toggleListOfAdoptionStatus(action.ids, action.status).pipe(
                    map((response: any) => {
                        const toAdoptUpdate: Update<DocumentInfo>[] = action.ids.map((id: any) => {
                            const changes: any = {ListForAdoption : action.status};
                            return { id, changes }
                        })
                        return ToAdoptAction.ListForAdoptionStatusUpdateSuccess({ updatedDocuments: toAdoptUpdate, message: response.message });
                    }),
                    catchError((error: any) => of(ToAdoptAction.ListForAdoptionStatusUpdateError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );

    OnAddAdopterSuccess$ = createEffect(() => 
        this.actions$.pipe(
            ofType(ToAdoptAction.AddAdopterSuccess),
            tap(() => this.router.navigateByUrl('/fundraising/adopt/to-adopt'))
        ), 
        { dispatch: false }
    )
}