<section class="volunteer-request-form">
    @if(loading) {
    <div class="loader__wrapper">
        <panjab-digi-lib-spinner [isFullScreen]="true"></panjab-digi-lib-spinner>
    </div>
    }
    <div class="container reg-container">
        <div class="row d-flex">
            <!-- Column 1 - Static Content -->
            <div class="col-md-5 column-1">
                <div class="content fw-normal text-justify">
                    <!-- Hide breadcrumbs in mobile view -->
                    <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                        <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"
                            route="/volunteer"></panjab-digi-lib-breadcrumbs>
                    </div>
                    <h5 class="fw-bold">{{'pdl-website.forms.labels.volunteerForm.volunteer' | translate }}</h5>
                    <div class="static-txt"
                        [innerHtml]="'pdl-website.forms.labels.volunteerForm.volunteerLeftColText' | translate"></div>
                </div>
            </div>
            <!-- Column 2 - Volunteer Form -->
            <div class="col-md-7 column-2">
                <div class="right-section">
                    <div class="top-content">
                        <div class="header-title" #moveToTop tabindex="-1">
                            <!-- Show breadcrumbs in mobile view -->
                            <div
                                class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                                <panjab-digi-lib-breadcrumbs [route]="'/volunteer'"
                                    [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                            </div>
                            <div class="title-txt mt-4">
                                <h5 class="fw-bolder">{{'pdl-website.forms.labels.volunteerForm.requestFormTitle' |
                                    translate }}</h5>
                            </div>
                        </div>
                        <div class="header-body">
                            <p class="fw-light"
                                [innerHtml]="'pdl-website.forms.labels.volunteerForm.volunteerRightColText' | translate">
                            </p>
                        </div>
                    </div>
                    <form [formGroup]="volunteerReqForm" (ngSubmit)="onSubmit()">
                        <div class="form-section1">
                            <!-- show alert -->
                            <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                            <!-- /show alert -->
                            <p class="fs-13"
                                [innerHtml]="'pdl-website.forms.labels.volunteerForm.volunteerReqTypesTitle' | translate">
                            </p>
                            <div formArrayName="options">
                                <div class="form-check" *ngFor="let optn of checkboxData; let i = index">
                                    <input class="form-check-input" (change)="handleReqTypeSelect($event, i)"
                                        type="checkbox" [formControlName]="i" />
                                    <label class="form-check-label"> {{optn.value}}</label>
                                </div>
                            </div>
                            <div *ngIf="volunteerReqForm.controls['options']['errors']?.['required']">
                                <span class="err-msg">At least one checkbox must be selected.</span>
                            </div>
                        </div>
                        <div class="form-section2">
                            <p class="fw-bold">{{'pdl-website.forms.user.formHeader' | translate }}</p>
                            @if(optionsSelected.includes(0)) {
                            <div class="row">
                                <div class="col">
                                    <div class="d-flex">
                                        <label for="orgnizeItem" class="form-label reqd">
                                            Choose Organize Item
                                        </label>
                                        <span class="flex-grow-1"></span>
                                    </div>
                                    <div class="d-flex gap-56">
                                        <div>
                                            <input formControlName="organize" name="organize" type="radio"
                                                class="form-check-input" value="events" aria-label="Events" />
                                            <label for="organize" class=" ">Events</label>
                                        </div>
                                        <div>
                                            <input formControlName="organize" name="organize" type="radio"
                                                class="form-check-input" value="exhibitions" aria-label="Exhibitions" />
                                            <label for="organize" class=" ">Exhibitions</label>
                                        </div>
                                        <div>
                                            <input formControlName="organize" name="organize" type="radio"
                                                class="form-check-input" value="campaigns" aria-label="Campaigns" />
                                            <label for="organize" class=" ">Campaigns</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="volunteerReqForm.controls['organize']['errors']?.['required']">
                                <span class="err-msg">Select any one event type. </span>
                            </div>
                            <div class="row">
                                @if (volunteerReqForm.get('organize')?.value && !futureVoluteerableOptions.length) {
                                <span class="err-msg">Currently there are no
                                    {{volunteerReqForm.get('organize')?.value}}</span>
                                } @else {

                                @if (futureVoluteerableOptions?.length) {
                                <div class="row">
                                    <div class="col">
                                        <div class="d-flex">
                                            <label for="orgnizeItem" class="form-label reqd"
                                                [ngClass]="{'text-danger': volunteerReqForm.controls['futureEventId'].invalid && volunteerReqForm.controls['futureEventId'].touched}">
                                                Choose the {{volunteerReqForm.get('organize')?.value}}
                                            </label>
                                            <span class="flex-grow-1"></span>
                                            @if(volunteerReqForm.controls['futureEventId'].invalid &&
                                            volunteerReqForm.controls['futureEventId'].touched) {
                                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                            }
                                            @if(volunteerReqForm.controls['futureEventId'].valid &&
                                            volunteerReqForm.controls['futureEventId'].touched) {
                                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                            }
                                        </div>
                                    </div>
                                    <div class="">
                                        <select class="form-select" formControlName="futureEventId" id="futureEventId"
                                            [ngClass]="{'border-danger': volunteerReqForm.controls['futureEventId'].invalid && volunteerReqForm.controls['futureEventId'].touched}">
                                            <option [ngValue]="null" disabled>Select</option>
                                            @for(option of futureVoluteerableOptions; track option.ExhibitionID) {
                                            <option [ngValue]="option?.ExhibitionID ?? option?.ID">
                                                {{getTitleFromOption(option, 100)}}
                                            </option>
                                            }
                                        </select>
                                    </div>
                                </div>

                                }
                                }
                            </div>

                            }


                            <!-- name, designation -->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="name" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['name'].invalid && volunteerReqForm.controls['name'].touched}">{{'pdl-website.forms.labels.fullname'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['name'].invalid &&
                                        volunteerReqForm.controls['name'].touched) {
                                        <ng-container
                                            *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.fullname' | translate }"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['name'].valid &&
                                        volunteerReqForm.controls['name'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="name"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['name'].invalid && volunteerReqForm.controls['name'].touched}"
                                        panjabDigiLibTrimmedText>
                                </div>
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="designation" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['designation'].invalid && volunteerReqForm.controls['designation'].touched}">{{'pdl-website.forms.labels.designation'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['designation'].invalid &&
                                        volunteerReqForm.controls['designation'].touched) {
                                        <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['designation'].valid &&
                                        volunteerReqForm.controls['designation'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="designation"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['designation'].invalid && volunteerReqForm.controls['designation'].touched}"
                                        panjabDigiLibTrimmedText>
                                </div>
                            </div>
                            <!-- organization -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <label for="organization" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['organization'].invalid && volunteerReqForm.controls['organization'].touched}">{{'pdl-website.forms.labels.organization'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['organization'].invalid &&
                                        volunteerReqForm.controls['organization'].touched) {
                                        <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['organization'].valid &&
                                        volunteerReqForm.controls['organization'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="organization"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['organization'].invalid && volunteerReqForm.controls['organization'].touched}"
                                        panjabDigiLibTrimmedText>
                                </div>
                            </div>
                            <!-- address1 -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <label for="address1" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['address1'].invalid && volunteerReqForm.controls['address1'].touched}">{{'pdl-website.forms.labels.address'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['address1'].invalid &&
                                        volunteerReqForm.controls['address1'].touched) {
                                        <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['address1'].valid &&
                                        volunteerReqForm.controls['address1'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="address1"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['address1'].invalid && volunteerReqForm.controls['address1'].touched}"
                                        panjabDigiLibTrimmedText>
                                </div>
                            </div>
                            <!-- address2 -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <label for="address2" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['address2'].invalid && volunteerReqForm.controls['address2'].touched}">{{'pdl-website.forms.labels.cityStateZip'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['address2'].invalid &&
                                        volunteerReqForm.controls['address2'].touched) {
                                        <ng-container *ngTemplateOutlet="requiredError;"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['address2'].valid &&
                                        volunteerReqForm.controls['address2'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="address2"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['address2'].invalid && volunteerReqForm.controls['address2'].touched}"
                                        panjabDigiLibTrimmedText>
                                </div>
                            </div>
                            <!-- country -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <label for="country" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['country'].invalid && volunteerReqForm.controls['country'].touched}">{{'pdl-website.forms.labels.country'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['country'].invalid &&
                                        volunteerReqForm.controls['country'].touched) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['country'].valid &&
                                        volunteerReqForm.controls['country'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <select class="form-select" formControlName="country" id="country"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['country'].invalid && volunteerReqForm.controls['country'].touched}">
                                        <option [ngValue]="null" disabled>Select</option>
                                        @for(country of countryList$ | async; track country.CountryID) {
                                        <option [ngValue]="country?.CountryID">{{country?.Name}}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <!-- phone, email -->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="phone" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['phone'].invalid && volunteerReqForm.controls['phone'].touched}">{{'pdl-website.forms.labels.phone'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['phone'].touched &&
                                        volunteerReqForm.controls['phone'].errors &&
                                        (volunteerReqForm.controls['phone']['errors']['required'] ||
                                        volunteerReqForm.controls['phone']['errors']['invalidField'])) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @else if(volunteerReqForm.controls['phone'].touched &&
                                        volunteerReqForm.controls['phone'].errors &&
                                        volunteerReqForm.controls['phone']['errors']) {
                                        <ng-container
                                            *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidPhonenum' | translate }"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['phone'].valid &&
                                        volunteerReqForm.controls['phone'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="text" #phoneInput class="form-control" formControlName="phone"
                                        (blur)="validateOnBlur(VALIDATE_TYPE.PHONE)"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['phone'].invalid && volunteerReqForm.controls['phone'].touched}"
                                        panjabDigiLibNumberonly>
                                    @if(volunteerReqForm.controls['phone'].errors &&
                                    volunteerReqForm.controls['phone']['errors']['invalidField']) {
                                    <div class="err-msg">
                                        {{'pdl-website.forms.user.phoneExist' | translate}}
                                    </div>
                                    }
                                </div>
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <label for="email" class="form-label reqd"
                                            [ngClass]="{'text-danger': volunteerReqForm.controls['email'].invalid && volunteerReqForm.controls['email'].touched}">{{'pdl-website.forms.labels.email'
                                            | translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['email'].touched &&
                                        volunteerReqForm.controls['email'].errors &&
                                        (volunteerReqForm.controls['email']['errors']['required'] ||
                                        volunteerReqForm.controls['email']['errors']['invalidField'])) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                        }
                                        @else if(volunteerReqForm.controls['email'].touched &&
                                        volunteerReqForm.controls['email'].errors &&
                                        volunteerReqForm.controls['email']['errors']['pattern']) {
                                        <ng-container
                                            *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidEmail' | translate }"></ng-container>
                                        }
                                        @if(volunteerReqForm.controls['email'].valid &&
                                        volunteerReqForm.controls['email'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                        }
                                    </div>
                                    <input type="email" #emailInput class="form-control" formControlName="email"
                                        (blur)="validateOnBlur(VALIDATE_TYPE.EMAIL)" id="email"
                                        [ngClass]="{'border-danger': volunteerReqForm.controls['email'].invalid && volunteerReqForm.controls['email'].touched}"
                                        panjabDigiLibTrimmedText>
                                    @if(volunteerReqForm.controls['email'].errors &&
                                    volunteerReqForm.controls['email']['errors']['invalidField']) {
                                    <div class="err-msg">
                                        {{'pdl-website.forms.user.emailExist' | translate}}
                                    </div>
                                    }
                                </div>
                            </div>
                            <!-- personal url -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <label for="url" class="form-label">{{'pdl-website.forms.labels.personalUrl' |
                                            translate }}</label>
                                        <span class="flex-grow-1"></span>
                                        @if(volunteerReqForm.controls['url'].touched &&
                                        volunteerReqForm.controls['url'].errors &&
                                        volunteerReqForm.controls['url']['errors']['pattern']) {
                                        <ng-container
                                            *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidUrl' | translate }"></ng-container>
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="url"
                                        panjabDigiLibTrimmedText>
                                </div>
                            </div>
                            <!-- recaptch -->
                            <div class="row mt-4">
                                <div class="col-12">
                                    <re-captcha #captchaRef (resolved)="resolveCaptcha($event)" formControlNme="cpt"
                                        siteKey="{{sitekey}}" [size]="isMobile ? 'compact' : 'normal'"></re-captcha>
                                </div>
                            </div>
                            <!-- /recaptch -->
                            <div class="row">
                                <div class="col-6 text-end">
                                    <button type="reset"
                                        class="btn btn-secondary btn__secondary">{{'pdl-website.forms.labels.resetBtn' |
                                        translate }}</button>
                                </div>
                                <div class="col-6 text-start">
                                    @if(isSubmitted) {
                                    <panjab-digi-lib-spinner-button
                                        [name]="'pdl-shared.loaderState.pleaseWait' | translate"></panjab-digi-lib-spinner-button>
                                    } @else {
                                    <button [disabled]="volunteerReqForm.invalid" type="submit"
                                        class="btn btn-primary btn__primary">
                                        {{'pdl-website.forms.labels.volunteerForm.submitRequest' | translate }}
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Template - required field error -->
    <ng-template #requiredError>
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
        </span>
    </ng-template>
    <!-- Template - invalid field error -->
    <ng-template #invalidError let-msg="msg">
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
            <ng-template #tipContent>{{msg}}</ng-template>
        </span>
    </ng-template>
    <!-- Template - valid field -->
    <ng-template #validFld>
        <span class="text-success">
            <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
        </span>
    </ng-template>
</section>
