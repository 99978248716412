<div class="uploadUnitWrapper mt-4">
  <div
    class="d-flex flex-column justify-content-center align-items-center gap-15">
    <span class="semibold">{{uploadInfo.Label}} {{'pdl-shared.content.only' |
      translate}}</span>
    <div
      class="uploadUnit cursor-pointer gap-10 p-4"
      (click)="onFileSelect()"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [class.dragging]="dragging"
      [ngStyle]="addStyle">
      @if(selectedFiles.length === 0) {
      <img src="assets/images/icons/upload.svg" alt="Upload">
      <span>{{ 'pdl-shared.content.dragDropContent' | translate }}</span>
      <span>{{ 'pdl-shared.content.or' | translate }}</span>
      <span>{{ 'pdl-shared.content.clickToBrowse' | translate }}</span>
      } @else {
      <div class="selected-files-info d-flex">
        @if(selectedFiles.length > 1) {
        <span class="file-name me-2">{{ selectedFiles.length }}
          {{'pdl-staff.upload.fileSelected' | translate}}</span>
        } @else {
        <span class="file-name me-2">{{ selectedFiles[0].file.name }}</span>
        }
        <img (click)="onDeleteFile($event)"
          src="assets/images/icons/trash-grey-icon.svg" alt="Remove">
      </div>
      }
    </div>
    <input type="file" #fileInput style="display: none;"
      (change)="onFileChange($event)"
      [accept]="acceptFileExtensions"
      [multiple]="uploadInfo.isMultiple">
    <span class="indicator">{{'pdl-staff.upload.supportedFile' | translate}}
      {{acceptFileExtLabel}}.</span>
  </div>
</div>
