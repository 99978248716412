/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { WebNewsAndArticlesActions } from 'shared/src/store/actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { NewsArticles } from 'shared/src/interfaces/news-and-articles.interface';

export interface State extends EntityState<NewsArticles> {
  isLoading?: boolean;
  isError?: boolean;
  totalRecords: number;
  pageNumber : number,
  pageSize: number,
  recordsList: NewsArticles[] | [], 
  articleDetails?: NewsArticles | null,
  relatedArticles?: NewsArticles[]
}

export const adapter = createEntityAdapter<NewsArticles>({
  selectId: (e) => e?.ID,
});

export const initialState : State = adapter.getInitialState({
  isLoading: false,
  isError: false,
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0,
  recordsList: [],
  articleDetails: null,
  relatedArticles: []
});

export const reducer = createReducer(
  initialState,

  on(WebNewsAndArticlesActions.LoadNewsAndArticles, (state,action) => ({
    ...state,
    isLoading: true,
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
    orderBy: action.payload?.orderBy,
    order: action.payload?.order
  })),

  on(WebNewsAndArticlesActions.LoadNewsAndArticlesSuccess, (state, action) => 
    adapter.setAll(action?.recordsList, {
      ...state,
      totalRecords: action?.totalRecords,
      isLoading: false,
      isError: false,
      articleDetails: null,
      relatedArticles: []
    })
  ),

  on(WebNewsAndArticlesActions.LoadNewsAndArticlesError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
      recordsList: [],
      articleDetails: null,
      relatedArticles: []
    };
  }),

  on(WebNewsAndArticlesActions.LoadNewsAndArticlesDetails, (state,action) => ({
    ...state,
    isLoading: true,
    id: action.payload?.id,
    articleDetails: null
  })),

  on(WebNewsAndArticlesActions.LoadNewsAndArticlesDetailsSuccess, (state, { record }) => {
      return {
        ...state,
        articleDetails: record?.articleDetails,
        relatedArticles: record?.relatedArticles,
        isLoading: false,
        isError: false
      }
    }
  ),

  on(WebNewsAndArticlesActions.LoadNewsAndArticlesDetailsError, (state, action) => {
    return {
      ...state,
      articleDetails: null,
      isLoading: false,
      isError: true
    };
  }),
);

export const { selectAll } = adapter.getSelectors();
