/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterOutlet } from '@angular/router';
import {
  DocumentAttachment,
  SelectedFile,
} from 'shared/src/interfaces/staff/upload.interface';
import { ToastService } from 'shared/src/services/toast/toast.service';

@Component({
  selector: 'panjab-digi-lib-upload-file',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltip, RouterOutlet],
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.scss',
})
export class UploadFileComponent implements OnInit, OnChanges {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() uploadInfo!: DocumentAttachment;
  @Input() uploadCompletedTrigger: boolean = false;
  @Input() selectedFiles: SelectedFile[] = [];
  @Input() addStyle: { [key: string]: string } = {};
  @Output() uploadablefiles = new EventEmitter<SelectedFile[]>();

  allowedFileTypes: string[] = [];

  dragging = false;
  acceptFileExtensions: string = '';
  acceptFileExtLabel: string = '';

  constructor(
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.uploadInfo && this.uploadInfo.AllowedFiles) {
      this.allowedFileTypes = this.uploadInfo.AllowedFiles;
      this.acceptFileExtensions = this.uploadInfo.AcceptedFileExtns || '';
      this.acceptFileExtLabel = this.uploadInfo.AcceptedFileExtns.split(',')
        .map((extension) => {
          return extension.toUpperCase().replace('.', '');
        })
        .join(', ');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['uploadCompletedTrigger'] &&
      changes['uploadCompletedTrigger'].currentValue
    ) {
      this.selectedFiles = [];
      this.fileInput.nativeElement.value = '';
      // this.uploadablefiles.emit(this.selectedFiles);
    }
  }

  onFileSelect(): void {
    this.fileInput.nativeElement.click();
  }

  onDeleteFile(event: Event): void {
    event.stopPropagation();
    this.selectedFiles = [];
    this.uploadablefiles.emit(this.selectedFiles);
    this.clearFileInput();
  }

  clearFileInput(): void {
    this.fileInput.nativeElement.value = '';
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFiles(input.files);
      input.value = '';
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.dragging = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.dragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.dragging = false;
    if (event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  addFiles(files: FileList): void {
    const filePromises = [];

    const invalidFiles: string[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.isFileTypeAllowed(file.type)) {
        // Push each file preview operation into the promises array
        filePromises.push(this.previewFile(file));
      } else {
        invalidFiles.push(file.name);
      }
    }

    if (invalidFiles.length > 0) {
      this.toastService.showWarning(
        `The following files are not allowed: ${invalidFiles.join(', ')}`
      );
    }

    // Wait for all file previews to be completed before emitting the selected files
    Promise.all(filePromises).then(() => {
      // Emit the updated selectedFiles array
      this.uploadablefiles.emit(
        this.selectedFiles.filter((file) => !file.isUploaded)
      );
    });
  }

  previewFile(file: File): void {
    const fileInfo = {
      file,
      preview: '', // Initialize preview as an empty string
      isUploading: false,
      isUploaded: false,
      uploadSuccess: false,
      uploadError: false,
    };

    // Add the file info immediately to selectedFiles array
    if (this.uploadInfo.isMultiple) {
      const fileExists =
        this.selectedFiles.findIndex((selectedFile) => {
          return selectedFile.file.name == fileInfo.file.name;
        }) > -1;
      if (!fileExists) {
        this.selectedFiles.push(fileInfo);
      } else {
        this.toastService.showWarning(
          this.translate
            .instant('pdl-staff.upload.fileAlreadySelected')
            .replace('<fileName>', `"${fileInfo.file.name}"`)
        );
        return;
      }
    } else {
      this.selectedFiles = [fileInfo];
    }

    // Now read the file as a data URL for the preview asynchronously
    const reader = new FileReader();

    reader.onload = (e) => {
      const preview = e.target?.result as string;
      fileInfo.preview = preview; // Update the file info with the preview
    };

    reader.readAsDataURL(file); // Read the file as a data URL
  }

  isFileTypeAllowed(fileType: string): boolean {
    return this.allowedFileTypes.includes(fileType);
  }
}
