/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { STAFF_API_ENDPOINTS } from './app.endpoints';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  staffApiURL = '';
  baseUrl = STAFF_API_ENDPOINTS.metadata;
  constructor(private http: HttpClient) {
    this.staffApiURL = environment.rootApiUrl + environment.staffApiPrefix;
  }

  ///////////// get document meta data detail////////

  detail(docId: number) {
    return this.http.get(`${this.staffApiURL}${this.baseUrl.default}`.replace('{id}', docId.toString())).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  ///////////// search collection based on keyword ////////

  search(searchCollection: string, searchField: string, query: string) {
    return this.http.get(`${this.staffApiURL}${this.baseUrl.search}`.replace('{collection}', searchCollection).replace('{searchField}', searchField).replace('{query}', query)).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

    ///////////// update metadata ////////

  edit(docId: number, payload: any,type:string) {
       payload.submittedType = type;
      return this.http.put(`${this.staffApiURL}${this.baseUrl.edit}`.replace('{id}', docId.toString()),payload).pipe(
        map((e: any) => e),
        catchError(this.handleError)
      );
  }

  ////////////// add new Data in field //////////////

  addMetadataFieldData(payload:any) {
    return this.http.post(`${this.staffApiURL}${this.baseUrl.addNewFieldData}`,payload).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  } 

  handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;
    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }
    return throwError(() => transformedError);
  }
}
