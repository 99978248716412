<section class="digitization-request-form">
    <div class="container reg-container">
       <div class="row d-flex">
          <!-- Column 1 - Static Content -->
          <div class="col-md-5 column-1">
             <div class="content fw-normal text-justify">
                <!-- Hide breadcrumbs in mobile view -->
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                   <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                </div>
                <h5 class="fw-bold">{{'pdl-website.forms.labels.digitizationRequest.heading' | translate }}</h5>
                <div class="static-txt"
                   [innerHtml]="'pdl-website.forms.labels.digitizationRequest.leftColText' | translate"></div>
             </div>
          </div>
          <!-- Column 2 - ditization Form -->
          <div class="col-md-7 column-2">
             <div class="right-section mb-5">
                <div class="top-content">
                   <div class="header-title" #moveToTop tabindex="-1">
                      <!-- Show breadcrumbs in mobile view -->
                      <div
                         class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                         <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                      </div>
                      <div class="title-txt mt-4">
                         <h5 class="fw-bolder">{{'pdl-website.forms.labels.digitizationRequest.requestFormTitle'
                            |
                            translate }}
                         </h5>
                      </div>
                   </div>
                   <div class="header-body">
                      <p class="fw-light"
                         [innerHtml]="'pdl-website.forms.labels.digitizationRequest.rightColText' | translate">
                      </p>
                   </div>
                </div>
                <form [formGroup]="digitizationRequestForm" (ngSubmit)="onSubmit()" autocomplete="off">
                <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                <div class="form-section2">
                   <p class="fw-bold">{{'pdl-website.forms.labels.digitizationRequest.informationText' |
                      translate }}
                   </p>
                   <!---- status -->
                   <div class="row">
                      <div class="col-md-12 mt-1">
                         <div>
                            <label for="name"
                               class="form-label reqd">{{'pdl-website.forms.labels.digitizationRequest.status'
                            | translate }}</label>
                            <div class="d-flex">
                               <div class="form-check w-25 mb-0">
                                  <input class="form-check-input" type="radio" formControlName="type"
                                     [value]="1" name="type" id="flexRadioDefault10">
                                  <label class="form-check-label" for="flexRadioDefault10">
                                  Author
                                  </label>
                               </div>
                               <div class="form-check w-25 mb-0">
                                  <input class="form-check-input" type="radio" formControlName="type"
                                     [value]="2" name="type" id="flexRadioDefault11">
                                  <label class="form-check-label" for="flexRadioDefault11">
                                  Publisher
                                  </label>
                               </div>
                               <div class="form-check w-25 mb-0">
                                  <input class="form-check-input" type="radio" formControlName="type"
                                     [value]="3" name="type" id="flexRadioDefault12">
                                  <label class="form-check-label" for="flexRadioDefault12">
                                  Custodian
                                  </label>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <!-- name,email -->
                   <div class="row">
                      <div class="col-md-6">
                         <div class="d-flex">
                            <label for="name" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['name'].invalid && digitizationRequestForm.controls['name'].touched}">{{'pdl-website.forms.labels.digitizationRequest.name'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['name'].invalid &&
                            digitizationRequestForm.controls['name'].touched) {
                            <ng-container
                               *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.fullname' | translate }"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['name'].valid &&
                            digitizationRequestForm.controls['name'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="text" autocomplete="off" id="name" class="form-control"
                            formControlName="name"
                            [ngClass]="{'border-danger': digitizationRequestForm.controls['name'].invalid && digitizationRequestForm.controls['name'].touched}"
                            panjabDigiLibTrimmedText>
                      </div>
                      <div class="col-md-6">
                         <div class="d-flex">
                            <label for="email" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['email'].invalid && digitizationRequestForm.controls['email'].touched}">{{'pdl-website.forms.labels.digitizationRequest.email'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['email'].touched &&
                            digitizationRequestForm.controls['email'].errors &&
                            (digitizationRequestForm.controls['email']['errors']['required'] ||
                            digitizationRequestForm.controls['email']['errors']['invalidField'])) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @else if(digitizationRequestForm.controls['email'].touched &&
                            digitizationRequestForm.controls['email'].errors &&
                            digitizationRequestForm.controls['email']['errors']['pattern']) {
                            <ng-container
                               *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidEmail' | translate }"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['email'].valid &&
                            digitizationRequestForm.controls['email'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="email" autocomplete="off" #emailInput class="form-control"
                         formControlName="email" id="email"
                         [ngClass]="{'border-danger': digitizationRequestForm.controls['email'].invalid && digitizationRequestForm.controls['email'].touched}"
                         panjabDigiLibTrimmedText>
                      </div>
                   </div>
                   <!--------copyright ----->
                   <div class="row">
                      <div class="col-md-12">
                         <div class="d-flex">
                            <label for="copyright" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['copyright'].invalid && digitizationRequestForm.controls['copyright'].touched}">{{'pdl-website.forms.labels.digitizationRequest.copyright'
                            | translate }}</label>
                            <span class="flex-grow-1 ms-1 grey-color">{{'pdl-website.forms.labels.digitizationRequest.copyrightAdditionalText'
                                | translate }}</span>
                            @if(digitizationRequestForm.controls['copyright'].touched &&
                            digitizationRequestForm.controls['copyright'].invalid &&
                            !digitizationRequestForm.controls['copyright'].hasError('commaSeparated')) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['copyright'].hasError('commaSeparated')) {
                            <ng-container
                               *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.labels.digitizationRequest.commaError' | translate }"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['copyright'].valid &&
                            digitizationRequestForm.controls['copyright'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="text" autocomplete="off" id="copyright" class="form-control"
                            formControlName="copyright"
                            [ngClass]="{'border-danger': digitizationRequestForm.controls['copyright'].invalid && digitizationRequestForm.controls['copyright'].touched}">
                      </div>
                   </div>
                   <!-- documents count-->
                   <div class="row">
                      <div class="col-md-12">
                         <div class="d-flex">
                            <label for="documentCount" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['documentCount'].invalid && digitizationRequestForm.controls['documentCount'].touched}">{{'pdl-website.forms.labels.digitizationRequest.documentCount'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['documentCount'].touched &&
                            digitizationRequestForm.controls['documentCount'].errors &&
                            (digitizationRequestForm.controls['documentCount']['errors']['required'] ||
                            digitizationRequestForm.controls['documentCount']['errors']['invalidField'])) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['documentCount'].valid &&
                            digitizationRequestForm.controls['documentCount'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="text" autocomplete="off" id="documentCount" [maxLength]="5"
                         [minLength]="1" #phoneInput panjabDigiLibNumberonly class="form-control"
                         formControlName="documentCount"
                         [ngClass]="{'border-danger': digitizationRequestForm.controls['documentCount'].invalid && digitizationRequestForm.controls['documentCount'].touched}">
                      </div>
                   </div>
                   <!-- address-->
                   <div class="row">
                      <div class="col-md-12">
                         <div class="d-flex">
                            <label for="address" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['address'].invalid && digitizationRequestForm.controls['address'].touched}">{{'pdl-website.forms.labels.digitizationRequest.address'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['address'].touched &&
                            digitizationRequestForm.controls['address'].invalid ) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['address'].valid &&
                            digitizationRequestForm.controls['address'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="text" autocomplete="off" id="address" class="form-control"
                            formControlName="address"
                            [ngClass]="{'border-danger': digitizationRequestForm.controls['address'].invalid && digitizationRequestForm.controls['address'].touched}">
                      </div>
                   </div>
                   <!--- city, state -->
                   <div class="row">
                      <div class="col-md-6">
                         <div class="d-flex">
                            <label for="city" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['city'].invalid && digitizationRequestForm.controls['city'].touched}">{{'pdl-website.forms.labels.digitizationRequest.city'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['city'].invalid &&
                            digitizationRequestForm.controls['city'].touched) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['city'].valid &&
                            digitizationRequestForm.controls['city'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="text" autocomplete="off" id="city" class="form-control"
                            formControlName="city"
                            [ngClass]="{'border-danger': digitizationRequestForm.controls['city'].invalid && digitizationRequestForm.controls['city'].touched}"
                            panjabDigiLibTrimmedText>
                      </div>
                      <div class="col-md-6">
                         <div class="d-flex">
                            <label for="state" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['state'].invalid && digitizationRequestForm.controls['state'].touched}">{{'pdl-website.forms.labels.digitizationRequest.state'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['state'].invalid &&
                            digitizationRequestForm.controls['state'].touched) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['state'].valid &&
                            digitizationRequestForm.controls['state'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="text" autocomplete="off" id="state" class="form-control"
                            formControlName="state"
                            [ngClass]="{'border-danger': digitizationRequestForm.controls['state'].invalid && digitizationRequestForm.controls['state'].touched}"
                            panjabDigiLibTrimmedText>
                      </div>
                   </div>
                   <!-- phone, country -->
                   <div class="row">
                      <div class="col-md-6">
                         <div class="d-flex">
                            <label for="country" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['country'].invalid && digitizationRequestForm.controls['country'].touched}">{{'pdl-website.forms.labels.digitizationRequest.country'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['country'].invalid &&
                            digitizationRequestForm.controls['country'].touched) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['country'].valid &&
                            digitizationRequestForm.controls['country'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <select class="form-select" formControlName="country" id="country"
                            [ngClass]="{'border-danger': digitizationRequestForm.controls['country'].invalid && digitizationRequestForm.controls['country'].touched, 'select_option': digitizationRequestForm.controls['country'] === null || digitizationRequestForm.controls['country'].invalid}">
                            <option class="select_option" [ngValue]="null" disabled>Select</option>
                            @for(country of countryList$ | async; track country.CountryID) {
                            <option [ngValue]="country?.CountryID">{{country?.Name}}</option>
                            }
                         </select>
                      </div>
                      <div class="col-md-6">
                         <div class="d-flex">
                            <label for="phone" class="form-label reqd"
                               [ngClass]="{'text-danger': digitizationRequestForm.controls['phone'].invalid && digitizationRequestForm.controls['phone'].touched}">{{'pdl-website.forms.labels.contactUsForm.phone'
                            | translate }}</label>
                            <span class="flex-grow-1"></span>
                            @if(digitizationRequestForm.controls['phone'].touched &&
                            digitizationRequestForm.controls['phone'].errors &&
                            (digitizationRequestForm.controls['phone']['errors']['required'] ||
                            digitizationRequestForm.controls['phone']['errors']['invalidField'])) {
                            <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                            }
                            @else if(digitizationRequestForm.controls['phone'].touched &&
                            digitizationRequestForm.controls['phone'].errors &&
                            digitizationRequestForm.controls['phone']['errors']) {
                            <ng-container
                               *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidPhonenum' | translate }"></ng-container>
                            }
                            @if(digitizationRequestForm.controls['phone'].valid &&
                            digitizationRequestForm.controls['phone'].touched) {
                            <ng-container *ngTemplateOutlet="validFld"></ng-container>
                            }
                         </div>
                         <input type="text" autocomplete="off" [maxLength]="12" [minLength]="10" #phoneInput
                         class="form-control" formControlName="phone" id="phone"
                         [ngClass]="{'border-danger': digitizationRequestForm.controls['phone'].invalid && digitizationRequestForm.controls['phone'].touched}"
                         panjabDigiLibNumberonly>
                      </div>
                   </div>
                   <!-- recaptch -->
                   <div class="row mt-4">
                      <div class="col-12">
                         <re-captcha #captchaRef (resolved)="resolveCaptcha($event)" formControlNme="cpt"
                         siteKey="{{sitekey}}" [size]="isMobile ? 'compact' : 'normal'"></re-captcha>
                      </div>
                   </div>
                   <!-- /recaptch -->
                   <div class="row">
                      <div class="col-6 text-end">
                         <button type="reset" [disabled]="isSubmitted"
                            class="btn btn-secondary btn__secondary">{{'pdl-website.forms.labels.resetBtn' |
                         translate }}</button>
                      </div>
                      <div class="col-6 text-start">
                         @if(isSubmitted) {
                         <panjab-digi-lib-spinner-button
                            [name]="'pdl-shared.loaderState.pleaseWait' | translate"></panjab-digi-lib-spinner-button>
                         } @else {
                         <button [disabled]="digitizationRequestForm.invalid" type="submit"
                            class="btn btn-primary btn__primary">
                         {{'pdl-website.forms.labels.contactUsForm.submit' | translate }}
                         </button>
                         }
                      </div>
                   </div>
                </div>
                </form>
             </div>
          </div>
       </div>
    </div>
    <!-- Template - required field error -->
    <ng-template #requiredError>
       <span class="text-danger">
       <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
       </span>
    </ng-template>
    <!-- Template - invalid field error -->
    <ng-template #invalidError let-msg="msg">
       <span class="text-danger">
          <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
          <ng-template #tipContent>{{msg}}</ng-template>
       </span>
    </ng-template>
    <!-- Template - valid field -->
    <ng-template #validFld>
       <span class="text-success">
       <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
       </span>
    </ng-template>
 </section>