import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { Store } from '@ngrx/store';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { UserService } from '../services/user.service';
import { environment } from '@panjab-digi-lib/shared';
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(
    private store: Store, 
    private toastService: ToastService, 
    private router: Router, 
    private authService: AuthenticationService, 
    private localStorage : LocalStorageService,
    private socialAuthService: SocialAuthService,
    private userService: UserService
  ) { }

  private terminateUserSession(err:any) {
    if(this.localStorage.get('socialProvider')) {
      this.socialAuthService.signOut().then(() => {
        // logged out
      });
    }
    this.userService.clearUserSession();
    // this.store.dispatch(AuthActions.tokenExpired())
    this.toastService.showError('Your session has expired! Please login again')
    this.router.navigateByUrl(`/login-user`);
    return of(err.message);
  }
  
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if(err.status === 403) {
      this.terminateUserSession(err);
    }
    return throwError(() => err);
  }

  private _setHeaders(token:string) {
    const customHeaders = {
      authorization: `Bearer ${token}`
    };
    return {
      headers: new HttpHeaders(customHeaders)
    };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    
    const token = this.localStorage.get('webToken') || '';
    let headerOptions = null;
    // Function to escape special characters in URL strings for regex
    function escapeRegExp(url: string): string {
      return url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    // Construct patterns dynamically using environment variables
    const excludedUrlPatterns = [
      new RegExp(`^${escapeRegExp(environment.getIPAddressEndpoint)}$`), // Static URL
      new RegExp(`^${escapeRegExp(environment.geoAPIEndpoint).replace('\\{IPAddress\\}', '[^?]+')}`), // Replace placeholder
      new RegExp(`^${escapeRegExp(environment.countryinfoapi).replace('\\{countryName\\}', '[^?]+')}`) // Replace placeholder
    ];

    // Normalize the URL (remove trailing slashes and split off query parameters)
    const cleanUrl = req.url.split('?')[0].replace(/\/$/, '');

    // Check if the URL matches any pattern
    if (!excludedUrlPatterns.some(pattern => pattern.test(req.url))) {
      headerOptions = token ? this._setHeaders(token) : null;
      if (headerOptions) {
        req = req.clone(headerOptions);
      }
    }

    
    return next.handle(req).pipe(
      catchError((err:HttpErrorResponse) => {
        if (err.status === 401) {
          // TODO: refresh token with social login
          // generate new token by refresh token
          const refreshToken = this.localStorage.get('webRefreshtoken'); 
          if(refreshToken) {
            return this.authService.getAccessToken(refreshToken).pipe(
              switchMap((res) => {
                // set new access token in the localstorage
                // retry the original request with new access token
                if (res.body.token) {
                  this.localStorage.set('webToken', res.body.token);
                  const headerOptions = this._setHeaders(res.body.token);
                  const authRequest = req.clone(headerOptions);
                  return next.handle(authRequest);
                } else {
                  return this.terminateUserSession(err);
                }
              }),
              catchError((error: any) => this.terminateUserSession(error))
            )
          }
        } 
        return this.handleAuthError(err);
      })
    );
  }
}
