/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebNewsAndArticlesActions } from 'shared/src/store/actions/index';
import {
  catchError,
  exhaustMap,
  map,
  of
} from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';
import { NewsAndArticlesService } from 'shared/src/services/website/news-and-articles/news-and-articles.service';

@Injectable()
export class WebNewsAndArticlesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: NewsAndArticlesService,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}

  //////////////// get news and articles list ////////////
  LoadNewsAndArticlesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebNewsAndArticlesActions.LoadNewsAndArticles),
      exhaustMap((action) => {
        return this.service.loadNewsAndArticles(action?.payload).pipe(
          map((response) => {
            return WebNewsAndArticlesActions.LoadNewsAndArticlesSuccess({
              recordsList: response?.recordsList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              WebNewsAndArticlesActions.LoadNewsAndArticlesError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// get article details by id ////////////
  LoadNewsAndArticlesById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebNewsAndArticlesActions.LoadNewsAndArticlesDetails),
      exhaustMap((action) => {
        return this.service.getDetail(action?.payload?.subjectId, action?.payload?.id).pipe(
          map(({articleDetails, relatedArticles}) => {
            return WebNewsAndArticlesActions.LoadNewsAndArticlesDetailsSuccess({
              record: {
                articleDetails,
                relatedArticles
              }
            });
          }),
          catchError((error) => {
            return of(
              WebNewsAndArticlesActions.LoadNewsAndArticlesDetailsError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );
}
