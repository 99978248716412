<div class="container adopted__books">
    @if((adoptedBooks$ | async)!.length && !(isLoading$ | async)) {
    @for(adopter of adoptedBooks$ | async; track adopter.AdoptByID) {
    <div class="card border-0">
        <div class="row p-4">
            <div class="col-12 col-sm-3 col-md-2 text-center col__one">
                <img src="{{imageBaseUrl}}{{adopter.Document?.Mst_Category?.Name}}/{{adopter.Document?.AccessionNo}}/Thumbnails/{{adopter.Document?.AccessionNo}}_90.jpg"
                    (error)="handleImageError($event)" class="img-fluid rounded" alt="{{adopter.Document?.Title}}">
            </div>
            <div class="col-12 col-sm-9 col-md-10 mt-3 mt-sm-0 col__two">
                <div class="card-body p-0">
                    <div class="d-flex mb-2 mb-md-3">
                        <h5 class="card-title book__title fw-bold mb-0"
                            [ngClass]="{'inaccessible__book': adopter.Document?.Status === docStatus.WEB_READY}">
                            @if(adopter.Document?.Status === docStatus.WEB_READY) {
                            {{adopter.Document?.Title | titlecase}}
                            } @else {
                            <a href="javascript:void(null)"> {{adopter.Document?.Title | titlecase}} </a>
                            }
                        </h5>
                        @if(adopter.Document?.Status === docStatus.WEB_READY) {
                        <img class="img-responsive ps-2 cursor-pointer" [autoClose]="'outside'" [ngbPopover]="bookTitle"
                            popoverClass="tooltip-custom-class" src="../../../../assets/images/icons/info-ico.svg"
                            alt="info" />
                        <ng-template #bookTitle [innerHTML]>{{'pdl-website.userProfile.offlineDocMessage' | translate }}
                            Please <a class="text-decoration-underline" href='/contact-us' target="_blank">Contact
                                Us</a> for more details.</ng-template>
                        }
                    </div>
                    <div class="d-flex align-items-center mb-2 mb-md-3 book__meta">
                        <span class="pe-3">
                            <div class="card-text book__category px-3">{{adopter.Document?.Mst_Category?.Name | uppercase}}
                            </div>
                        </span>
                        @if(adopter.Document?.AuthorInfo?.length) {
                        <div class="card-text book__author">
                            <img src="../../../../assets/images/icons/pen-ico.svg" alt="pen-icon" />
                            <span class="ps-1 author__name">
                                @for(author of adopter.Document?.AuthorInfo; track author.AuthorID ) {
                                <span>{{author.FullName | uppercase}}</span>
                                }
                            </span>
                        </div>
                        }
                        @if(adopter.DateAdopted) {
                        <div class="card-text adopted__date">
                            <img width="20" height="20" src="../../../../assets/images/icons/calendar-ico.svg"
                                alt="calendar-icon" />
                            <span class="ps-1">{{adopter.DateAdopted | date:'dd MMMM YYYY' | uppercase}}</span>
                        </div>
                        }
                    </div>
                    <p class="card-text budget__amt">{{'pdl-shared.content.budget' | translate}}: ₹
                        {{adopter.AdoptedPrice}}</p>
                    <p class="card-text book__desc">{{adopter.WebDocument?.Description}}</p>
                </div>
            </div>
        </div>
    </div>
    }
    } @else if(isLoading$| async) {
    <div class="text-center">
        <panjab-digi-lib-spinner></panjab-digi-lib-spinner>
    </div>
    } @else {
    <div class="text-center">No Book Adopted yet</div>
    }
</div>
@if(totalRecords > pageSize) {
<div class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4">
    <ngb-pagination class="adoptedbooks__pagination" [collectionSize]="totalRecords" [(page)]="pageNo"
        [pageSize]="pageSize" [rotate]="true" [ellipses]="true" (pageChange)="loadAdoptedBooks()" />
</div>
}
