/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  BreadcrumbsComponent,
  environment,
  SharedCommonService,
} from '@panjab-digi-lib/shared';
import { SocialLoginComponent } from '../common/social-login/social-login.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { forkJoin, Observable, Subscription } from 'rxjs';
import {
  NgbDateStruct,
  NgbModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RecaptchaComponent,
} from 'ng-recaptcha';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { TrimmedTextDirective } from '../../../common/directives/trimmed-text.directive';
import { NumberonlyDirective } from '../../../common/directives/numberonly.directive';
import { UploadDocumentService } from 'shared/src/services/website/upload-document/upload-document.service';
import { CategoryDropdownList } from 'shared/src/interfaces/common.interface';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiSelectComponent } from 'shared/src/lib/components/multi-select/multi-select.component';
import {
  DocumentAttachment,
  SelectedFile,
  UploadedFile,
} from 'shared/src/interfaces/staff/upload.interface';
import { UploadFileComponent } from 'shared/src/lib/components/upload-file/upload-file.component';
import { ToastService } from 'shared/src/services/toast/toast.service';
@Component({
  selector: 'panjab-digi-lib-upload-document',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SocialLoginComponent,
    BreadcrumbsComponent,
    ReactiveFormsModule,
    NgbTooltipModule,
    RouterLink,
    AlertsComponent,
    SpinnerButtonComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    TrimmedTextDirective,
    NumberonlyDirective,
    NgbModule,
    NgSelectModule,
    MultiSelectComponent,
    UploadFileComponent,
    FormsModule,
  ],
  providers: [DatePipe],
  templateUrl: './upload-document.component.html',
  styleUrl: './upload-document.component.scss',
})
export class UploadDocumentComponent implements OnInit, OnDestroy {
  contactReqForm!: FormGroup;
  successMsg = false;
  isSubmitted = false;
  sitekey = environment.recaptcha.siteKey;
  isMobile = false;
  subscription = new Subscription();
  minDate!: NgbDateStruct;
  formattedStartDate: string = '';
  formattedEndDate: string = '';
  reasonList!: { ID: number; Reason: string }[];
  categoryList: CategoryDropdownList[] = [];
  documentAttachments: DocumentAttachment[] = [];
  selectedDocumentAttachments: DocumentAttachment[] = [];
  formConfig: any;
  dropdownOptions: any = {};
  form!: FormGroup;
  uploadCompletedTrigger: boolean = false;
  selectedFilesMap!: { [key: string]: SelectedFile[] };
  uploadedFiles!: { [key: string]: UploadedFile };
  selectedTypes = [];
  countryList = [{ CountryID: 1, Name: 'India' }];
  isUpLoading: boolean = false;
  docId: string = '';
  isButtonDisabled = true;
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent;
  @ViewChild('moveToTop', { static: false }) moveToTop!: ElementRef;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.isUpLoading) {
      // Show confirmation when user tries to reload or close the tab
      $event.returnValue = true; // Display the default browser prompt
    }
  }
  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private service: UploadDocumentService,
    private sharedCommonService: SharedCommonService,
    readonly toastService: ToastService,
    private translate: TranslateService,
    private readonly router: Router
  ) {
    this.getCategoryList();
    this.initForm();
  }

  // Implement the canDeactivate method
  canDeactivate(): boolean {
    if (this.isUpLoading) {
      const message = this.translate.instant(
        'pdl-shared.content.uploadInProgress'
      );
      return confirm(
        message + ' ' + this.translate.instant('pdl-shared.content.sureToLeave')
      );
    }
    return true;
  }

  ngOnInit() {
    // change recaptcha size when it is on mobile view
    if (window.innerWidth < 370) {
      this.isMobile = true;
    }
  }
  initForm() {
    this.form = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(1)]],
      categoryID: [null, [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(1000)]],
      cpt: [null, Validators.required],
      documentAttachments: [null, Validators.required],
      preCode: [],
    });
  }

  getCategoryList() {
    this.subscription.add(
      this.sharedCommonService.getCategories().subscribe({
        next: (response) => {
          this.categoryList = response;
        },
        error: (error) => {
          console.log('error', error);
          this.categoryList = [];
        },
      })
    );
  }

  fetchUploadTypes(event: Event) {
    const value = (event.target as HTMLSelectElement).value;
    this.form.reset();
    this.selectedFilesMap = {};
    this.selectedDocumentAttachments = [];
    const allowedKeys = [
      'title',
      'categoryID',
      'description',
      'cpt',
      'documentAttachments',
      'preCode',
    ];

    Object.keys(this.form.controls).forEach((key) => {
      if (!allowedKeys.includes(key)) {
        this.form.removeControl(key);
      }
    });

    this.form.patchValue({
      categoryID: value,
    });

    this.fetchMetaDataFields(value);
    this.subscription.add(
      this.service.uploadTypes(value).subscribe({
        next: (response) => {
          this.documentAttachments = response;
          this.setupUploadConfig();
        },
        error: () => {
          this.documentAttachments = [];
        },
      })
    );
  }

  fetchMetaDataFields(value: string) {
    this.formConfig = null;
    this.subscription.add(
      this.service.metaDataFields(value).subscribe({
        next: (response) => {
          this.formConfig = response;
          this.createControls();
        },
        error: (error) => {
          console.log('metaFields', error);
        },
      })
    );
  }

  setupUploadConfig(): void {
    this.uploadedFiles = this.documentAttachments.reduce(
      (acc: { [key: string]: UploadedFile }, item) => {
        acc[item.Path] = { files: [], continuationToken: '' };
        return acc;
      },
      {}
    );

    this.selectedFilesMap = this.documentAttachments.reduce(
      (acc: { [key: string]: SelectedFile[] }, item) => {
        acc[item.Path] = [];
        return acc;
      },
      {}
    );
  }

  createControls() {
    for (const key in this.formConfig) {
      const field = this.formConfig[key];
      if (field.Type === 'Dropdown') {
        this.dropdownOptions[key] = field.Data;
      }
      const controlConfig = this.getControlConfig(field);
      this.form.addControl(
        key,
        this.fb.control(controlConfig.value, controlConfig.validators)
      );
    }
  }

  getControlConfig(field: any) {
    const isRequired = field.IsRequired === 1;
    const validators = isRequired ? [Validators.required] : [];

    if (field.Type === 'Dropdown') {
      const selectedOptions = field.Data.filter((item: any) => item.isSelected);
      const formattedValue = selectedOptions
        .map((item: any) => (item.id ? item.id : item.value))
        .join(',');
      return { value: formattedValue, validators };
    } else if (field.Type === 'Text') {
      return { value: field.Data || '', validators };
    }

    return { value: '', validators };
  }

  getFormKeys(): string[] {
    return Object.keys(this.formConfig);
  }

  getOptions(key: string): any[] {
    return this.dropdownOptions[key] || [];
  }

  checkAttachments() {
    const hasEmptyAttachment = this.selectedDocumentAttachments.some(
      (attachment) =>
        !this.selectedFilesMap[attachment.Path] ||
        this.selectedFilesMap[attachment.Path].length === 0
    );

    if (hasEmptyAttachment) {
      this.isButtonDisabled = true; // Disable the button if there's an error
    } else {
      this.isButtonDisabled = false; // Enable the button if no errors
    }
  }

  onFilesSelected(selectedFiles: SelectedFile[], uploadType: string) {
    this.selectedFilesMap[uploadType] = selectedFiles;
    // show/hide uploaded file in case of single upload
    this.documentAttachments.forEach((attachmentInfo) => {
      const hideUploadedFile = this.selectedFilesMap[uploadType].length !== 0;
      if (attachmentInfo.Path == uploadType && !attachmentInfo.isMultiple) {
        if (this.uploadedFiles[uploadType].files.length > 0) {
          this.uploadedFiles[uploadType].files[0].isHidden = hideUploadedFile;
        }
      }
    });
    this.checkAttachments();
  }

  updateDocumentTypeSelection() {
    const selectedDocumentTypes =
      this.form.get('documentAttachments')?.value || [];

    this.selectedDocumentAttachments = selectedDocumentTypes.reduce(
      (acc: DocumentAttachment[], documentType: number) => {
        const document = this.documentAttachments.find(
          (doc) => doc.ConfigID === documentType
        );
        if (
          document &&
          !acc.some((selectedDoc) => selectedDoc.ConfigID === documentType)
        ) {
          acc.push(document);
        }
        return acc;
      },
      []
    );
    this.checkAttachments();
  }

  onInputChange(event: any, key: string) {
    const value = event.target.value;
    const decimalRegex = /^\d{0,4}(\.\d{0,2})?$/;
    const numberRegex = /^\d{0,4}$/;
    if (this.formConfig[key].TextType === 'Decimal') {
      if (!decimalRegex.test(value)) {
        event.target.value = value.slice(0, -1);
      }
    } else if (this.formConfig[key].TextType === 'Number') {
      if (!numberRegex.test(value)) {
        event.target.value = value.slice(0, -1);
      }
    }
  }

  resetForm() {
    this.form.reset();
    this.clearSelectedData();
    this.moveToFirstElement();
    this.resetReCaptcha();
  }

  clearSelectedData() {
    this.formConfig = null;
    this.selectedDocumentAttachments = [];
    this.selectedFilesMap = {};
  }

  moveToFirstElement(): void {
    this.renderer.selectRootElement(this.moveToTop.nativeElement).focus();
  }

  resolveCaptcha(captchaResponse: string | null) {
    if (captchaResponse) {
      this.form.patchValue({ cpt: captchaResponse });
    } else {
      this.form.controls['cpt'].setErrors({ invalid: true });
    }
  }

  resetReCaptcha() {
    this.captchaRef.reset();
  }

  onSubmit() {
    this.isSubmitted = true;
    const preCode = this.categoryList.find(
      (item) => item.categoryID === parseInt(this.form.get('categoryID')?.value)
    )?.PreCode;
    this.form.patchValue({
      preCode: preCode,
    });
    if (this.form.valid) {
      const formValues = this.form.value;
      delete this.form.value.documentAttachments;
      for (const key in formValues) {
        if (formValues.hasOwnProperty(key)) {
          const controlValue = formValues[key];
          const textType = this.formConfig[key]?.TextType;

          if (textType === 'Decimal') {
            formValues[key] = controlValue ? parseFloat(controlValue) : 0;
          }
        }
      }
      this.subscription.add(
        this.service.add(formValues).subscribe({
          next: (response) => {
            this.docId = response.docId;
            this.uploadData();
          },
          error: (error) => {
            this.toastService.showError(
              error?.message || String(APP_CONSTANTS.defaultErrMsg)
            );
          },
        })
      );
    }
  }

  uploadData() {
    const uploadObservables: Observable<any>[] = [];

    Object.keys(this.selectedFilesMap).forEach((uploadType) => {
      const files = this.selectedFilesMap[uploadType].reduce(
        (acc: File[], data) => {
          if (!data.isUploaded) {
            data.isUploading = true;
            acc.push(data.file);
          }
          return acc;
        },
        []
      );
      if (files.length > 0) {
        this.isUpLoading = true;
        // Collect observables from each uploadFiles call
        uploadObservables.push(this.uploadFiles(uploadType, files));
      }
    });

    // Use forkJoin to wait for all API calls to complete
    forkJoin(uploadObservables).subscribe({
      next: () => {
        this.isUpLoading = false;
        this.toastService.showSuccess('Document submitted successfully!');
        this.router.navigate(['/user/upload-history']);
      },
      error: (error) => {
        console.error('Error during file upload', error);
        this.selectedFilesMap[error.details.uploadType].forEach(
          (selectedFile) => {
            selectedFile.uploadSuccess = false;
            selectedFile.uploadError = true;
            selectedFile.isUploading = false;
          }
        );
        this.toastService.showError(error.message);
        this.isUpLoading = false;
      },
    });
  }

  uploadFiles(uploadType: string, files: File[]) {
    const formData = new FormData();
    formData.append('DocID', String(this.docId));
    files.forEach((file) => {
      formData.append('uploadFiles', file, file.name);
    });
    formData.append('uploadType', uploadType);
    return this.service.uploadFiles(formData);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
