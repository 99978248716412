/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { map } from 'rxjs';
import {
  GenericPayload,
  GenericReponse,
} from 'shared/src/interfaces/common.interface';

@Injectable({
  providedIn: 'root',
})
export class UploadDocumentService {
  appURL = '';
  endPointUrl = WEB_API_ENDPOINTS.uploadDocument;
  constructor(private http: HttpClient) {
    this.appURL = environment.rootApiUrl + environment.appApiPrefix;
  }

  ////////////////////// document upload type list /////////////////////
  uploadTypes(id: string) {
    const url = `${this.appURL}${this.endPointUrl.uploadTypes}`.replace(
      '{id}',
      id
    );
    return this.http
      .get<GenericReponse>(url)
      .pipe(map((e: GenericReponse) => e?.body));
  }
  ////////////////////// document upload type list /////////////////////
  metaDataFields(id: string) {
    const url = `${this.appURL}${this.endPointUrl.metaDataFields}`.replace(
      '{id}',
      id
    );
    return this.http.get<GenericReponse>(url).pipe(map((e: any) => e?.body));
  }
  ////////////////////// submit upload form with metadata fields /////////////////////
  add(payload: any) {
    const url = `${this.appURL}${this.endPointUrl.add}`;
    return this.http.post(url, payload).pipe(map((e: any) => e?.body));
  }
  ///////////// search collection based on keyword ////////
  search(searchCollection: string, searchField: string, query: string) {
    return this.http
      .get(
        `${this.appURL}${this.endPointUrl.search}`
          .replace('{collection}', searchCollection)
          .replace('{searchField}', searchField)
          .replace('{query}', query)
      )
      .pipe(map((e: any) => e.body));
  }
  ////////////// add new Data in field //////////////
  addMetadataFieldData(payload: any) {
    return this.http
      .post(`${this.appURL}${this.endPointUrl.addNewFieldData}`, payload)
      .pipe(map((e: any) => e));
  }
  ////////////// upload data //////////////
  uploadFiles(formData: FormData) {
    return this.http
      .post(`${this.appURL}${this.endPointUrl.default}`, formData)
      .pipe(map((e: any) => e.body));
  }

  ////////////////////upload history /////////////
  uploadHistory(payload:GenericPayload) {
    const url = `${this.appURL}${this.endPointUrl.list}`.replace('{pageNumber}', payload.pageNumber.toString())
          .replace('{pageSize}', payload.pageSize.toString());
    return this.http
      .get(url)
      .pipe(map((e: any) => e.body));
  }
}
