import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromWebNewsAndArticles from '../../reducers/website/web-news-and-articles.reducer';

export const selectState =
  createFeatureSelector<fromWebNewsAndArticles.State>('webNewsAndArticles');

export const loadNewsAndArticlesList = createSelector(selectState, (state) => ({
  recordsList: fromWebNewsAndArticles.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber
}));

export const getNewsAndArticlesDetails = createSelector(
  selectState, 
  (state) => ({body :state.articleDetails}));

export const getRelatedNewsAndArticles = createSelector(
  selectState, 
  (state) => ({body :state.relatedArticles}));

export const getNewsAndArticlesById = (id: number) => createSelector(
  selectState,
  (state) => ({body :state.entities[id]})
)

export const getNewsAndArticlesOfSameType = (id: number) => createSelector(
  selectState,
  (state) => {
    const values = Object.values(state.entities);
    return {
      body: values.filter(v => v?.ID != id)
    }
  }
)