/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { map } from 'rxjs';
import { ContactUsRequestPayload } from 'shared/src/interfaces/contact-us.interface';
import { GenericReponse } from 'shared/src/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  appURL = '';
  endPointUrl = WEB_API_ENDPOINTS.contactUs;
  constructor(private http: HttpClient) {
    this.appURL = environment.rootApiUrl + environment.appApiPrefix;
  }

  ////////////////////// contact us reason list /////////////////////
  reasonList() {
    const url = `${this.appURL}${this.endPointUrl.reason}`
    return this.http.get<{ body: { ID: number, Reason: string }[] }>(url)
      .pipe(map((e: { body: { ID: number, Reason: string }[] }) => e))
  }

  ////////////////////// add contact us request /////////////////////
  request(payload: ContactUsRequestPayload) {
    const url = `${this.appURL}${this.endPointUrl.request}`
    return this.http.post<GenericReponse>(url, payload)
      .pipe(map((e: GenericReponse) => e))
  }
}
