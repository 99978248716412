@if(isLoading) {
<panjab-digi-lib-spinner></panjab-digi-lib-spinner>
}
@if(!isLoading) {
<div class="overflow-x-hidden">
    <section class="top_section">
        <img src="{{imageBaseUrl}}{{itemDetail.ImagePath}}" class="banner_image" alt="event-img" />
        <div class="innerpage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                            <a class="breadcrumb-link font-regular" href="#">
                                <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                                {{'pdl-shared.content.eventsCampaigns.home' | translate}}
                            </a>
                            <span class="chevron-arrow font-regular">
                                <img src="assets/images/icons/arrow-right.svg" alt="icon">
                            </span>
                            <a class="breadcrumb-link font-regular" [routerLink]="'/events-campaigns'">
                                {{'pdl-website.eventsCampaigns.title' | translate }}
                            </a>
                            @if (itemDetail && itemDetail.Title) {
                                <span class="chevron-arrow font-regular">
                                    <img src="assets/images/icons/arrow-right.svg" alt="icon">
                                </span>
                                <a class="breadcrumb-link font-regular" >
                                    <span>{{fixLength(itemDetail.Title, 20)}}</span>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="top_section_bottom">
            <div class="content">
                <p class="heading">
                    {{itemDetail.Title}}
                </p>
                <div class="subheading ">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div>
                            <div>{{itemDetail.Date.startDate | date : 'dd MMMM YYYY'}} -
                                {{itemDetail.Date.endDate
                                |
                                date :
                                'dd MMMM YYYY'}} | {{ '1970-01-01T' + itemDetail.Time.startTime + ':00' | date:
                                'h:mm a'
                                }} -
                                {{
                                '1970-01-01T' + itemDetail.Time.endTime + ':00' | date: 'h:mm a' }}
                            </div>
                            <div class="mb-3">
                                {{itemDetail.Location}}
                            </div>
                            <!-- @if(itemDetail.OtherLocations && itemDetail.OtherLocations.length > 0) {
                            <button class="check_more_locations cursor-pointer">Check
                                {{itemDetail.OtherLocations.length}}
                                More
                                {{itemDetail.OtherLocations.length > 1 ? 'Locations' : 'Location'}} 
                            </button>
                            } -->
                        </div>
                        <!-- <div class="btn_wrapper">
                            <a href="javascript:void(0)"
                                class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.eventsCampaigns.getDirections'
                                | translate }} <img src="assets/images/icons/directions-ico.svg"
                                    alt="direction-icon" class="ps-2">
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-------------------------- google map section  ----------------------->
    <section class="container exhibition_detail mt-5">
        <div class="mapouter mb-5 mt-5 d-flex justify-content-center">
            <div class="gmap_canvas">
                <div #mapContainer style="width: 100%; height: 400px;"></div>
            </div>
        </div>
    </section>
    <div class="tab-content">
        <div id="event-campaign_detail" role="tabpanel" class="tab-pane fade show active">
            <section class="container event-campaign_detail mt-5 desc_content">
                <p class="heading">{{ itemDetail.Title }}</p>
                <div [innerHTML]="sanitizeHTML(itemDetail.Description)"></div>
                <div class="mb-5 mt-5">
                    <panjab-digi-lib-global-divider />
                </div>
            </section>
        </div>
    </div>
</div>
}
