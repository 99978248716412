<div class="row ">
    <section class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="topbar-pdl">
                        <div class="row">
                            <div class="col-lg-8 col-md-3 col-2">
                                <div class="topbar-left d-flex align-items-center justify-content-start">
                                    <a class="btn pdl-toggle-menu p-0" data-bs-toggle="offcanvas"
                                        href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                        <img src="assets/images/icons/hamburger-duotone.svg">
                                    </a>
                                    <ul
                                        class="topbar-list d-flex align-items-center justify-content-center ps-0 mb-0 d-none d-lg-flex">
                                        <li class="me-3">
                                            <a [routerLink]="['/volunteer']" class="me-3">
                                                <img src="assets/images/icons/volunteer-ico.svg">
                                                {{'pdl-website.topbarList1.topbarList1' | translate }}
                                            </a>
                                        </li>
                                        <li class="me-3">
                                            <a href="#" class="me-3">
                                                <img src="assets/images/icons/pdl-guestbook-ico.svg">
                                                {{'pdl-website.topbarList2.topbarList2' | translate }}
                                            </a>
                                        </li>
                                        <li class="me-3">
                                            <a href="#" [routerLink]="['/donate']" class="me-3">
                                                <img src="assets/images/icons/donate-ico.svg">
                                                {{'pdl-website.topbarList3.topbarList3' | translate }}
                                            </a>
                                        </li>
                                        <li class="me-3">
                                            <a href="#" class="me-3">
                                                <img src="assets/images/icons/feedback-ico.svg">
                                                {{'pdl-website.topbarList4.topbarList4' | translate }}
                                            </a>
                                        </li>
                                        <li class="me-3">
                                            <a href="#" [routerLink]="'/contact-us'" class="me-3">
                                                <img src="assets/images/icons/contact-us-ico.svg">
                                                {{'pdl-website.topbarList5.topbarList5' | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-9 col-10">
                                <ul class="topbar-list-right d-flex align-items-center justify-content-end ps-0 mb-0">
                                    <li class="me-3">
                                        <a href="#" class="d-flex align-items-center">
                                            <img src="assets/images/icons/librarian-ico.svg">
                                            {{'pdl-website.topbarRight.topbarrightText' | translate }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="pdl-light-cta d-flex align-items-center sm-font-0" href="#">
                                            {{'pdl-website.topbarAsk.topbarrightAsk' | translate }} <img
                                                src="assets/images/icons/chevron-right-ico.svg">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="offcanvas offcanvas-start top-sidebar" tabindex="-1" id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
        <h6 class="offcanvas-title d-flex align-items-center" id="offcanvasExampleLabel">
            <!-- <img src="assets/images/icons/signin-ico.svg"> Hello Sign in -->
            <div class="website-logo">
                <a href="/" class="d-flex align-items-center text-white text-decoration-none">
                    <img src="assets/images/icons/logo-icon.svg"> <span
                        class="logo-title">{{'pdl-website.headerPage.logoTitle' | translate }}</span>
                </a>
            </div>
        </h6>
        <button type="button" class="btn-close d-flex align-items-center justify-content-center"
            data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="assets/images/icons/close-ico.svg">
        </button>
    </div>
    <div class="offcanvas-body p-0 mt-3 me-3 mb-3">
        @for(key of listKeys; track key) {
        <div class="vertical-nav p-3 mb-2 pt-0">
            <h6 class="heading-dark semibold">{{('pdl-website.' + key +'.titleText') | translate }}</h6>
            <ul class="list-style-none ps-0 mb-0">
                @for(item of sidebarlist[key]; track item){
                <li>
                    <a [href]="'pdl-website.'+item+'.linkhref'
                        | translate ">{{'pdl-website.'+item+'.linkText'
                        | translate }}
                    </a>
                </li>
                }
            </ul>
        </div>
        }
    </div>
</div>
