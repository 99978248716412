<section class="innerpage-banner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                    <a class="breadcrumb-link font-regular" href="#">
                        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                        {{'pdl-shared.content.eventsCampaigns.home' | translate}}
                    </a>
                    <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg" alt="icon">
                    </span>
                    <a class="breadcrumb-link font-regular" [routerLink]="'/events-campaigns'">
                        {{'pdl-website.eventsCampaigns.title' | translate }}
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="page-title">
                    <h1 class="heading-dark semibold mb-0">
                        {{payload.filters ? ('pdl-website.eventsCampaigns.searchFor' | translate)+payload.filters :
                        ('pdl-website.eventsCampaigns.allEventsCampaigns' | translate)}}
                    </h1>
                </div>
            </div>
        </div>

    </div>
</section>
<section class="advancesearch-section section-padding ">
    <div class="container">
        <div class="col mb-3">
            <div class="results-and-filters d-flex align-items-center justify-content-between">
                <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                    @if(totalRecords) {
                    <span class="records__count">{{'pdl-shared.pagination.showing' | translate}}</span>
                    <span class="records__count">{{fromRecords}}</span>
                    <span class="records__count">{{'pdl-shared.pagination.to' | translate}}</span>
                    <span class="records__count">{{toRecords}}</span>
                    <span class="records__count">{{'pdl-shared.pagination.of' | translate}}</span>
                    <span class="records__count">{{totalRecords}}</span>
                    <span class="records__count">{{'pdl-shared.pagination.entries' | translate}}</span>
                    } @else {
                    <span>{{'pdl-website.eventsCampaigns.allEventsCampaigns' | translate}}</span>
                    }
                </div>
                <div class="category-action d-flex align-items-center justify-content-end">
                    <div class="page-filters-dropdown">
                        <div class="dropdown page-filter-dropdown">
                            <a class="page-filter-action btn btn-secondary dropdown-toggle" href="#" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="assets/images/icons/filters-ico.svg" alt="icon">
                                {{'pdl-shared.content.eventsCampaigns.filters' | translate}}
                            </a>

                            <ul class="dropdown-menu">
                                <li>
                                    <div class="d-flex ms-2 mb-1 me-2">
                                        <input class="form-check-input" type="checkbox" id="switchShow"
                                            [checked]="payload.filters && payload.filters.includes('Events')"
                                            (change)="addFilter('Events',$event)">
                                        <span class="ms-2">{{'pdl-website.eventsCampaigns.filters.onlyEvents' |
                                            translate}}</span>
                                    </div>
                                <li>
                                    <div class="d-flex ms-2 mb-1 me-2">
                                        <input class="form-check-input" type="checkbox" id="switchShow"
                                            [checked]="payload.filters && payload.filters.includes('Campaigns')"
                                            (change)="addFilter('Campaigns', $event)">
                                        <span class="ms-2">{{'pdl-website.eventsCampaigns.filters.onlyCampaigns' |
                                            translate}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex ms-2 mb-1 me-2">
                                        <input class="form-check-input" type="checkbox" id="switchShow"
                                            [checked]="payload.filters && payload.filters.includes('Upcoming')"
                                            (change)="addFilter('Upcoming',$event)">
                                        <span class="ms-2">{{'pdl-website.eventsCampaigns.filters.futureEventsCampaigns'
                                            |
                                            translate}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex ms-2 mb-1 me-2">
                                        <panjab-digi-lib-date-range-picker
                                            [givenRange]="{startDate: startDate,endDate: endDate}" [inputBox]="false"
                                            [checkBox]="true" (click)="$event.stopPropagation()"
                                            (selectedRange)="selectedRange($event)"></panjab-digi-lib-date-range-picker>
                                    </div>
                                </li>
                                <li>
                                    <div class="ms-2 mb-1 me-2">
                                        <input class="form-check-input" type="checkbox" id="switchShow"
                                            (change)="toggleLocation($event)">
                                        <span class="ms-2">{{'pdl-shared.content.eventsCampaigns.location' |
                                            translate}}</span>
                                        <div class="d-flex">
                                            @if(showLocationFilter) {
                                            <panjab-digi-lib-google-place-autocomplete [isAllExhibition]="true"
                                                (setAddress)="getAddress($event)"></panjab-digi-lib-google-place-autocomplete>
                                            }
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="list-styles">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#home-tab-pane" type="button" role="tab"
                                    aria-controls="home-tab-pane" aria-selected="true">

                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.125 5.8125C3.125 5.30469 3.51562 4.875 4.0625 4.875H5.9375C6.44531 4.875 6.875 5.30469 6.875 5.8125V7.6875C6.875 8.23438 6.44531 8.625 5.9375 8.625H4.0625C3.51562 8.625 3.125 8.23438 3.125 7.6875V5.8125ZM3.125 12.0625C3.125 11.5547 3.51562 11.125 4.0625 11.125H5.9375C6.44531 11.125 6.875 11.5547 6.875 12.0625V13.9375C6.875 14.4844 6.44531 14.875 5.9375 14.875H4.0625C3.51562 14.875 3.125 14.4844 3.125 13.9375V12.0625ZM5.9375 17.375C6.44531 17.375 6.875 17.8047 6.875 18.3125V20.1875C6.875 20.7344 6.44531 21.125 5.9375 21.125H4.0625C3.51562 21.125 3.125 20.7344 3.125 20.1875V18.3125C3.125 17.8047 3.51562 17.375 4.0625 17.375H5.9375Z"
                                            fill="#7A8699" />
                                        <path opacity="0.4"
                                            d="M8.75 6.75C8.75 6.08594 9.29688 5.5 10 5.5H21.25C21.9141 5.5 22.5 6.08594 22.5 6.75C22.5 7.45312 21.9141 8 21.25 8H10C9.29688 8 8.75 7.45312 8.75 6.75ZM8.75 13C8.75 12.3359 9.29688 11.75 10 11.75H21.25C21.9141 11.75 22.5 12.3359 22.5 13C22.5 13.7031 21.9141 14.25 21.25 14.25H10C9.29688 14.25 8.75 13.7031 8.75 13ZM21.25 18C21.9141 18 22.5 18.5859 22.5 19.25C22.5 19.9531 21.9141 20.5 21.25 20.5H10C9.29688 20.5 8.75 19.9531 8.75 19.25C8.75 18.5859 9.29688 18 10 18H21.25Z"
                                            fill="#7A8699" />
                                    </svg>

                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                    data-bs-target="#profile-tab-pane" type="button" role="tab"
                                    aria-controls="profile-tab-pane" aria-selected="false">

                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.25 9.875C11.25 10.9297 10.3906 11.75 9.375 11.75H5.625C4.57031 11.75 3.75 10.9297 3.75 9.875V6.125C3.75 5.10938 4.57031 4.25 5.625 4.25H9.375C10.3906 4.25 11.25 5.10938 11.25 6.125V9.875ZM21.25 19.875C21.25 20.9297 20.3906 21.75 19.375 21.75H15.625C14.5703 21.75 13.75 20.9297 13.75 19.875V16.125C13.75 15.1094 14.5703 14.25 15.625 14.25H19.375C20.3906 14.25 21.25 15.1094 21.25 16.125V19.875Z"
                                            fill="#7A8699" />
                                        <path opacity="0.4"
                                            d="M21.25 10.1875C21.25 11.0859 20.5469 11.75 19.6875 11.75H15.3125C14.4141 11.75 13.75 11.0859 13.75 10.1875V5.8125C13.75 4.95312 14.4141 4.25 15.3125 4.25H19.6875C20.5469 4.25 21.25 4.95312 21.25 5.8125V10.1875ZM11.25 20.1875C11.25 21.0859 10.5469 21.75 9.6875 21.75H5.3125C4.41406 21.75 3.75 21.0859 3.75 20.1875V15.8125C3.75 14.9531 4.41406 14.25 5.3125 14.25H9.6875C10.5469 14.25 11.25 14.9531 11.25 15.8125V20.1875Z"
                                            fill="#7A8699" />
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                        tabindex="0">
                        <div class="searched-list mt-2">
                            <div class="row">
                                @for(item of recordsList;track item.ID;let i= $index ) {
                                <div class="col-12">
                                    <a href="javascript:void()"
                                        class="list-search-card d-flex align-items-top justify-content-start"
                                        [ngClass]="i%2===0 ? 'even-card' : 'odd-card'">
                                        <div class="list-thumbnail">
                                            <img src="{{imageBaseUrl}}{{item.ImagePath}}"
                                                (click)="navigateToDetail(item)" alt="img">
                                            <div class="item-badge">{{ item.Type === 1 ?
                                                ('pdl-website.eventsCampaigns.event' | translate) :
                                                ('pdl-website.eventsCampaigns.campaign' | translate) }}</div>
                                        </div>
                                        <div class="thumb-content">
                                            <h5 class="semibold heading pdl-title-color"
                                                (click)="navigateToDetail(item)">
                                                {{item.Title}}
                                            </h5>
                                            <div class="small-tags">
                                                <span class="small-tag">
                                                    <img src="assets/images/icons/calendar-ico.svg" alt="calendar-icon">
                                                    {{item.Date.startDate | date : 'dd MMM YYYY' }} -
                                                    {{item.Date.endDate | date : 'dd MMM YYYY' }}
                                                </span>
                                                <span class="small-tag">
                                                    <img src="assets/images/icons/location-ico.svg" alt="location-icon">
                                                    {{item.Location }}
                                                </span>
                                            </div>

                                            <p class="description-text">
                                                <span class="text__ellipse" [innerHTML]="item.Description"></span>
                                            </p>
                                        </div>
                                    </a>
                                </div>
                                }@empty {
                                <div class="no-results d-flex">
                                    <p class="no-results-text mt-3"> {{'pdl-website.forms.advancedSearch.noRecordsFound'
                                        | translate }}
                                    </p>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                        tabindex="0">
                        <div class="searches-in-grid mt-2">
                            <div class="row justify-content-center">
                                @for(item of recordsList;track item.ID) {
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div class="events-campaigns-card position-relative cursor-pointer"
                                        [routerLink]="'/events-campaigns/'+ item.ID">
                                        <div class="block-img">
                                            <img src="{{imageBaseUrl}}{{item.ImagePath}}" alt="image" class="thumb-img">
                                            <div class="item-badge">{{ item.Type === 1 ?
                                                ('pdl-website.eventsCampaigns.event' | translate) :
                                                ('pdl-website.eventsCampaigns.campaign' | translate) }}</div>
                                        </div>
                                        <div class="block-text ps-4 pe-4">
                                            <h5 class="medium-font"> {{item.Title }}
                                            </h5>
                                            <div class="small-tags">
                                                <span class="small-tag">
                                                    <img src="assets/images/icons/calendar-ico.svg" alt="calendar-icon">
                                                    {{item.Date.startDate | date : 'dd MMMM YYYY'}} -
                                                    {{item.Date.endDate | date : 'dd MMMM YYYY'}}
                                                </span>
                                                <span class="small-tag">
                                                    <img src="assets/images/icons/location-ico.svg" alt="calendar-icon">
                                                    {{item.Location}}
                                                </span>
                                            </div>
                                            <p class="description-text text__ellipse" [innerHTML]="item.Description"></p>
                                            <div
                                                class="pdl-btns-row pdl-btns-row-fixed d-flex align-items-center justify-content-start flex-wrap">
                                                <!-- <a href="javascript:void(0)"
                                                    class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.eventsCampaigns.getDirections'
                                                    | translate }} <img src="assets/images/icons/directions-ico.svg"
                                                        alt="direction-icon"></a> -->
                                                <a href="javascript:void(0)" (click)="navigateToDetail(item)"
                                                    class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                                    {{'pdl-website.eventsCampaigns.openDetails' | translate }} <img
                                                        src="assets/images/icons/visit-ico.svg" alt="visit-icon"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        @if(totalRecords) {
        <div class="row">
            <div class="col-12">
                <div
                    class="pdl-pagination d-flex align-items-center justify-content-center webPagination__wrapper mt-5">
                    <nav aria-label="Page navigation example">
                        <ngb-pagination class="donations__pagination" [collectionSize]="totalRecords"
                            [(page)]="pageNumber" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                            (pageChange)="getPayload(pageNumber)" [maxSize]="maxPageNumber" [ellipses]="true" />
                    </nav>
                </div>
            </div>
        </div>
        }
    </div>

</section>

@if(isLoading) {
<panjab-digi-lib-spinner></panjab-digi-lib-spinner>
}
