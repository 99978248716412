// effects/notes.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DocumentNotesService } from 'shared/src/services/website/metadata/document-notes.service';
import { DocumentNotesActions } from '../../actions';

@Injectable()
export class DocumentNotesEffects {
  constructor(private actions$: Actions, private notesService: DocumentNotesService) {}

  loadNotesByDocId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.loadNotesByDocId),
      exhaustMap(({ docID }) =>
        this.notesService.getNotesByDocId(docID).pipe(
          map((response) => DocumentNotesActions.loadNotesByDocIdSuccess({ notes: response.body })),
          catchError((error) => of(DocumentNotesActions.loadNotesByDocIdError({ message: error.message })))
        )
      )
    )
  );

  createNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.createNote),
      exhaustMap(({ payload }) =>
        this.notesService.createNote(payload).pipe(
          map((response) => DocumentNotesActions.createNoteSuccess({ note: response })),
          catchError((error) => of(DocumentNotesActions.createNoteError({ message: error.message })))
        )
      )
    )
  );

  updateNoteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.updateNoteById),
      exhaustMap(({ payload }) =>
        this.notesService.updateNoteById(payload.ID, payload).pipe(
          map((updatedNote) =>
            DocumentNotesActions.updateNoteByIdSuccess({ updatedNote })
          ),
          catchError((error) =>
            of(DocumentNotesActions.updateNoteByIdFailure({ error }))
          )
        )
      )
    )
  );

  deleteNoteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.deleteNoteById),
      exhaustMap(({ noteId }) =>
        this.notesService.deleteNoteById(noteId).pipe(
          map(() => DocumentNotesActions.deleteNoteSuccess({ noteId })),
          catchError((error) => of(DocumentNotesActions.deleteNoteError({ message: error.message })))
        )
      )
    )
  );
}
