import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from '../../staticPageHead/staticPageHead.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { StaticPageService } from '../../StaticPageService';
import { environment } from '@panjab-digi-lib/shared';
import { SpinnerComponent } from '../../../../../../../../shared/src/lib/components/spinner/spinner.component';

@Component({
  selector: 'panjab-digi-lib-team-member',
  standalone: true,
  imports: [
    CommonModule,
    StaticPageHeadComponent,
    TranslateModule,
    SpinnerComponent,
  ],
  templateUrl: './team-member.component.html',
  styleUrl: './team-member.component.scss',
})
export class TeamMemberComponent {
  id: any = '0';
  loading: boolean = true;
  staffDetails: any = null;
  azureCloudStorageUrl = environment.azureCloudStrorage.publicUrl;
  fallbackImage =
    'https://api.dicebear.com/5.x/initials/svg?scale=50&size=197&backgroundColor=669074&seed=';
  constructor(
    private route: ActivatedRoute,
    private StaticPageService: StaticPageService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.StaticPageService.getStaffByID(this.id).subscribe(
      (data: any) => {
        if (data.code === 200 && data?.body?.staffDetails) {
          this.staffDetails = data.body.staffDetails;
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
  handleImageError(event: Event, staff: any) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage + staff.FName;
  }
}
