import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";

import { Cart } from 'shared/src/interfaces/website/adoption.interface';
import { CartActions } from '../../actions';

export interface State extends EntityState<Cart> {
  isLoading?: boolean;
  isUpdating: boolean;
  isError?: boolean;
  errorMessage?: string;
  filters : any;
  totalAmount : number;
}

export const adapter = createEntityAdapter<Cart>({
  selectId: (e) => e.CartID
});

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isUpdating: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  filters : {},
  totalAmount : 0
});

export const reducer = createReducer(
  initialState,
  on(CartActions.LoadCartList,(state, action) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),

  on(CartActions.LoadCartListSuccess, (state, action) => ({
    ...adapter.setAll(action?.cartList, {
      ...state,
      totalAmount: action?.totalAmount,
      isLoading: false,
      isError: false,
    })
  })),

  on(CartActions.LoadCartListError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  ),

  on(CartActions.AddToCartList,(state, action) =>
    ({
      ...state,
      isUpdating: true,
      isError: false,
      errorMessage: ''
    })
  ),

  on(CartActions.AddToCartListSuccess, (state, action) => 
  {  
    const totalAmount = Number(state.totalAmount)+Number(action.cart.AdoptionPrice);
    return ({
        ...adapter.addOne(action.cart, state),
        totalAmount: Math.round(totalAmount*100)/100,
        isUpdating: false,
        isError: false,
        errorMessage: ''
    })
  }
  ),

  on(CartActions.AddToCartListError,(state, action) =>
    ({
      ...state,
      isUpdating: false,
      isError: true,
      errorMessage: action.message,
    })
  ),

  on(CartActions.RemoveFromCartList,(state, action) =>
    ({
      ...state,
      isUpdating: true,
      isError: false,
      errorMessage: ''
    })
  ),

  on(CartActions.RemoveFromCartListSuccess, (state, action) => 
  {
    const totalAmount = Number(state.totalAmount-action.AdoptionPrice);
    
    return ({
        ...adapter.removeOne(action.CartID, state),
        totalAmount: Math.round(totalAmount*100)/100,
        isUpdating: false,
        isError: false,
        errorMessage: ''
    })
  }
  ),

  on(CartActions.RemoveFromCartListError,(state, action) =>
    ({
      ...state,
      isUpdating: false,
      isError: true,
      errorMessage: action.message,
    })
  ),

  on(CartActions.EmptyGuestCart,(state, action) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),

  on(CartActions.EmptyGuestCartSuccess, (state, action) => ({
    ...adapter.setAll(action?.cartList, {
      ...state,
      totalAmount: action?.totalAmount,
      isLoading: false,
      isError: false,
    })
  })),

  on(CartActions.EmptyGuestCartError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();