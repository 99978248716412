import { createAction, props } from '@ngrx/store';
import { Bookmark } from 'shared/src/interfaces/metadata.interface';

export const loadBookmarks = createAction(
  '[Bookmarks] Load Bookmarks',
  props<{ docId: number }>()
);
export const loadBookmarksSuccess = createAction(
  '[Bookmarks] Load Bookmarks Success',
  props<{ bookmarks: Bookmark[] }>()
);
export const loadBookmarksFailure = createAction(
  '[Bookmarks] Load Bookmarks Failure',
  props<{ error: any }>()
);

export const addBookmark = createAction(
  '[Bookmarks] Add Bookmark',
  props<{ bookmark: Omit<Bookmark, 'ID'> }>()
);
export const addBookmarkSuccess = createAction(
  '[Bookmarks] Add Bookmark Success',
  props<{ bookmark: Bookmark }>()
);
export const addBookmarkFailure = createAction(
  '[Bookmarks] Add Bookmark Failure',
  props<{ error: any }>()
);

export const deleteBookmark = createAction(
  '[Bookmarks] Delete Bookmark',
  props<{ bookmarkId: number }>()
);
export const deleteBookmarkSuccess = createAction(
  '[Bookmarks] Delete Bookmark Success',
  props<{ bookmarkId: number }>()
);
export const deleteBookmarkFailure = createAction(
  '[Bookmarks] Delete Bookmark Failure',
  props<{ error: any }>()
);
