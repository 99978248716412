/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { SaveEventsCampaignsPayload, EventsCampaignsItem, GetEventsCampaignsPayload, UpdateRecordStatusPayload } from 'shared/src/interfaces/events-campaigns.interface';

export const LoadRecords = createAction(
  '[EventsCampaigns] Load EventsCampaigns List',
  props<{ payload: GetEventsCampaignsPayload }>()
);

export const LoadRecordsSuccess = createAction(
  '[EventsCampaigns] Load EventsCampaigns Success',
  props<{ recordsList: EventsCampaignsItem[]; totalRecords: number }>()
);

export const LoadRecordsError = createAction(
  '[EventsCampaigns] Load EventsCampaigns Error',
  props<{ message: string }>()
);

export const SaveRecord = createAction(
  '[EventsCampaigns] Save Record to the List',
  props<{ payload: FormData }>()
);

export const SaveRecordSuccess = createAction(
  '[EventsCampaigns] Save Record Success',
  props<{ record: EventsCampaignsItem; message: string }>()
);

export const SaveRecordError = createAction(
  '[EventsCampaigns] Save Record Error',
  props<{ message: string }>()
);

export const UpdateRecord = createAction(
  '[EventsCampaigns] Update Record in List',
  props<{ payload: SaveEventsCampaignsPayload }>()
);

export const UpdateRecordSuccess = createAction(
  '[EventsCampaigns] Record Updated Success',
  props<{ record: EventsCampaignsItem; message: string }>()
);

export const UpdateRecordError = createAction(
  '[EventsCampaigns] Update Record Error',
  props<{ message: string }>()
);

export const UpdateRecordStatus = createAction(
  '[EventsCampaigns] Update Record Status in List',
  props<{ payload: UpdateRecordStatusPayload }>()
);

export const UpdateRecordStatusSuccess = createAction(
  '[EventsCampaigns] Record Updated Status Success',
  props<{ record: EventsCampaignsItem[]; message: string }>()
);

export const UpdateRecordStatusError = createAction(
  '[EventsCampaigns] Update Record Status Error',
  props<{ message: string }>()
);

export const DeleteRecord = createAction(
  '[EventsCampaigns] Delete Record in List',
  props<{ payload: { id: number } }>()
);

export const DeleteRecordSuccess = createAction(
  '[EventsCampaigns] Delete Record Success',
  props<{ message: string }>()
);

export const DeleteRecordError = createAction(
  '[EventsCampaigns] Delete Record Error',
  props<{ message: string }>()
);

