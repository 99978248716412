// actions/notes.actions.ts
import { createAction, props } from '@ngrx/store';
import { Note } from 'shared/src/interfaces/metadata.interface';


export const loadNotesByDocId = createAction(
  '[Notes] Load Notes By Doc ID',
  props<{ docID: number }>()
);

export const loadNotesByDocIdSuccess = createAction(
  '[Notes] Load Notes By Doc ID Success',
  props<{ notes: Note[] }>()
);

export const loadNotesByDocIdError = createAction(
  '[Notes] Load Notes By Doc ID Error',
  props<{ message: string }>()
);

export const createNote = createAction(
  '[Notes] Create Note',
  props<{ payload: Note }>()
);

export const createNoteSuccess = createAction(
  '[Notes] Create Note Success',
  props<{ note: Note }>()
);

export const createNoteError = createAction(
  '[Notes] Create Note Error',
  props<{ message: string }>()
);

export const updateNoteById = createAction(
    '[Document Notes] Update Note By ID',
    props<{ payload: { ID: number; Note: string; DocID: number; PageNumber: number } }>()
  );
  
  export const updateNoteByIdSuccess = createAction(
    '[Document Notes] Update Note By ID Success',
    props<{ updatedNote: any }>()
  );
  
  export const updateNoteByIdFailure = createAction(
    '[Document Notes] Update Note By ID Failure',
    props<{ error: any }>()
  );

export const deleteNoteById = createAction(
  '[Notes] Delete Note By ID',
  props<{ noteId: number }>()
);

export const deleteNoteSuccess = createAction(
  '[Notes] Delete Note Success',
  props<{ noteId: number }>()
);

export const deleteNoteError = createAction(
  '[Notes] Delete Note Error',
  props<{ message: string }>()
);
