import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { StaticPageHeadComponent } from '../static/staticPageHead/staticPageHead.component';
import { 
  NgbPaginationModule,
  NgbPopoverModule, 
  NgbTooltipModule, 
  NgbPagination 
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { NewsArticles } from 'shared/src/interfaces/news-and-articles.interface';
import { APP_CONSTANTS as CONSTANTS } from '../../../common/constants/app.constants';
import { environment, WebNewsAndArticlesSelector, WebNewsAndArticlesActions } from '@panjab-digi-lib/shared';
import { SortOrder } from 'shared/src/interfaces/common.interface';
import { NewsAndArticlesService } from 'shared/src/services/website/news-and-articles/news-and-articles.service';
@Component({
  selector: 'panjab-digi-lib-news-and-articles',
  standalone: true,
  imports: [CommonModule, RouterLink, StaticPageHeadComponent, TranslateModule, NgbPagination, NgbPaginationModule, NgbPopoverModule, NgbTooltipModule],
  templateUrl: './news-and-articles.component.html',
  styleUrl: './news-and-articles.component.scss',
})
export class NewsAndArticlesComponent implements OnInit, OnDestroy {
  constructor(
    private readonly store: Store,
    private readonly newsAndArticleService: NewsAndArticlesService,
    private readonly route: Router
  ) {}
  activeTab = 0;
  addNewsArticlesEnabled = false;
  subscription = new Subscription();
  maxSize = APP_CONSTANTS.maxSize;
  pageNumber = 1;
  pageSize = APP_CONSTANTS.pageSize;
  newsAndArticlesList: NewsArticles[] = [];
  totalRecords = 0;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  fallbackImage = CONSTANTS.newsAndArticlesFallbackImage;
  defaultOrder = { orderBy: 'PublishedDate', order: 'DESC' };
  sortOrder: SortOrder = this.defaultOrder;
  subjectsList: any = [];
  pagePath = '';
  ngOnInit(): void {
    // get subjects name used by news and articles
    this.loadSubjects();
    this.pagePath = this.route.url;
  }

  ///////////////// load subjects used in the newsandarticles list //////////////
  loadSubjects() {
    this.newsAndArticleService.getSubjects().subscribe((data) => {
      this.subjectsList = data;
      // get first subject id to make first tab as default selected
      this.activeTab = data[0]?.id;
      // get list of articles
      this.loadNewsAndArticles();
    });
  }

  /////////////// set active tab and reload the articles list ///////////////
  setActiveTab = (key: any) => {
    this.activeTab = key;
    this.loadNewsAndArticles();
  };

  ////////////////// load all newsandarticles //////////////////
  loadNewsAndArticles() {
    const payload = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      subjectId: this.activeTab.toString(),
      orderBy: this.sortOrder.orderBy,
      order: this.sortOrder.order
    };
    this.store.dispatch(WebNewsAndArticlesActions.LoadNewsAndArticles({ payload }));
    this.subscription.add(
      this.store
        .select(WebNewsAndArticlesSelector.loadNewsAndArticlesList)
        .subscribe((data) => {
          this.totalRecords = data?.totalRecords;
          this.newsAndArticlesList = data?.recordsList;
        })
    );
  }

  //////////// get thumbnail image //////////////
  getThumbnailImg(item: any): string {
    return `${this.imageBaseUrl}${item.CategoryName}/${item.AccessionNo}/Thumbnails/${item.AccessionNo}_200.jpg`;
  }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
