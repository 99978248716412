import { createAction, props } from '@ngrx/store';
import { AdoptionBooks, Cart } from 'shared/src/interfaces/website/adoption.interface';

export const LoadCartList = createAction(
  '[Cart] Cart List',
  props<{currencyCode: string}>()
);

export const LoadCartListSuccess = createAction(
  '[Cart] Cart List Successfully',
  props<{ cartList: Cart[]; totalAmount: number }>()
);

export const LoadCartListError = createAction(
  '[Cart] Cart List Error',
  props<{ message: string }>()
);

export const AddToCartList = createAction(
  '[Cart] Add To Cart List',
  props<{ document: Cart, currencyCode: string, checkout: boolean }>()
);

export const AddToCartListSuccess = createAction(
  '[Cart] Add To Cart List Successfully',
  props<{ cart: Cart, checkout: boolean }>()
);

export const AddToCartListError = createAction(
  '[Cart] Add To Cart List Error',
  props<{ message: string }>()
);

export const RemoveFromCartList = createAction(
  '[Cart] Remove From Cart List',
  props<{document: Cart}>()
);

export const RemoveFromCartListSuccess = createAction(
  '[Cart] Remove From Cart List Successfully',
  props<{ CartID: number; AdoptionPrice: number}>()
);

export const RemoveFromCartListError = createAction(
  '[Cart] Remove From Cart List Error',
  props<{ message: string }>()
);

export const EmptyGuestCart = createAction(
  '[Cart] Empty Guest Cart List',
)

export const EmptyGuestCartSuccess = createAction(
  '[Cart] Empty Guest Cart List Successfully',
  props<{ cartList: Cart[]; totalAmount: number }>()
);

export const EmptyGuestCartError = createAction(
  '[Cart] Empty Guest Cart List Error',
  props<{ message: string }>()
);

export const noGuestCartToSync = createAction(
  '[Cart] No Guest Cart to Sync On Login'
)

export const SyncGuestCartOnLogin = createAction(
  '[Cart] Sync Guest Cart List On Login',
  props<{ DocIDs: number[] }>()
)

export const SyncGuestCartOnLoginSuccess = createAction(
  '[Cart] Sync Guest Cart List On Login Successfully',
  // props<{ cartList: Cart[]; totalAmount: number }>()
);

export const SyncGuestCartOnLoginError = createAction(
  '[Cart] Sync Guest Cart List On Login Error',
  props<{ message: string }>()
);