/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent, AppState, CountryActions, CountrySelector, environment } from '@panjab-digi-lib/shared';
import { SocialLoginComponent } from '../common/social-login/social-login.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NgbDateStruct, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaComponent } from 'ng-recaptcha';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { UtilService } from '../../../common/services/util.service';
import { TrimmedTextDirective } from '../../../common/directives/trimmed-text.directive';
import { NumberonlyDirective } from '../../../common/directives/numberonly.directive';
import { ExhibitionService } from 'shared/src/services/website/exhibition/exhibition.service';

@Component({
  selector: 'panjab-digi-lib-exhibition-request',
  standalone: true,
  imports: [CommonModule, TranslateModule, SocialLoginComponent, BreadcrumbsComponent, ReactiveFormsModule, NgbTooltipModule, RouterLink, AlertsComponent, SpinnerButtonComponent, RecaptchaModule,
     RecaptchaFormsModule, TrimmedTextDirective, NumberonlyDirective,NgbModule],
  providers: [DatePipe],
  templateUrl: './exhibition-request.component.html',
  styleUrl: './exhibition-request.component.scss',
})
export class ExhibitionRequestComponent implements OnInit, OnDestroy {
  pageTitle = 'Exhibitions';
  exhibitionReqForm!: FormGroup;
  countryList$!: Observable<any>;
  successMsg = false;
  isSubmitted = false;
  sitekey = environment.recaptcha.siteKey;
  isMobile = false;
  subscription = new Subscription();
  minDate!: NgbDateStruct;
  formattedStartDate: string = '';
  formattedEndDate: string = '';
  exhibitionList! : {ExhibitionID: number,Title:string}[];
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent;
  @ViewChild('moveToTop', { static: false }) moveToTop!: ElementRef;
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private el: ElementRef,
    private alertService: AlertService,
    private renderer: Renderer2,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private service: ExhibitionService
  ) {

    this.getCountriesList();
    this.getExhibitionList();
    this.initexhibitionReqForm();
  }

  ngOnInit() {
    const today = new Date();
    this.minDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate() + 1
    };
    // change recaptcha size when it is on mobile view
    if(window.innerWidth < 370) {
      this.isMobile = true;
    }
  }


  getCountriesList() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({payload}));
    this.countryList$ = this.store.select(CountrySelector.getCountries);
  }

  getExhibitionList() {
   this.subscription.add(this.service.exhibitionList().subscribe({
    next: (res) => {
    this.exhibitionList = res?.body;
    this.exhibitionList.push({ExhibitionID: -1, Title: 'For New Exhibition'})
    },
    error: () => {
      console.log('error fetching exhibition list')
    }
  }))
  }

  initexhibitionReqForm() {
    this.exhibitionReqForm = this.fb.group(
      {
        organizationName: ['', [Validators.required, Validators.minLength(5), Validators.pattern(APP_CONSTANTS.regExp.alphaNumericOnly)]],
        organizationAddress: ['', [Validators.required]],
        organizationCity: ['', [Validators.required, Validators.maxLength(30)]],
        organizationState: ['', [Validators.required, Validators.maxLength(30)]],
        organizationCountry: [null, Validators.required],
        organizationPhone: ['', [Validators.required, Validators.pattern(APP_CONSTANTS.regExp.numberOnly) ,Validators.minLength(10), Validators.maxLength(12)]],
        organizationEmail: ['', {
          validators: [Validators.required, Validators.pattern(APP_CONSTANTS.regExp.email)],
          updateOn: 'blur'
        }],
        organizationWebsite: ['', [Validators.pattern(APP_CONSTANTS.regExp.url)]],
        organizationDescription: [null, [Validators.required, Validators.maxLength(1000)]],
        personName: ['', [Validators.required, Validators.maxLength(100)]],
        personDesignation: ['',Validators.maxLength(50)],
        personPhone: ['', [Validators.required, Validators.pattern(APP_CONSTANTS.regExp.numberOnly) ,Validators.minLength(10), Validators.maxLength(12)]],
        exhibitionId : [null,[Validators.required]],
        exhibitionName: [''],
        exhibitionVenue: ['',Validators.required],
        exhibitionStartDate: ['',Validators.required],
        exhibitionEndDate: ['',[Validators.required, this.endDateValidator.bind(this)]],
        exhibitionOtherInformation: ['',[Validators.required, Validators.maxLength(1000)]],
        cpt: [null, Validators.required]

    });
    this.exhibitionReqForm.get('exhibitionId')?.valueChanges.subscribe(value => {
      const exhibitionNameControl = this.exhibitionReqForm.get('exhibitionName');
      if (exhibitionNameControl) {
        if (value === -1) {
          exhibitionNameControl.setValidators([Validators.required, Validators.maxLength(200)]);
        } else {
          exhibitionNameControl.clearValidators();
        }
        exhibitionNameControl.updateValueAndValidity();
      }
    });
  }


  endDateValidator(control: any): { [key: string]: boolean } | null {
    const startDate = this.exhibitionReqForm?.get('exhibitionStartDate')?.value;
    const endDate = control.value;
        if (startDate && endDate) {
      const start = new Date(startDate.year, startDate.month - 1, startDate.day);
      const end = new Date(endDate.year, endDate.month - 1, endDate.day);
      if (end <= start) {
        return { 'endDateInvalid': true };
      }
    }
    return null;
  }


  convertToDate(date: NgbDateStruct): Date | null {
    if (date && date.year && date.month && date.day) {
      return new Date(date.year, date.month - 1, date.day); // JavaScript months are 0-based
    }
    return null;
  }

  onStartDateChange(date: NgbDateStruct): void {
    const validDate = this.convertToDate(date);
    if (validDate) {
      this.formattedStartDate = this.formatDate(validDate);
    } 
  }

  onEndDateChange(date: NgbDateStruct): void {
    const validDate = this.convertToDate(date);
    if (validDate) {
      this.formattedEndDate = this.formatDate(validDate);
    }
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy') || '';
  }

  resetForm() {
    this.exhibitionReqForm.reset();
    this.moveToFirstElement();
    this.resetReCaptcha();
  }

  moveToFirstElement(): void {
    this.renderer.selectRootElement(this.moveToTop.nativeElement).focus();
  }

  resolveCaptcha(captchaResponse: string | null) {
    if(captchaResponse) {
      this.exhibitionReqForm.patchValue({'cpt': captchaResponse})
    } else {
      this.exhibitionReqForm.controls['cpt'].setErrors({'invalid': true});
    }
  }

  resetReCaptcha() {
    this.captchaRef.reset();
  }

  onSubmit() {
    this.alertService.clear();
    this.exhibitionReqForm.markAllAsTouched();
    if(this.exhibitionReqForm.valid) {
      this.isSubmitted = true;
      const formData = this.exhibitionReqForm.value;
      if(this.formattedStartDate && this.formattedEndDate) {
        const [startDay, startMonth, startYear] = this.formattedStartDate.split('-');
        const [endDay, endMonth, endYear] = this.formattedEndDate.split('-');
        const startDate = `${startYear}-${startMonth}-${startDay}`;
        const endDate = `${endYear}-${endMonth}-${endDay}`;
        formData.exhibitionStartDate = startDate;
        formData.exhibitionEndDate = endDate;
      }
      delete formData.cpt;
      if(formData.exhibitionName === "") {
        delete formData.exhibitionName;
      }
      if(formData.exhibitionId === -1) {
        delete formData.exhibitionId;
      }
      if(formData.organizationWebsite === "" || !formData.organizationWebsite) {
        delete formData.organizationWebsite;
      }
      this.service.request(formData).subscribe({
        next: (res:any) => {
          this.successMsg = true;
          this.alertService.success(res.message);
          this.resetForm();
          this.isSubmitted = false;
        },
        error: () => {
          this.isSubmitted = false;
          this.resetReCaptcha();
        }
      });
    } else {
      this.utilService.focusOnInvalidField(this.exhibitionReqForm, this.el);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
