@if (loading) {
<div class="loader__wrapper">
  <panjab-digi-lib-spinner [isFullScreen]="true"></panjab-digi-lib-spinner>
</div>
} @else {
@if (staffDetails) {
<panjab-digi-lib-static-page-head [showPageName]="false" [pageName]="staffDetails?.FName" [pagePath]="'/team/'+id">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding pt-3 ">
  <div class="container">
    <div class="d-flex  content">
      <img class="img" [src]="azureCloudStorageUrl + staffDetails.Image" [alt]="staffDetails.FName"
        (error)="handleImageError($event, staffDetails)" />
      <div class="d-flex flex-column info-and-bio">
        <span class="type">{{staffDetails.UserType.Name}}</span>
        <span class="name">{{staffDetails.FName}}</span>
        <span class="addInfo">{{staffDetails.AdditionalInfo}}</span>
        <p class="bio">
          {{staffDetails.Bio}}
        </p>
      </div>
    </div>
  </div>
</section>
} @else {
<panjab-digi-lib-static-page-head [showPageName]="false" pageName='' pagePath="/team">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding pt-3 ">
  <div class="container">
    <div class="d-flex justify-content-center mt-4">
      {{"pdl-website.team.noTeamMemberFound" | translate}}
    </div>
  </div>
</section>
}
}
