<section class="innerpage-banner">
  <div class="container">
    @if (showPath) {
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            Home
          </a>
          @if (pageName) {
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="{{pagePath}}">
            {{ pageName }}
          </a>
          }

        </div>
      </div>
    </div>
    } @else {
    <div class="mt-2">&nbsp;</div>
    }
    @if (showPageName) {
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title">
          <h1 class="heading-dark semibold mb-0">{{ pageName }}</h1>
        </div>
      </div>
    </div>
    }
  </div>

</section>
